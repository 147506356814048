import React from "react";
import logo from "../../Assets/Images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
function Submitted() {
  return (
    <>
      <div className="login">
        <div
          className="container d-flex flex-column justify-content-between"
          style={{ height: "100vh" }}
        >
          <div>
            <img src={logo} className="login-logo mt-3" />
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
            <div className="w-100 mt-4">
              <div className="register-form">
                <div className="m-4">
                  <h6>
                    Application Submitted{" "}
                    <FontAwesomeIcon
                      style={{ color: "green" }}
                      icon={faCheckCircle}
                    />
                  </h6>
                  <p className="mt-3">
                  Your application has been submitted! AlSubaee Team will review and approve our account with 24 to 48 hours.
                  </p>
                  <NavLink to='/'>
                  <button className="btn btn-secondary mt-3">
                    <FontAwesomeIcon className="me-2" icon={faArrowLeft} />
                    Back to home
                  </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="login-footer mb-3">
            <p>© 2024 Alsubaee All Rights Reserved</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Submitted;
