import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import { useSelector,useDispatch } from "react-redux";
import DashboardPage from "./DashboardPage";
import Settingage from "./Settingage";
import Axios from "axios";
import { ApiEndPoint } from "../../Data/Api";
import IncomeStatement from "./IncomeStatement";
import MakePayment from "./MakePayment";
import UserDashboard from "./UserDashboard";
import Profile from "./Profile";
import EditProfile from "./EditProfile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Routes,
  Route,
  useNavigate,
  NavLink,
  useLocation,
} from "react-router-dom";
import {
  faCreditCard,
  faDashboard,
  faDollarSign,
  faFileInvoice,
  faGear,
  faHome,
  faHotel,
  faHouse,
  faPerson,
  faSignOut,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import user from '../../Assets/Images/user.png'
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { setLoginUser } from "../../reducers/hotelActions";
// import Statement from "./Statement";
// import IncomeLedger from "./IncomeLedger";
import MyPayments from "./MyPayments";
function Dashboard() {
  const baseurl = ApiEndPoint();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch=useDispatch();
  const UserDetail = useSelector((state) => state.user.user);
  const [tentitiveBookings, setTentitiveBookings] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [incomList, setIncomList] = useState([]);
  useEffect(() => {
    if (location.pathname === '/dashboard/bookings' || location.pathname === '/dashboard' || location.pathname ===  '/dashboard/make-payment') {
      GetUserBooking();
    }
  }, [location.pathname]);
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    dispatch(setLoginUser(null));
    navigate("/login");
  };
  const GetUserBooking = async () => {
    const data = {
      token: UserDetail.token,
      b2b_agent_id: UserDetail.id,
    };

    try {
      const response = await Axios.post(
        baseurl + "/api/view_customer_hotel_booking",
        data
      );
      if (response.data.message === "success") {
        setTentitiveBookings(response.data.tentative_Hotel_Bookings);
        setInvoiceList(response.data.inv_details);
        const mergedArray = [
          ...response.data.hotels_bookings.map((obj) => ({
            ...obj,
            domain_name: "Hotel Booking",
          })),
          ...response.data.invoice_Bookings.map((obj) => ({
            ...obj,
            domain_name: "Inovice Booking",
          })),
        ];
        setDashboardData(mergedArray);
        const confirmlist = response.data.inv_details.filter(
          (item) => item.status === "CONFIRMED"
        );
        setIncomList(confirmlist);
      }
    } catch (error) {
      console.log(error);
      console.error("Error:", error);
    }
  };
  const getNavLinkClass = (path) => {
    return location.pathname === path ? "active" : "";
  };
  return (
    <>
      <>
        <section class="pt-3">
          <div class="container-fluid">
            <div  class="row g-2 g-lg-4">
            
              <div class="col-lg-4 col-xl-3">
                <div
                  class="offcanvas-lg offcanvas-end"
                  tabindex="-1"
                  id="offcanvasSidebar"
                >
                  <div class="offcanvas-header justify-content-end pb-2">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="offcanvas"
                      data-bs-target="#offcanvasSidebar"
                      aria-label="Close"
                    ></button>
                  </div>

                  <div class="offcanvas-body p-3 p-lg-0">
                    <div class="card rounded-0 border-none w-100">
                      <div class="position-absolute top-0 end-0 p-3">
                        <a
                          href="#"
                          class="text-primary-hover"
                          data-bs-toggle="tooltip"
                          data-bs-title="Edit profile"
                        >
                          <i class="bi bi-pencil-square"></i>
                        </a>
                      </div>

                      <div class="card-body  p-3">
                        <div class="text-center mb-3">
                        <div class="avatar avatar-xl mb-2">
                          <img class="avatar-img rounded-circle border border-2 border-white" src={user} alt=""/>
                        </div>
                          <h6 class="mb-0">
                            {UserDetail.first_name} {UserDetail.last_name}
                          </h6>
                          <p class="text-reset text-primary-hover small">
                            {UserDetail.email}
                          </p>
                          <hr />
                        </div>

                        <ul className="nav nav-pills-primary-soft flex-column">
                        <li className="nav-item mt-1">
                            <NavLink
                              to="/"
                              className="text-decoration-none"
                            >
                              <p
                                style={{ cursor: "pointer", fontSize: "1.2em" }}
                                className={`nav-link ${getNavLinkClass(
                                  "/"
                                )}`}
                              >
                                <FontAwesomeIcon icon={faHome} /> Home
                              </p>
                            </NavLink>
                          </li>
                          <li className="nav-item mt-1">
                            <NavLink
                              to="/dashboard"
                              className="text-decoration-none"
                            >
                              <p
                                style={{ cursor: "pointer", fontSize: "1.2em" }}
                                className={`nav-link ${getNavLinkClass(
                                  "/dashboard"
                                )}`}
                              >
                                <FontAwesomeIcon icon={faDashboard} /> Dashboard
                              </p>
                            </NavLink>
                          </li>
                          <li className="nav-item mt-1">
                            <NavLink
                              to="/dashboard/profile"
                              className="text-decoration-none"
                            >
                              <p
                                style={{ cursor: "pointer", fontSize: "1.2em" }}
                                className={`nav-link ${getNavLinkClass(
                                  "/dashboard/profile"
                                )}`}
                              >
                                <FontAwesomeIcon icon={faUserCircle} />{' '} 
                                 My Profile
                              </p>
                            </NavLink>
                          </li>
                          <li className="nav-item mt-1">
                            <NavLink
                              to="/dashboard/my-payments"
                              className="text-decoration-none"
                            >
                              <p
                                style={{ cursor: "pointer", fontSize: "1.2em" }}
                                className={`nav-link ${getNavLinkClass(
                                  "/dashboard/my-payments"
                                )}`}
                              >
                                <FontAwesomeIcon icon={faCreditCard} />{' '} 
                                 My Payments
                              </p>
                            </NavLink>
                          </li>
                          {/* <li className="nav-item mt-1">
                            <NavLink
                              to="/dashboard/credit_limit"
                              className="text-decoration-none"
                            >
                              <p
                                style={{ cursor: "pointer", fontSize: "1.2em" }}
                                className={`nav-link ${getNavLinkClass(
                                  "/dashboard/credit_limit"
                                )}`}
                              >
                                <FontAwesomeIcon icon={faCreditCard} />{' '} 
                                Credit Limit
                              </p>
                            </NavLink>
                          </li> */}
                          <li className="nav-item mt-1">
                            <NavLink
                              to="/dashboard/bookings"
                              className="text-decoration-none"
                            >
                              <p
                                style={{ cursor: "pointer", fontSize: "1.2em" }}
                                className={`nav-link ${getNavLinkClass(
                                  "/dashboard/bookings"
                                )}`}
                              >
                                <FontAwesomeIcon icon={faFileInvoice} /> My
                                Bookings
                              </p>
                            </NavLink>
                          </li>
                          <li className="nav-item mt-1">
                            <p
                              style={{ cursor: "pointer", fontSize: "1.2em" }}
                              onClick={() => navigate("account-statement")}
                              className={`nav-link ${getNavLinkClass(
                                "/dashboard/account-statement"
                              )}`}
                            >
                              <FontAwesomeIcon icon={faDollarSign} /> Account
                              Statement
                            </p>
                          </li>
                          <li className="nav-item mt-1">
                            <p
                              style={{ cursor: "pointer", fontSize: "1.2em" }}
                              onClick={() => navigate("make-payment")}
                              className={`nav-link ${getNavLinkClass(
                                "/dashboard/make-payment"
                              )}`}
                            >
                              <FontAwesomeIcon icon={faUpload} /> Make Payment
                            </p>
                          </li>
                          <li className="nav-item mt-1">
                            <p
                              style={{ cursor: "pointer", fontSize: "1.2em" }}
                              onClick={() => navigate("/")}
                              className='nav-link'
                            >
                              <FontAwesomeIcon icon={faHotel} /> Hotel Search
                            </p>
                          </li>
                          <li className="nav-item mt-1">
                            <p
                              style={{ cursor: "pointer", fontSize: "1.2em" }}
                              onClick={() => navigate("settings")}
                              className={`nav-link ${getNavLinkClass(
                                "/dashboard/settings"
                              )}`}
                            >
                              <FontAwesomeIcon icon={faGear} /> Settings
                            </p>
                          </li>
                          <li className="nav-item mt-1">
                            <p
                              style={{ cursor: "pointer", fontSize: "1.2em" }}
                              onClick={handleLogout}
                              className="nav-link text-danger bg-danger-soft-hover"
                            >
                              <FontAwesomeIcon icon={faSignOut} /> Sign Out
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-8 mb-4 col-padding-left col-xl-9 ps-xl-5 ">
                <div class="d-grid mb-0 d-lg-none w-100">
                  <button
                    class="btn btn-primary mb-4"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasSidebar"
                    aria-controls="offcanvasSidebar"
                  >
                    <i class="fas fa-sliders-h"></i> Menu
                  </button>
                </div>
                <div className="row ">
                  <div className="col-lg-12 cold-md-12">
                    <Routes>
                      UserDashboard
                      <Route
                        index
                        element={
                          <UserDashboard invoiceListData={dashboardData} />
                        }
                      />
                      <Route
                        path="bookings"
                        element={
                          <DashboardPage GetUserBooking={GetUserBooking} invoiceListData={dashboardData} />
                        }
                      />
                      <Route
                        path="account-statement"
                        element={
                          <IncomeStatement invoiceListData={incomList} />
                        }
                      />
                      <Route
                        path="make-payment"
                        element={
                          <MakePayment GetUserBooking={GetUserBooking} tentativebookins={tentitiveBookings} />
                        }
                      />
                      <Route
                        path="settings"
                        element={<Settingage userData={UserDetail} />}
                      />
                       <Route
                        path="profile"
                        element={<Profile userData={UserDetail} />}
                      />
                      <Route
                        path="edit-profile"
                        element={<EditProfile userData={UserDetail} />}
                      />
                      <Route
                        path="my-payments"
                        element={<MyPayments userData={UserDetail} />}
                      />
                      
                       {/* <Route
                        path="credit_limit"
                        element={<Statement userData={UserDetail} />}
                      />
                       <Route
                        path="income_ledger"
                        element={<IncomeLedger userData={UserDetail} />}
                      /> */}
                      
                    </Routes>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
}

export default Dashboard;
