
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './Assets/style.css';
import './Assets/MediaQuries.css'
import 'react-toastify/dist/ReactToastify.css';
import 'rsuite/dist/rsuite.min.css'; // Import RSuite CSS
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import logo from './Assets/Images/whatsapplogo.jpeg'
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { BrowserRouter as Router,Routes,Route, Navigate } from 'react-router-dom';
import Home from './Pages/Home';
import Login from './Pages/AuthPages/Login';
import Register from './Pages/AuthPages/Register';
import Submitted from './Pages/AuthPages/Submitted';
import HotelDetail from './Pages/HotelDetail';
import HotelCheckout from './Pages/HotelCheckout';
import ProtectedRoute from './Pages/AuthPages/ProtectedRoute';
import HotelListing from './Pages/HotelListing';
import HotelVoucher from './Pages/HotelVoucher';
import HotelInvoice from './Pages/HotelInvoice';
import ContactusNew from './Pages/ContactusNew';
import AboutusNew from './Pages/AboutusNew';
import Dashboard from './Pages/Dashboard/index';
import Faqs from './Pages/Faqs';
import ForgetPassword from './Pages/AuthPages/ForgetPassword';
import ScrollToTop from './Components/ScrollTop';
function App() {
  return (
    <>
     <FloatingWhatsApp
      phoneNumber="+966566383738"
      accountName="Alsubaee Holidays"
      statusMessage="Online"
      chatMessage="Hello! How can we help you today?"
      avatar={logo}
      style={{ left: '15px', right: 'auto' }}
      notification
    />
   <Router>
   <ScrollToTop />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/submit" element={<Submitted />} />
        <Route path="/forgot_password" element={<ForgetPassword />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/hotel_detail/:id"
          element={
            <ProtectedRoute>
              <HotelDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/hotel_listing"
          element={
            <ProtectedRoute>
              <HotelListing />
            </ProtectedRoute>
          }
        />
        <Route
          path="/hotel_checkout"
          element={
            <ProtectedRoute>
              <HotelCheckout />
            </ProtectedRoute>
          }
        />
        <Route
          path="/hotel_voucher/:id"
          element={
              <HotelVoucher />
          }
        />
        <Route
          path="/hotel_invoice/:id"
          element={
              <HotelInvoice />
          }
        />
         <Route
          path="/contact_us"
          element={
            <ProtectedRoute>
              <ContactusNew />
            </ProtectedRoute>
          }
        />
         <Route
          path="/about_us"
          element={
            <ProtectedRoute>
              <AboutusNew />
            </ProtectedRoute>
          }
        />
        <Route path="/dashboard/*" element={
          <ProtectedRoute>
           <Dashboard/>
          </ProtectedRoute>
          } />
          <Route path="/faqs" element={
          <ProtectedRoute>
           <Faqs/>
          </ProtectedRoute>
          } />
          
         <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
