import React, { useState, useEffect } from "react";
import { faEnvelope, faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import user from "../../Assets/Images/user.png";
import { Uploader, Button,Modal } from "rsuite";
import { ApiEndPoint, ApiToken } from "../../Data/Api";
import axios from "axios";
import { Tooltip, Whisper } from "rsuite";
import Plus from "@rsuite/icons/legacy/Plus";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../Components/Loader";
import { NavLink, useNavigate } from "react-router-dom";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { setLoginUser } from "../../reducers/hotelActions";
import { useDispatch } from "react-redux";
import pdf from "../../Assets/Images/pdf.webp";
function EditProfile({ userData }) {
  const token = ApiToken();
  const baseurl = ApiEndPoint();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileIdProf, setFileIdProf] = useState(null);
  const [modalCompanyDocs, setModalCompanyDocs] = useState(false);
  const [modalBillingDocs, setModalBillingDocs] = useState(false);
  const [filesUploadDocument, setFilesUploadDocument] = useState([]);
  const [paymentDocument, setPaymentDocument] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [personalInfoErrors, setPersonalInfoErrors] = useState({});
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    personalInfo: {
      firstName: userData.first_name,
      lastName: userData.last_name,
    },
    companyInfo: {
      companyName: userData.company_name,
      address: userData.company_address,
      contactNo: userData.company_Phone_no,
      website: userData.company_Website,
    },
    billingInfo: {
      accountName:
        userData.billing_Details === null ? "" : userData.account_Name,
      accountNumber:
        userData.billing_Details === null ? "" : userData.account_Number,
      IBAN: userData.billing_Details === null ? "" : userData.IBAN_Number,
      accountCredit:
        userData.billing_Details === null ? "" : userData.account_Credit,
    },
  });
  // console.log(userData);
  useEffect(() => {
    const imageUrl =
      "https://api.synchtravel.com/storage/app/public/b2b_Agents_Documents/id_Card/" +
      userData?.id_Card;

    if (userData?.id_Card !== null) {
      setFileList([
        {
          name: userData?.id_Card,
          fileKey: 1,
          url: imageUrl, // Keep the original URL for reference
        },
      ]);

      setFileIdProf(imageUrl); // Store the original URL
    }
  }, [userData]);
  const handlepersonalinfo = () => {
    let newErrors = {};
    if (!formData.personalInfo.firstName) {
      newErrors.firstName = "Please Enter First Name.";
    } else if (!/^[a-zA-Z\s]*$/.test(formData.personalInfo.firstName)) {
      newErrors.firstName = "First Name cannot contain special characters.";
    } else if (formData.personalInfo.firstName.length > 20) {
      newErrors.firstName = "First Name cannot be longer than 20 characters.";
    }

    if (!formData.personalInfo.lastName) {
      newErrors.lastName = "Please Enter Last Name.";
    } else if (!/^[a-zA-Z\s]*$/.test(formData.personalInfo.lastName)) {
      newErrors.lastName = "Last Name cannot contain special characters.";
    } else if (formData.personalInfo.lastName.length > 20) {
      newErrors.lastName = "Last Name cannot be longer than 20 characters.";
    }
    setPersonalInfoErrors(newErrors);
    if(Object.keys(newErrors).length === 0){
      return false;
    }else{
      return true;
    }
  };
  const handlecompanyinfo = () => {
    let newErrors = {};
    if (!formData.companyInfo.companyName) {
      newErrors.companyName = "Please Enter Company Name";
    } else if (
      formData.companyInfo.companyName.length < 10 ||
      formData.companyInfo.companyName.length > 50
    ) {
      newErrors.companyName = "Company Name must be between 10 and 50 characters.";
    }

    if (!formData.companyInfo.address) {
      newErrors.address = "Please Enter Address";
    } else if (
      formData.companyInfo.address.length < 10 ||
      formData.companyInfo.address.length > 50
    ) {
      newErrors.address = "Address must be between 10 and 50 characters.";
    }

    if (!formData.companyInfo.contactNo) {
      newErrors.contactNo = "Please Enter Contact Number.";
    } else if (!/^\d{8,15}$/.test(formData.companyInfo.contactNo)) {
      newErrors.contactNo = "Contact Number must be between 8 and 15 digits.";
    }

    if (!formData.companyInfo.website) {
      newErrors.website = "Please Enter Website URL.";
    } else if (
      formData.companyInfo.website.length < 10 ||
      formData.companyInfo.website.length > 50
    ) {
      newErrors.website = "Website URL must be between 10 and 50 characters.";
    }
    if (!isValidURL(formData.companyInfo.website)) {
      newErrors.website = "Please enter a valid website URL, including 'http://' or 'https://'.";
    }
    if (!fileIdProf) {
      newErrors.fileIdProf = "Please Upload Id Proof.";
    }

    if (!userData.business_Document && filesUploadDocument.length === 0) {
      newErrors.filesUploadDocument = "Please Upload Business Document.";
    }
    setErrors(newErrors);
    if(Object.keys(newErrors).length === 0){
      return false;
    }else{
      return true;
    }
  };
  function isValidURL(url) {
    const pattern = /^(https?:\/\/)?([\w\d-]+\.)+[\w-]{2,}(\/[\w\d-_.~:?#[\]@!$&'()*+,;=]*)*\/?$/;
    return pattern.test(url);
  }
  const handlebillinginfo = () => {
    let newErrors = {};

    if (!formData.billingInfo.accountName) {
      newErrors.accountName = "Please Enter Account Name";
    } else if (
      formData.billingInfo.accountName.length < 10 ||
      formData.billingInfo.accountName.length > 50
    ) {
      newErrors.accountName = "Account Name must be between 10 and 50 characters.";
    }

    if (!formData.billingInfo.accountNumber) {
      newErrors.accountNumber = "Please Enter Account Number";
    } else if (
      formData.billingInfo.accountNumber.length < 8 ||
      formData.billingInfo.accountNumber.length > 20
    ) {
      newErrors.accountNumber = "Account Number must be between 8 and 20 characters.";
    }

    if (!formData.billingInfo.IBAN) {
      newErrors.IBAN = "Please Enter IBAN.";
    } else if (
      formData.billingInfo.IBAN.length < 8 ||
      formData.billingInfo.IBAN.length > 20
    ) {
      newErrors.IBAN = "IBAN Number must be between 8 and 20 characters.";
    }

    if (!formData.billingInfo.accountCredit) {
      newErrors.accountCredit = "Please Enter Account Credit.";
    }

    if (!userData.account_Document && paymentDocument.length === 0) {
      newErrors.paymentDocument = "Please Upload Billing Documents.";
    }
    setErrors(newErrors);
    if(Object.keys(newErrors).length === 0){
      return false;
    }else{
      return true;
    }
  };
  const handleChange = (e, section) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [section]: {
        ...formData[section],
        [name]: value,
      },
    });
  };
  const handleFileChange = (fileList) => {
    const bytesInMB = 1048576; // 1 MB in bytes
    let hasLargeFile = false;
    fileList.forEach((file) => {
      const fileSizeInBytes = file.blobFile.size;
      const fileSizeInMB = fileSizeInBytes / bytesInMB;

      // If any file exceeds the size limit, show an error and stop processing
      if (fileSizeInMB > 2) {
        toast.error(`File "${file.name}" exceeds the maximum limit of 2 MB.`);
        hasLargeFile = true; // Mark that there is a large file
        return; // Break out of the loop
      }
    });
    if (hasLargeFile) {
      return;
    }
    setFilesUploadDocument(fileList);
  };
  const handlePaymentFileChange = (fileList) => {
    const bytesInMB = 1048576; // 1 MB in bytes
    let hasLargeFile = false;
    fileList.forEach((file) => {
      const fileSizeInBytes = file.blobFile.size;
      const fileSizeInMB = fileSizeInBytes / bytesInMB;

      // If any file exceeds the size limit, show an error and stop processing
      if (fileSizeInMB > 2) {
        toast.error(`File "${file.name}" exceeds the maximum limit of 2 MB.`);
        hasLargeFile = true; // Mark that there is a large file
        return; // Break out of the loop
      }
    });
    if (hasLargeFile) {
      return;
    }
    setPaymentDocument(fileList);
  };
  const previewFile = (file, callback) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const removeImage = () => {
    setFileIdProf(null); // Clears the previewed image
    setUploading(false); // Resets uploading state
  };
  const handleUpload = (file) => {
    const bytesInMB = 1048576;
    const fileSizeInBytes = file.blobFile.size;
    const fileSizeInMB = fileSizeInBytes / bytesInMB;
    if (fileSizeInMB > 2) {
      toast.error("File size exceeds the maximum limit of 2 MB.");
      return;
    }
    setUploading(true);
    previewFile(file.blobFile, (value) => {
      setFileIdProf(value);
      setUploading(false);
    });
  };
  const handleSubmit = async () => {
    if (handlepersonalinfo()) {
      return;
    }
    if (handlecompanyinfo()) {
      return;
    }
    if (handlebillinginfo()) {
      return;
    }
    const submitData = new FormData();
    submitData.append("id", userData.id);
    submitData.append("token", token);
    submitData.append("email", formData.personalInfo.email);
    submitData.append("password", formData.personalInfo.password);
    submitData.append("title", ""); // Title is empty in the original data
    submitData.append("first_name", formData.personalInfo.firstName);
    submitData.append("last_name", formData.personalInfo.lastName);
    submitData.append("company_name", formData.companyInfo.companyName);
    submitData.append("company_address", formData.companyInfo.address);
    submitData.append("company_Website", formData.companyInfo.website);
    submitData.append("country", userData.country); // Country is empty in the original data
    submitData.append("city",userData.city); // City is empty in the original data
    submitData.append("phone_no", userData.phone_no);
    submitData.append("company_Phone_no", formData.companyInfo.contactNo);
    submitData.append("zip_code", ""); // Zip code is empty in the original data
    submitData.append("otp_code", ""); // OTP code is empty in the original data
    submitData.append("personal_Details", "Active");
    submitData.append("comapany_Details", "Active");
    submitData.append("billing_Details", "Active");
    if (fileList[0]?.blobFile) {
      submitData.append("id_Card", fileIdProf);
    }
    if (filesUploadDocument.length !== 0) {
      filesUploadDocument.forEach((item, index) => {
        submitData.append(
          `business_Document[${index}]`,
          item.blobFile,
          item.name
        );
      });
    }
    submitData.append("account_Name", formData.billingInfo.accountName);
    submitData.append("account_Number", formData.billingInfo.accountNumber);
    submitData.append("IBAN_Number", formData.billingInfo.IBAN);
    submitData.append("account_Credit", formData.billingInfo.accountCredit);
    submitData.append("", "");
    if (paymentDocument.length !== 0) {
      paymentDocument.forEach((item, index) => {
        submitData.append(
          `account_Document[${index}]`,
          item.blobFile,
          item.name
        );
      });
    }
    setIsLoading(true);
    try {
      const response = await axios.post(
        baseurl + "/api/update/customer/submit",
        submitData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setIsLoading(false);
      if (response.data.status === "success") {
        dispatch(setLoginUser(response.data.b2b_agent));
        toast.success("Your profile update successfully.");
        navigate("/dashboard/profile");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };
  const CompanyDocsModalChange=()=>{
    setModalCompanyDocs(!modalCompanyDocs);
  }
  const BillingDocsModalChange=()=>{
    setModalBillingDocs(!modalBillingDocs);
  }
  return (
    <>
    <Modal open={modalCompanyDocs} onClose={CompanyDocsModalChange}>
        <Modal.Header>
          <Modal.Title>Company Business Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
          <div style={{flexWrap:'wrap'}} className="d-flex align-items-center">
            {userData.business_Document !==undefined&& userData.business_Document !==null && JSON.parse(userData.business_Document).map((item, index) => {
              // Get the file extension to differentiate between image and PDF
              const fileExtension = item.split(".").pop().toLowerCase();
              const isImage = ["jpg", "jpeg", "png", "gif"].includes(
                fileExtension
              );
              const fileUrl =
                "https://api.synchtravel.com/public/uploads/b2b_Agents_Documents/business_Document/" +
                item;

              return (
                <div key={index} style={{ margin: "10px" }}>
                  {isImage ? (
                    // Render image if it's an image file
                    <img
                      style={{
                        width: "auto",
                        height: "10em",
                        objectFit: "cover",
                      }}
                      src={fileUrl}
                      alt={`Document ${index + 1}`}
                    />
                  ) : (
                    // Render a link or icon for PDFs
                    <a
                      href={fileUrl}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      className="text-center"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={pdf} // Add a generic PDF icon here
                        alt={`PDF Document ${index + 1}`}
                        style={{
                          width: "auto",
                          height: "10em",
                          objectFit: "cover",
                        }}
                      />
                      <p>PDF Document {index + 1}</p>
                    </a>
                  )}
                </div>
              );
            })}
          </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end align-items-center">
          
          <button  className="btn ms-2 btn-secondary" onClick={CompanyDocsModalChange}>
            close
          </button>
        </Modal.Footer>
      </Modal>
      <Modal open={modalBillingDocs} onClose={BillingDocsModalChange}>
        <Modal.Header>
          <Modal.Title>Account Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
          <div style={{flexWrap:'wrap'}} className="d-flex align-items-center">
            {userData.account_Document !==undefined&& userData.account_Document !==null && JSON.parse(userData.account_Document).map((item, index) => {
              // Get the file extension to differentiate between image and PDF
              const fileExtension = item.split(".").pop().toLowerCase();
              const isImage = ["jpg", "jpeg", "png", "gif"].includes(
                fileExtension
              );
              const fileUrl =
                "https://api.synchtravel.com/public/uploads/b2b_Agents_Documents/account_Document/" +
                item;

              return (
                <div key={index} style={{ margin: "10px" }}>
                  {isImage ? (
                    // Render image if it's an image file
                    <img
                      style={{
                        width: "auto",
                        height: "10em",
                        objectFit: "cover",
                      }}
                      src={fileUrl}
                      alt={`Document ${index + 1}`}
                    />
                  ) : (
                    // Render a link or icon for PDFs
                    <a
                      href={fileUrl}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      className="text-center"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={pdf} // Add a generic PDF icon here
                        alt={`PDF Document ${index + 1}`}
                        style={{
                          width: "auto",
                          height: "10em",
                          objectFit: "cover",
                        }}
                      />
                      <p>PDF Document {index + 1}</p>
                    </a>
                  )}
                </div>
              );
            })}
          </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end align-items-center">
          
          <button  className="btn ms-2 btn-secondary" onClick={BillingDocsModalChange}>
            close
          </button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
      {isLoading && <Loader />}
      <div class="card-header bg-transparent ">
        <h4 class="card-header-title">
          <FontAwesomeIcon icon={faUserCircle} /> Edit My Profile
        </h4>
      </div>
      <div class="profile_card card mt-4">
        <div class="card-body profile-user-box">
          <div class="row">
            <div class="col-sm-8">
              <div class="row align-items-center">
                <div class="col-auto">
                  <div class="avatar-lg">
                    <img
                      src={user}
                      alt=""
                      class="rounded-circle img-thumbnail"
                    />
                  </div>
                </div>
                <div class="col">
                  <div>
                    <h4 class="mt-1 mb-1 text-white">
                      {userData?.first_name} {userData?.last_name}
                    </h4>
                    <p class="font-13 text-white-50">
                      {" "}
                      <FontAwesomeIcon icon={faEnvelope} /> {userData?.email}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Personal-info-form">
        <h4 className="mt-3">Personal Information</h4>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group mt-3">
              <label>First Name</label>
              <input
                className="form-control mt-2"
                type="text"
                name="firstName"
                value={formData.personalInfo.firstName}
                onChange={(e) => handleChange(e, "personalInfo")}
                placeholder="Enter your first name"
              />
              {personalInfoErrors.firstName && <span className="text-danger">{personalInfoErrors.firstName}</span>}

            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mt-3">
              <label>Last Name</label>
              <input
                className="form-control mt-2"
                type="text"
                name="lastName"
                value={formData.personalInfo.lastName}
                onChange={(e) => handleChange(e, "personalInfo")}
                placeholder="Enter your last name"
              />
               {personalInfoErrors.lastName && <span className="text-danger">{personalInfoErrors.lastName}</span>}

            </div>
          </div>
        </div>
      </div>
      <div className="company-info-form">
        <h4 className="mt-3">Company Information</h4>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group mt-3">
              <label>Company Name</label>
              <input
                className="form-control mt-2"
                type="text"
                name="companyName"
                value={formData.companyInfo.companyName}
                onChange={(e) => handleChange(e, "companyInfo")}
                placeholder="abc company"
              />
              {errors.companyName && <span className="text-danger">{errors.companyName}</span>}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mt-3">
              <label>Address</label>
              <input
                className="form-control mt-2"
                type="text"
                name="address"
                value={formData.companyInfo.address}
                onChange={(e) => handleChange(e, "companyInfo")}
                placeholder="street 123"
              />
              {errors.address && <span className="text-danger">{errors.address}</span>}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mt-3">
              <label>Contact No</label>
              <input
                className="form-control mt-2"
                type="text"
                onKeyPress={(event) => {
                  if (!/[0-9+]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                name="contactNo"
                value={formData.companyInfo.contactNo}
                onChange={(e) => handleChange(e, "companyInfo")}
                placeholder="+123 456 7899"
              />
              {errors.contactNo && <span className="text-danger">{errors.contactNo}</span>}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mt-3">
              <label>Website</label>
              <input
                className="form-control mt-2"
                type="text"
                name="website"
                value={formData.companyInfo.website}
                onChange={(e) => handleChange(e, "companyInfo")}
                placeholder="www.example.com"
              />
              {errors.website && <span className="text-danger">{errors.website}</span>}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mt-3">
              <label>Id Proof</label>
              <div>
                <Uploader
                  fileList={[]}
                  onChange={(files) => {
                    setFileList(files); // Update the file list with the new file
                  }}
                  listType="picture"
                  accept=".png, .jpg, .jpeg"
                  action="//jsonplaceholder.typicode.com/posts/"
                  fileListVisible={false}
                  onUpload={handleUpload}
                >
                  <button style={{ width: 300, height: 150 }}>
                    {uploading && <Loader backdrop center size="md" />}
                    {fileIdProf ? (
                      <img
                        src={fileIdProf}
                        alt="Preview"
                        width="100%"
                        height="100%"
                      />
                    ) : (
                      <Plus style={{ fontSize: 20 }} />
                    )}
                  </button>
                </Uploader>
              </div>
              {errors.fileIdProf && <span className="text-danger">{errors.fileIdProf}</span>}
              {fileIdProf && (
                <div className="text-center">
                  <Button appearance="link" color="red" onClick={removeImage}>
                    Remove
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group d-upload mt-3">
              <div className='d-flex justify-content-between'>
              <label>
                Upload Business Document{" "}
                {userData?.business_Document !== null && (
                  <Whisper
                    placement="top"
                    controlId="control-id-hover"
                    trigger="hover"
                    speaker={
                      <Tooltip>
                        {" "}
                        If you want to change your business document, upload a
                        new one. Otherwise, leave it empty.
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </Whisper>
                )}
              </label>
               <p onClick={CompanyDocsModalChange} className="text-primary cursor-pointer">Previous Documents</p>
              </div>
              <div>
                <Uploader
                  className="mt-2"
                  defaultFileList={filesUploadDocument}
                  listType="picture-text"
                  accept=".png, .jpg, .jpeg, .pdf"
                  onChange={handleFileChange}
                  autoUpload={false}
                  action="//jsonplaceholder.typicode.com/posts/"
                >
                  <Button>Select files...</Button>
                </Uploader>
                {errors.filesUploadDocument && <span className="text-danger">{errors.filesUploadDocument}</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Billing-info-form">
        <h4 className="mt-3">Billing Information</h4>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group mt-3">
              <label>Account Name</label>
              <input
                className="form-control mt-2"
                type="text"
                name="accountName"
                value={formData.billingInfo.accountName}
                onChange={(e) => handleChange(e, "billingInfo")}
                placeholder="xyz"
              />
              {errors.accountName && <span className="text-danger">{errors.accountName}</span>}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mt-3">
              <label>Account Number</label>
              <input
                className="form-control mt-2"
                type="text"
                name="accountNumber"
                value={formData.billingInfo.accountNumber}
                onChange={(e) => handleChange(e, "billingInfo")}
                placeholder="street 123"
              />
              {errors.accountNumber && <span className="text-danger">{errors.accountNumber}</span>}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mt-3">
              <label>IBAN</label>
              <input
                className="form-control mt-2"
                type="text"
                name="IBAN"
                value={formData.billingInfo.IBAN}
                onChange={(e) => handleChange(e, "billingInfo")}
                placeholder="S000 1234567 89"
              />
               {errors.IBAN && <span className="text-danger">{errors.IBAN}</span>}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mt-3">
              <label>Account Credit</label>
              <input
                className="form-control mt-2"
                name="accountCredit"
                value={formData.billingInfo.accountCredit}
                onChange={(e) => handleChange(e, "billingInfo")}
                type="text"
              />
               {errors.accountCredit && <span className="text-danger">{errors.accountCredit}</span>}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group Billing mt-3">
            <div className='d-flex justify-content-between'>
              <label>
                Upload Account Document{" "}
                {userData?.account_Document !== null && (
                  <Whisper
                    placement="top"
                    controlId="control-id-hover"
                    trigger="hover"
                    speaker={
                      <Tooltip>
                        If you want to change your business document, upload a
                        new one. Otherwise, leave it empty.
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </Whisper>
                )}
              </label>
              <p onClick={BillingDocsModalChange} className="text-primary cursor-pointer">Previous Documents</p>

              </div>
              <div>
                <Uploader
                  className="mt-2"
                  accept=".png, .jpg, .jpeg, .pdf"
                  defaultFileList={paymentDocument}
                  listType="picture-text"
                  onChange={handlePaymentFileChange}
                  autoUpload={false}
                  action="//jsonplaceholder.typicode.com/posts/"
                >
                  <Button>Select files...</Button>
                </Uploader>
                {errors.paymentDocument && <span className="text-danger">{errors.paymentDocument}</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center my-4">
        <NavLink to='/dashboard/profile'>
      <button class="theme-btn-danger-1 me-2 ps-4 pe-4 py-2">
          Cancel
        </button>
        </NavLink>
        <button onClick={handleSubmit} class="theme-btn-1 ps-4 pe-4 py-2">
          Submit
        </button>
      </div>
    </>
  );
}
export default EditProfile;
