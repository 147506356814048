import { faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React,{useState,useEffect} from "react";
import { ApiEndPoint,ApiToken } from "../../Data/Api";
import axios from "axios";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import Loader from "../../Components/Loader";
function MyPayments(){
  const baseurl=ApiEndPoint();
  const token=ApiToken();
  const UserDetail = useSelector((state) => state.user.user);
  const [paymentList,setPaymentList]=useState([]);
  const [isLoading,setisLoading]=useState(true);
  useEffect(()=>{
    GetPaymentList();
  },[]);
  const GetPaymentList=async()=>{
    const data={
      token:token,
      b2b_agent_id: UserDetail.id,
    }
    
    try{
      const response=await axios.post(baseurl+'/api/B2B_Payment_List',data);
      setisLoading(false)
      if(response.data.status==='success'){
        var newlist=response.data.booking_data.filter(item=>item.status==='Confirmed');
        console.log(newlist);
        setPaymentList(newlist);
      }
    }catch(error){
      setisLoading(false)
      console.log(error);
    }
  }
  const columns = [
    {
      field: "invoice_no",
      headerName: "Invoice No",
      width: 230,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "lead_passenger",
      headerName: "Guest Name",
      width: 230,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Date",
      renderCell: (params) =>
        ` ${moment(params.row.created_at).format('ll')}`,
      headerName: "Date",
      width: 230,
      headerAlign: "center",
      align: "center",
    },
    {
        field: "Price",
        renderCell: (params) =>
          `${params.row.exchange_currency} ${params.row.exchange_price}`,
        headerName: "Price",
        width: 230,
        headerAlign: "center",
        align: "center",
      }
  ];

    return(
        <>
        {isLoading&&(<Loader/>)}
         <section class="pt-0">
        <div class="container vstack gap-4">
          <div class="row">
            <div class="col-12">
              <h4 class="fs-4 mb-0">
                <i class="bi bi-house-door fa-fw me-1">
                  <FontAwesomeIcon icon={faList} />{' '}
                </i>
                Payment List
              </h4>
            </div>
          </div>
          </div>
          </section>
         <div className="mt-4">
          <DataGrid className="mt-4" rows={paymentList} columns={columns} pageSize={5} />
          </div>
        </>
    )
}

export default MyPayments;