import {
  faFacebook,
  faInstagram,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
  faMailBulk,
  faMapMarkerAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../Assets/Images/logo.png";
import React from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";

function Footer() {
  return (
    <>
      <footer class="sm-sec-pad mt-4">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 mb-15-md col-lg-4">
              <h3>Saudia Arabia</h3>
              <p>
                <FontAwesomeIcon className="me-2" icon={faMapMarkerAlt} />{" "}
                Office #46, Sultana Center, Sultana street madina 41411
              </p>
              <p>
                {" "}
                <a href="tel://+966593940580">
                  <i class="ti-mobile">
                    <FontAwesomeIcon className="me-2" icon={faPhone} />
                  </i>{" "}
                  +966 59 394 0580
                </a>
              </p>
            </div>

            <div class="col-12 col-md-6 mb-15-md col-lg-4">
              <h3>United States</h3>
              <p>
                <FontAwesomeIcon className="me-2" icon={faMapMarkerAlt} /> 159
                Meodow St, Suit #363, Naugatuck CT, 067700
              </p>
              <p>
                {" "}
                <a href="tel://+14752379600">
                  <i class="ti-mobile">
                    <FontAwesomeIcon className="me-2" icon={faPhone} />
                  </i>{" "}
                  +1 475 237 9600
                </a>
              </p>
            </div>

            <div class="col-12 col-md-6 mb-15-md col-lg-4">
              <h3>Get in Touch</h3>
              <p>
                <a
                  href="mailto:info@AlSubaeeHolidays.com"
                  title=" info@AlSubaeeHolidays.com"
                >
                  <svg
                    class="svg-inline--fa fa-envelope fa-w-16"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fa"
                    data-icon="envelope"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                    ></path>
                  </svg>{" "}
                  <i class="fa fa-envelope"></i>&nbsp; info@AlSubaeeHolidays.com
                </a>
              </p>
              <p class="mt-15">
                <a
                
                  class="sm-icon facebook"
                  title="Facebook"
                  target="_blank"
                >
                  <svg
                    class="svg-inline--fa fa-facebook-f fa-w-9"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="facebook-f"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 264 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M215.8 85H264V3.6C255.7 2.5 227.1 0 193.8 0 124.3 0 76.7 42.4 76.7 120.3V192H0v91h76.7v229h94V283h73.6l11.7-91h-85.3v-62.7c0-26.3 7.3-44.3 45.1-44.3z"
                    ></path>
                  </svg>{" "}
                  <i class="fab fa-facebook-f"></i>
                </a>
                &nbsp;
                <a
                 
                  class="sm-icon linkedin"
                  title="LinkedIn"
                  target="_blank"
                >
                  <svg
                    class="svg-inline--fa fa-linkedin-in fa-w-14"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="linkedin-in"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M100.3 448H7.4V148.9h92.9V448zM53.8 108.1C24.1 108.1 0 83.5 0 53.8S24.1 0 53.8 0s53.8 24.1 53.8 53.8-24.1 54.3-53.8 54.3zM448 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448h-.1z"
                    ></path>
                  </svg>{" "}
                  <i class="fab fa-linkedin-in"></i>
                </a>
                &nbsp;
                <a
                 
                  class="sm-icon google-plus"
                  title="Youtube"
                  target="_blank"
                >
                  <svg
                    class="svg-inline--fa fa-youtube fa-w-18"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="youtube"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                    ></path>
                  </svg>{" "}
                  <i class="fab fa-youtube"></i>
                </a>
              </p>
            </div>
          </div>

          <div class="footer-copyrights">
            <div class="row">
              <div class="col-12 col-sm-12">
                © {moment().format("yyyy")} Alsubaee All Rights Reserved
              </div>
            </div>
          </div>
        </div>
      </footer>
      <section class="primary-bg page-nav-container">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12 col-md-12 col-lg-9">
              <div class="menu-page-menu-container">
                <ul class="page-nav" style={{ textAlign: "left" }}>
                  <li
                    id="menu-item-8754"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-8754"
                  >
                    <NavLink className="text-decoration-none" to="/about_us">
                      Who we are
                    </NavLink>
                  </li>
                  <li
                    id="menu-item-8758"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-8758"
                  >
                    <NavLink className="text-decoration-none" to="/faqs">
                      Faqs
                    </NavLink>
                  </li>
                  <li
                    id="menu-item-8759"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-8759"
                  >
                    <NavLink className="text-decoration-none" to="/contact_us">
                      Contact us
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-12 col-md-12 col-lg-3">
              <div class="green_nav-sub_header">
                <a className="text-decoration-none" href="tel:+966593940580" style={{ color: "#fff" }}>
                  <svg
                    class="svg-inline--fa fa-phone fa-w-16"
                    style={{ fontSize: "13px" }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fa"
                    data-icon="phone"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"
                    ></path>
                  </svg>{" "}
                  <i class="fa fa-phone" style={{ fontSize: "13px" }}></i>{" "}
                  &nbsp; Call
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a
                className="text-decoration-none"
                  href="mailto:info@AlSubaeeHolidays.com"
                  style={{ color: "#fff" }}
                >
                  <svg
                    class="svg-inline--fa fa-envelope fa-w-16"
                    style={{ fontSize: "13px" }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fa"
                    data-icon="envelope"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                    ></path>
                  </svg>{" "}
                  <i class="fa fa-envelope" style={{ fontSize: "13px" }}></i>{" "}
                  &nbsp; Email
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <footer>
        <div class="footer-overlay"></div>
        <div class="container margin_60">
          <div class="row">
            <div class="col-lg-5 col-md-12 pe-5">
              <p>
                <NavLink to="/">
                  <img src={logo} width="100" height="110" alt="" />
                </NavLink>
              </p>
              <p>
                At AlSubaee , we connect travel partners to organizers and
                travel companies looking for Hajj and Umrah lodging and
                transport services.
              </p>
              <div class="follow_us">
                <ul>
                  <li>Follow us</li>
                  <li>
                    <a href="#0">
                      <i class="bi bi-facebook">
                        <FontAwesomeIcon icon={faFacebook} />
                      </i>
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i class="bi bi-twitter-x">
                        {" "}
                        <FontAwesomeIcon icon={faTwitter} />
                      </i>
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i class="bi bi-instagram">
                        <FontAwesomeIcon icon={faInstagram} />
                      </i>
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i class="bi bi-whatsapp">
                        <FontAwesomeIcon icon={faWhatsapp} />
                      </i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 ms-lg-auto">
              <h5>Useful links</h5>
              <ul class="links">
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>
                <li>
                  <NavLink to="/about_us">Who we are</NavLink>
                </li>
                <li>
                  <NavLink to="/faqs">FAQs</NavLink>
                </li>
                <li>
                  <NavLink to="/contact_us">Contact us</NavLink>
                </li>
              </ul>
            </div>
            <div class="col-lg-3 col-md-6">
              <h5>Contact Info</h5>
              <ul class="contacts">
                <li>
                  {" "}
                  <p className="text-success mb-2">Location: Saudia Arabia </p>
                </li>
                <li>
                  <a>
                    <i class="ti-mobile">
                      <FontAwesomeIcon className="me-2" icon={faMapMarkerAlt} />
                    </i>{" "}
                    Office #46, Sultana Center, Sultana street madina 41411{" "}
                  </a>
                </li>
                <li>
                  <a href="tel://+966593940580">
                    <i class="ti-mobile">
                      <FontAwesomeIcon className="me-2" icon={faPhone} />
                    </i>{" "}
                    +966 59 394 0580
                  </a>
                </li>
                <li>
                  <a href="mailto:info@AlSubaeeHolidays.com">
                    <FontAwesomeIcon className="me-2" icon={faEnvelope} />{" "}
                    info@AlSubaeeHolidays.com
                  </a>
                </li>
              </ul>
              <ul class="contacts">
                <li>
                  {" "}
                  <p className="text-success mb-2">Location: United States </p>
                </li>
                <li>
                  <a>
                    <i class="ti-mobile">
                      <FontAwesomeIcon className="me-2" icon={faMapMarkerAlt} />
                    </i>{" "}
                    159 Meodow St, Suit #363, Naugatuck CT, 067700{" "}
                  </a>
                </li>
                <li>
                  <a href="tel://+14752379600">
                    <i class="ti-mobile">
                      <FontAwesomeIcon className="me-2" icon={faPhone} />
                    </i>{" "}
                    +1 475 237 9600
                  </a>
                </li>
              </ul>
              
            </div>
          </div>
          <hr />
          <div class="row">
            <div className="login-footer my-2">
              <p className="text0center">© {moment().format('yyyy')} Alsubaee All Rights Reserved</p>
            </div>
          </div>
        </div>
      </footer> */}
    </>
  );
}
export default Footer;
