import { faEnvelope, faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import user from "../../Assets/Images/user.png";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import pdf from "../../Assets/Images/pdf.webp";
import { NavLink } from "react-router-dom";
function Profile({ userData }) {
  // console.log(userData);
  return (
    <>
      <div class="card-header bg-transparent ">
        <h4 class="card-header-title">
          <FontAwesomeIcon icon={faUserCircle} /> My Profile
        </h4>
      </div>
      <div class="profile_card card mt-4">
        <div class="card-body profile-user-box">
          <div class="row">
            <div class="col-sm-8">
              <div class="row align-items-center">
                <div class="col-auto">
                  <div class="avatar-lg">
                    <img
                      src={user}
                      alt=""
                      class="rounded-circle img-thumbnail"
                    />
                  </div>
                </div>
                <div class="col">
                  <div>
                    <h4 class="mt-1 mb-1 text-white">
                      {userData?.first_name} {userData?.last_name}
                    </h4>
                    <p class="font-13 text-white-50">
                      {" "}
                      <FontAwesomeIcon icon={faEnvelope} /> {userData?.email}
                    </p>
                    {/* <p class="font-13 text-white-50">
                      {" "}
                      <FontAwesomeIcon icon={faPhone}/> {userData?.phone_no}
                    </p> */}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="text-center mt-sm-0 mt-3 text-sm-end">
                <NavLink to='/dashboard/edit-profile'>
                  <button type="button" class="btn btn-light">
                    <i class="mdi mdi-account-edit me-1"></i> Edit Profile
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h4 className="mt-4">Profile Info</h4>
      <div className="row ">
        <div className="col-md-6 mt-2">
          <label className="fw-bold">First Name</label>
          <p>{userData?.first_name}</p>
        </div>
        <div className="col-md-6 mt-2">
          <label className="fw-bold">Last Name</label>
          <p>{userData?.last_name}</p>
        </div>
      </div>
      <h4 className="mt-4">Company Info</h4>
      <div className="row ">
        <div className="col-md-4 mt-2">
          <label className="fw-bold">Company Name</label>
          <p>{userData?.company_name === null ? 'N/A' : userData?.company_name}</p>
        </div>
        <div className="col-md-4 mt-2">
          <label className="fw-bold">Company Address</label>
          <p>{userData?.company_address === null ? 'N/A' : userData?.company_address}</p>
        </div>
        <div className="col-md-4 mt-2">
          <label className="fw-bold">Company Contact</label>
          <p>{userData?.company_Phone_no === null ? 'N/A' : userData?.company_Phone_no}</p>
        </div>
        <div className="col-md-4 mt-2">
          <label className="fw-bold">Website</label>
          <p>{userData?.company_Website === null ? 'N/A' : userData?.company_Website}</p>
        </div>
        <div className=" mt-2">
          <p className="fw-bold">Id Card Proof</p>
          {userData?.id_Card !==null && (
          <img
          className="mt-2"
            style={{ width: "auto", height: "15em", objectFit: "cover" }}
            src={
              "https://api.synchtravel.com/storage/app/public/b2b_Agents_Documents/id_Card/" +
              userData?.id_Card
            }
          />
        )}
        </div>
        <div className=" mt-2">
          <label className="fw-bold">Business Documents</label>
          <div style={{flexWrap:'wrap'}} className="d-flex align-items-center">
            {userData.business_Document !==undefined&& userData.business_Document !==null && JSON.parse(userData.business_Document).map((item, index) => {
              // Get the file extension to differentiate between image and PDF
              const fileExtension = item.split(".").pop().toLowerCase();
              const isImage = ["jpg", "jpeg", "png", "gif"].includes(
                fileExtension
              );
              const fileUrl =
                "https://api.synchtravel.com/public/uploads/b2b_Agents_Documents/business_Document/" +
                item;

              return (
                <div key={index} style={{ margin: "10px" }}>
                  {isImage ? (
                    // Render image if it's an image file
                    <img
                      style={{
                        width: "auto",
                        height: "15em",
                        objectFit: "cover",
                      }}
                      src={fileUrl}
                      alt={`Document ${index + 1}`}
                    />
                  ) : (
                    // Render a link or icon for PDFs
                    <a
                      href={fileUrl}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      className="text-center"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={pdf} // Add a generic PDF icon here
                        alt={`PDF Document ${index + 1}`}
                        style={{
                          width: "auto",
                          height: "15em",
                          objectFit: "cover",
                        }}
                      />
                      <p>PDF Document {index + 1}</p>
                    </a>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <h4 className="mt-4">Billing Info</h4>
      <div className="row ">
        <div className="col-md-4 mt-2">
          <label className="fw-bold">Account Name</label>
          <p>{userData?.account_Name === null ? 'N/A' : userData?.account_Name}</p>
        </div>
        <div className="col-md-4 mt-2">
          <label className="fw-bold">Account Number</label>
          <p>{userData?.account_Number === null ? 'N/A' : userData?.account_Number}</p>
        </div>
        <div className="col-md-4 mt-2">
          <label className="fw-bold">IBAN</label>
          <p>{userData?.IBAN_Number === null ? 'N/A' : userData?.IBAN_Number}</p>
        </div>
        <div className="col-md-4 mt-2">
          <label className="fw-bold">Account Credit</label>
          <p>{userData?.account_Credit === null ? 'N/A' : userData?.account_Credit}</p>
        </div>
        <div className=" mt-2">
          <label className="fw-bold">Account Documents</label>
          <div style={{flexWrap:'wrap'}} className="d-flex align-items-center">
            {userData.account_Document !==undefined&& userData.account_Document !==null && JSON.parse(userData.account_Document).map((item, index) => {
              // Get the file extension to differentiate between image and PDF
              const fileExtension = item.split(".").pop().toLowerCase();
              const isImage = ["jpg", "jpeg", "png", "gif"].includes(
                fileExtension
              );
              const fileUrl =
                "https://api.synchtravel.com/public/uploads/b2b_Agents_Documents/account_Document/" +
                item;

              return (
                <div key={index} style={{ margin: "10px" }}>
                  {isImage ? (
                    // Render image if it's an image file
                    <img
                      style={{
                        width: "auto",
                        height: "15em",
                        objectFit: "cover",
                      }}
                      src={fileUrl}
                      alt={`Document ${index + 1}`}
                    />
                  ) : (
                    // Render a link or icon for PDFs
                    <a
                      href={fileUrl}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      className="text-center"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={pdf} // Add a generic PDF icon here
                        alt={`PDF Document ${index + 1}`}
                        style={{
                          width: "auto",
                          height: "15em",
                          objectFit: "cover",
                        }}
                      />
                      <p>PDF Document {index + 1}</p>
                    </a>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
export default Profile;
