const initialState = {
    hotelDetail: {},
  };
  
  const hotelDetailReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_HOTEL_DETAIL':
        return {
          ...state,
          hotelDetail: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default hotelDetailReducer;
  