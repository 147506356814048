import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faBan,
  faCheck,
  faFileInvoice,
  faHouse,
  faReceipt,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { NavLink } from "react-router-dom";
import Chart from "react-apexcharts";
import { Modal, Button, Pagination } from "rsuite";
import Axios from "axios";
import { ApiEndPoint, ApiToken } from "../../Data/Api";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
function UserDashboard({ invoiceListData }) {
  // console.log(invoiceListData);
  const UserDetail = useSelector((state) => state.user.user);
  const baseurl = ApiEndPoint();
  const token = ApiToken();
  const [bookingList, setBookingList] = useState(invoiceListData);
  const [defaultList, setDefaultList] = useState(invoiceListData);
  const [filterList, setFilterList] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [textError, setTextError] = useState("");
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [requestModal, setRequestModal] = useState(false);
  const [requestText, setRequestText] = useState("");
  const [requestInvoiceNo, setRequestInvoiceNo] = useState("");
  const [tentativeBookins, setTentativeBookins] = useState(0);
  const [autoCancelledInNext24Hours, setAutoCancelledInNext24Hours] =
    useState(0);
  const [autoCancelledInNext4Days, setAutoCancelledInNext4Days] = useState(0);
  const [cancelledInPrevious24Hours, setCancelledInPrevious24Hours] =
    useState(0);
  const [cancelledInPrevious4Days, setCancelledInPrevious4Days] = useState(0);

  useEffect(() => {
    setBookingList(invoiceListData);
    setDefaultList(invoiceListData);
    var filteredlist = invoiceListData.filter((item) => {
      const reservation = JSON.parse(item.reservation_response);
      const checkinDate = new Date(reservation.hotel_details.checkIn); // Assuming the date is in a valid format
      const today = new Date();

      // Compare dates: only return items where the check-in date is in the future
      return checkinDate > today;
    });
    var tentative = invoiceListData.filter(
      (item) => item.status === "Tentative"
    );
    setFilterList(filteredlist);
    setTentativeBookins(tentative.length);
    const checkCancellations = () => {
      const today = new Date();
      let next24HoursCount = 0;
      let next4DaysCount = 0;
      let previous24HoursCount = 0;
      let previous4DaysCount = 0;

      invoiceListData.forEach((item) => {
        const hotelDetail = JSON.parse(item.reservation_response).hotel_details;

        // Extract the cancellation policies from the hotel details
        const cancellationPolicies = hotelDetail.rooms.flatMap((room) =>
          room.room_rates.flatMap((rate) =>
            rate.cancellation_policy.map((policy) => ({
              ...policy,
              net: rate.net, // Include net value for comparison
            }))
          )
        );

        cancellationPolicies.forEach((policy) => {
          const cancellationDate = new Date(policy.from_date);

          // Calculate the time differences in hours and days
          const timeDiffInHours = (cancellationDate - today) / (1000 * 60 * 60);
          const timeDiffInDays = timeDiffInHours / 24;

          // Count based on status
          if (item.status.toLowerCase() === "confirmed") {
            // Next 24 hours (auto-cancel) with free cancellation check
            if (
              timeDiffInHours > 0 &&
              timeDiffInHours <= 24 &&
              parseFloat(policy.amount) === parseFloat(policy.net)
            ) {
              next24HoursCount += 1;
            }

            // Next 4 days with free cancellation check
            if (
              timeDiffInDays > 0 &&
              timeDiffInDays <= 4 &&
              parseFloat(policy.amount) === parseFloat(policy.net)
            ) {
              next4DaysCount += 1;
            }
          }

          if (item.status.toLowerCase() === "cancelled") {
            // Previous 24 hours
            if (timeDiffInHours < 0 && timeDiffInHours >= -24) {
              previous24HoursCount += 1;
            }

            // Previous 4 days
            if (timeDiffInDays < 0 && timeDiffInDays >= -4) {
              previous4DaysCount += 1;
            }
          }
        });
      });

      setAutoCancelledInNext24Hours(next24HoursCount);
      setAutoCancelledInNext4Days(next4DaysCount);
      setCancelledInPrevious24Hours(previous24HoursCount);
      setCancelledInPrevious4Days(previous4DaysCount);
    };

    if (invoiceListData.length !== 0) {
      checkCancellations();
    }
  }, [invoiceListData]);
  const handleChange = (event) => {
    setFilterText(event.target.value);

    const filterValue = event.target.value.toLowerCase();
    const filterdata = invoiceListData.filter((item) => {
      const hotelName = JSON.parse(
        item.reservation_response
      ).hotel_details.hotel_name.toLowerCase();
      const leadPassenger = item.lead_passenger.toLowerCase();
      const brnNo =
        item.brn_Number == null ? "" : item.brn_Number.toLowerCase();
      const hcnNo =
        item?.hcn_Number == null ? "" : item.hcn_Number.toLowerCase();
      const invoiceno = item.invoice_no.toLowerCase();

      // Check if any of the fields contain the filter text
      return (
        hotelName.includes(filterValue) ||
        leadPassenger.includes(filterValue) ||
        brnNo.includes(filterValue) ||
        hcnNo.includes(filterValue) ||
        invoiceno.includes(filterValue)
      );
    });

    setDefaultList(filterdata);
  };
  const aggregatedData = aggregateBookingsByMonth(invoiceListData);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const totalBookingsData = months.map(
    (month) => aggregatedData.total[month] || 0
  );
  const confirmedBookingsData = months.map(
    (month) => aggregatedData.confirmed[month] || 0
  );
  const cancelledBookingsData = months.map(
    (month) => aggregatedData.cancelled[month] || 0
  );

  const options = {
    chart: {
      id: "booking-chart",
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    colors: ["#46a8a7", "#33FF57", "#ff0606"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: months,
    },
    yaxis: {
      title: {
        text: "Booking  Count",
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    legend: {
      position: "top",
    },
  };

  const series = [
    {
      name: "Total Bookings",
      data: totalBookingsData,
    },
    {
      name: "Confirmed Bookings",
      data: confirmedBookingsData,
    },
    {
      name: "Cancelled Bookings",
      data: cancelledBookingsData,
    },
  ];
  const handlerequestChange = (event) => {
    setRequestText(event.target.value);
  };
  const RequestModalOpen = (id) => {
    setRequestModal(true);
    setRequestInvoiceNo(id);
  };
  const SubmitRequestCAncel = async () => {
    if (requestText === "") {
      setTextError("Please enter a reason for cancellation.");
      return;
    }
    setIsSubmiting(true);
    var data = {
      token: token,
      invoice_no: requestInvoiceNo,
      cancellation_Request_Message: requestText,
      b2b_agent_id: UserDetail.id,
    };
    try {
      const response = await Axios.post(
        baseurl + "/api/make_Cancel_Request",
        data
      );
      setIsSubmiting(false);
      setRequestModal(false);
      setRequestText("");
      if (response.data.status === "success") {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setRequestModal(false);
      setIsSubmiting(false);
      console.log(error);
    }
  };
  const handleChangePage = (newPage) => {
    const data = bookingList.filter((v, i) => {
      const start = limit * (newPage - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
    setDefaultList(data);
    setPage(newPage);
  };
  const handleChangeLimit = (newLimit) => {
    const data = bookingList.filter((v, i) => {
      const start = newLimit * (page - 1);
      const end = start + newLimit;
      return i >= start && i < end;
    });
    setDefaultList(data);
    setLimit(newLimit);
    setPage(1); // Reset page to 1 when limit changes
  };

  return (
    <>
      {/* <h5>Dashboard Statistics</h5> */}
      <ToastContainer />
      <Modal open={requestModal} onClose={() => setRequestModal(false)}>
        <Modal.Header>
          <Modal.Title>Request Cancellation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-1">
            <p className="h6 mb-2">Enter Request Cancellation Reason</p>
            <textarea
              class="form-control"
              value={requestText}
              onChange={handlerequestChange}
              rows="3"
            ></textarea>
            {textError && <p className="text-danger">{textError}</p>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isSubmiting ? (
            <Button appearance="primary" loading>
              Primary
            </Button>
          ) : (
            <Button onClick={SubmitRequestCAncel} appearance="primary">
              Submit
            </Button>
          )}
          <Button onClick={() => setRequestModal(false)} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <section class="pt-0">
        <div class="container vstack gap-4">
          <div class="row">
            <div class="col-12">
              <h4 class="fs-4 mb-0">
                <i class="bi bi-house-door fa-fw me-1">
                  <FontAwesomeIcon icon={faHouse} />
                </i>
                Dashboard
              </h4>
            </div>
          </div>
          <div class="row g-4">
            <div class="col-sm-6 col-xl-4">
              <div class="card p-1  mt-1 border">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="ms-3">
                    <h5>Total Bookings</h5>
                  </div>
                  <div class="icon-xl bg-info rounded-3 text-white">
                    {invoiceListData.length}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xl-4">
              <div class="card p-1  border">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="ms-3">
                    <h5>Confirmed Bookings</h5>
                  </div>
                  <div class="icon-xl bg-success rounded-3 text-white">
                    {invoiceListData.length === 0
                      ? 0
                      : invoiceListData.filter(
                          (booking) =>
                            booking.status.toLowerCase() === "confirmed"
                        ).length}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xl-4">
              <div class="card p-1  mt-1 border">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="ms-3">
                    <h5>Tentative Bookings</h5>
                  </div>
                  <div class="icon-xl bg-info rounded-3 text-white">
                    {tentativeBookins}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xl-4">
              <div class="card p-1 border">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="ms-3">
                    <h5>Cancelled Bookings</h5>
                  </div>
                  <div class="icon-xl bg-danger rounded-3 text-white">
                    {invoiceListData.length === 0
                      ? 0
                      : invoiceListData.filter(
                          (booking) =>
                            booking.status.toLowerCase() === "cancelled"
                        ).length}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xl-4">
              <div class="card p-1 card-body border">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="ms-3">
                    <h5>On Request Bookings</h5>
                  </div>
                  <div class="icon-xl bg-primary rounded-3 text-white">
                    {invoiceListData.length === 0
                      ? 0
                      : invoiceListData.filter(
                          (booking) =>
                            booking.status.toLowerCase() === "room on request"
                        ).length}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xl-4">
              <div class="card p-1 card-body border">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="ms-3">
                    <h5>Paid Bookings</h5>
                  </div>
                  <div class="icon-xl bg-info rounded-3 text-white">
                    {invoiceListData.length === 0
                      ? 0
                      : invoiceListData.filter(
                          (booking) =>
                            booking.status.toLowerCase() === "confirmed" &&
                            booking.payment_details !== null &&
                            JSON.parse(booking.payment_details) !== ""
                        ).length}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xl-4">
              <div class="card p-1 card-body border">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="ms-3">
                    <h5>Unpaid Bookings</h5>
                  </div>
                  <div class="icon-xl bg-warning rounded-3 text-white">
                    {invoiceListData.length === 0
                      ? 0
                      : invoiceListData.filter(
                          (booking) =>
                            (booking.status.toLowerCase() === "confirmed" &&
                              booking.payment_details === null) ||
                            JSON.parse(booking.payment_details) === ""
                        ).length}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
          <div class="row g-4">
            <div class="col-sm-12 col-xl-6">
              <div class="card card-body border rounded-1">
                <h6>Free Cancellation</h6>
                <h6 className="text-secondary mt-3">
                  {autoCancelledInNext24Hours} end in 24 hours
                </h6>
                <h6 className=" text-secondary mt-3">
                  {autoCancelledInNext4Days} end in 4 days
                </h6>
              </div>
            </div>

            <div class="col-sm-12 col-xl-6">
              <div class="card card-body border rounded-1">
                <h6>Cancelled Bookings</h6>
                <h6 className="text-secondary mt-3">
                  {cancelledInPrevious24Hours} previous 24 hours
                </h6>
                <h6 className=" text-secondary mt-3">
                  {cancelledInPrevious4Days} previous 4 days
                </h6>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card border rounded-3">
                <div class="card-header border-bottom">
                  <div class="d-sm-flex justify-content-between align-items-center">
                    <h5 class="mb-2 mb-sm-0">Arrival List</h5>
                    {/* <a href="#" class="btn btn-sm btn-primary mb-0">View All</a> */}
                  </div>
                </div>

                <div class="card-body">
                  <div class="row g-3 align-items-center justify-content-between mb-3">
                    <div class="col-md-8">
                      <form class="rounded position-relative">
                        <input
                          class="form-control pe-5"
                          type="search"
                          value={filterText}
                          onChange={handleChange}
                          placeholder="Search by hotel name"
                          aria-label="Search"
                        />
                        {/* <button class="btn border-0 px-3 py-0 position-absolute top-50 end-0 translate-middle-y" type="submit"><i class="fas fa-search fs-6"></i></button> */}
                      </form>
                    </div>
                  </div>
                  <div class="table-responsive border-0">
                    <table class="table align-middle p-4 mb-0 table-hover table-shrink">
                      <thead class="table-light">
                        <tr>
                          <th scope="col" class="border-0 rounded-start">
                            #
                          </th>
                          <th scope="col" class="border-0">
                            Description
                          </th>
                          <th scope="col" class="border-0">
                            Hotel Name
                          </th>
                          <th scope="col" class="border-0">
                            Additional Meal
                          </th>
                          <th scope="col" class="border-0">
                            Status/Payment
                          </th>
                          <th scope="col" class="border-0">
                            Action
                          </th>
                        </tr>
                      </thead>

                      <tbody class="border-top-0">
                        {bookingList.length !== 0 &&
                          defaultList
                            .filter((item) => {
                              const reservation = JSON.parse(
                                item.reservation_response
                              );
                              const checkinDate = new Date(
                                reservation.hotel_details.checkIn
                              ); // Assuming the date is in a valid format
                              const today = new Date();

                              // Compare dates: only return items where the check-in date is in the future
                              return checkinDate > today;
                            })
                            .map((item, index) => {
                              let payment;
                              const reservation = JSON.parse(
                                item.reservation_response
                              );
                              const reservationreq = JSON.parse(
                                item.reservation_request
                              );
                              var meallist = [];
                              if (
                                reservationreq?.meal_Details &&
                                reservationreq.meal_Details !== undefined
                              ) {
                                meallist = JSON.parse(
                                  reservationreq?.meal_Details
                                );
                              }
                              if (item.payment_details !== null) {
                                if (JSON.parse(item.payment_details) !== "") {
                                  payment = "Full Payment";
                                } else {
                                  payment = "No Payment";
                                }
                              } else {
                                payment = "No Payment";
                              }
                              return (
                                <tr key={item.id}>
                                  <td>
                                    {" "}
                                    <h6 class="mb-0">{index + 1}</h6>{" "}
                                  </td>
                                  <td>
                                    Invoice No : {item.invoice_no}
                                    <br />
                                    HCN No : {item.hcn_Number}
                                    <br />
                                    Guest Name : {item.lead_passenger}
                                    <br />
                                    Al Subaee Res No : {item.brn_Number}
                                  </td>
                                  <td>
                                    <h6 class="mb-0">
                                      {reservation.hotel_details.hotel_name}{" "}
                                    </h6>
                                    {reservation?.hotel_details?.rooms.map(
                                      (item, index) => (
                                        <p className="mt-0 pt-0" key={index}>
                                          {index + 1} {item.room_name}-
                                          {item.room_rates.map(
                                            (rate, index) => (
                                              <span key={index}>
                                                {rate.room_board}
                                              </span>
                                            )
                                          )}
                                        </p>
                                      )
                                    )}
                                    <p>
                                      {moment(
                                        reservation.hotel_details.checkIn
                                      ).format("ll")}{" "}
                                      <FontAwesomeIcon icon={faArrowRight} />{" "}
                                      {moment(
                                        reservation.hotel_details.checkOut
                                      ).format("ll")}
                                    </p>
                                  </td>
                                  <td>
                                    {meallist.map((meal, index) => (
                                      <small className="mt-0 pt-0" key={index}>
                                        {index + 1} {meal.name}
                                        <br />
                                      </small>
                                    ))}
                                  </td>
                                  <td>
                                    {" "}
                                    <div
                                      class={`badge ${
                                        item.status.toLowerCase() ===
                                        "confirmed"
                                          ? "text-bg-success"
                                          : item.status.toLowerCase() ===
                                            "cancelled"
                                          ? "text-bg-danger "
                                          : "text-bg-info"
                                      } `}
                                    >
                                      {item.status}
                                    </div>
                                    <br />
                                    {item.payment_Reject_Status ===
                                    "Rejected" ? (
                                      <div className="badge bg-danger text-danger bg-opacity-10">
                                        Payment Rejected
                                      </div>
                                    ) : (
                                      <div
                                        class={`mt-1 badge ${
                                          payment == "No Payment"
                                            ? "bg-danger text-danger"
                                            : "bg-success text-success"
                                        } bg-opacity-10 `}
                                      >
                                        {payment}
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    <a
                                      href={`/hotel_voucher/${item.invoice_no}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <p class="btn btn-sm btn-primary-soft mb-0">
                                        View Voucher
                                      </p>
                                    </a>
                                    <br />
                                    {item?.status === "Confirmed" && (
                                      <p
                                        onClick={() =>
                                          RequestModalOpen(item.invoice_no)
                                        }
                                        className="cursor-pointer"
                                        style={{
                                          width: "10.2em",
                                          fontSize: ".8em",
                                        }}
                                      >
                                        Request Cancellation
                                      </p>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                      </tbody>
                    </table>
                    <Pagination
                      prev
                      next
                      first
                      last
                      ellipsis
                      boundaryLinks
                      maxButtons={5}
                      className="mt-3"
                      size="xs"
                      layout={["total", "-", "|", "pager", "skip"]}
                      total={filterList.length}
                      limit={limit}
                      activePage={page}
                      onChangePage={handleChangePage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-4">
            <Chart options={options} series={series} type="area" height={350} />
          </div>
        </div>
      </section>
    </>
  );
}

const aggregateBookingsByMonth = (bookings) => {
  const monthMap = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  const result = {
    total: {},
    confirmed: {},
    cancelled: {},
  };

  bookings.forEach(({ created_at, status }) => {
    const month = created_at.slice(5, 7); // Extract the month (e.g., '08' for August)
    const monthName = monthMap[month]; // Get month name (e.g., 'Aug')

    // Initialize counts for the month if not already done
    if (!result.total[monthName]) {
      result.total[monthName] = 0;
      result.confirmed[monthName] = 0;
      result.cancelled[monthName] = 0;
    }

    // Increment total bookings
    result.total[monthName]++;

    // Increment according to status
    if (status && status.toLowerCase() === "confirmed") {
      result.confirmed[monthName]++;
    } else if (status && status.toLowerCase() === "cancelled") {
      result.cancelled[monthName]++;
    }
  });

  return result;
};
export default UserDashboard;
