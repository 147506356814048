import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem("authToken"); // Replace "authToken" with your actual token key
  const loginTime = localStorage.getItem("loginTime"); // Timestamp when token was set
  const currentTime = new Date().getTime();
  // return token ? children : <Navigate to="/login" />;
  if (token && loginTime && currentTime - loginTime < 7200000) {
    return children;
  } else {
    // If no token or token is expired, clear storage and redirect to login
    localStorage.removeItem("authToken");
    localStorage.removeItem("loginTime");
    return <Navigate to="/login" />;
  }
};

export default ProtectedRoute;