import React, { useEffect, useState, useCallback } from "react";
import DataTable from "react-data-table-component";
import Axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import { ApiEndPoint } from "../../Data/Api";
import { DataGrid } from "@mui/x-data-grid";
function IncomeStatement({ invoiceListData }) {
  const UserDetail = useSelector((state) => state.user.user);
  const baseurl = ApiEndPoint();
  const [filterText, setFilterText] = useState("");
  const [filterText1, setFilterText1] = useState("");
  const [filteredItems1, setfilteredItems1] = useState([]);
  const [totalCount, setTotalCount] = useState({BookingTotal:0,PaymentTotal:0});
  const [agentData, setAgentData] = useState({});
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [resetPaginationToggle1, setResetPaginationToggle1] = useState(false);

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
    rows: {
      style: {
        backgroundColor: "#f7f7fa",
      },
    },
  };

  const filteredItems = invoiceListData.filter(
    (item) =>
      (item.invoice_no &&
        item.invoice_no.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.lead_passenger &&
        item.lead_passenger.toLowerCase().includes(filterText.toLowerCase()))
  );
  useEffect(() => {
    if (Object.keys(agentData).length !== 0) {
      if (filterText1 === "") {
        setfilteredItems1(agentData?.b2b_Agent_AD);
      } else {
        var filtereded = agentData?.b2b_Agent_AD.filter(
          (item) => item.invoice_no === Number(filterText1)
        );
        setfilteredItems1(filtereded);
      }
    }
  }, [filterText1]);
  const downloadCSV = useCallback(() => {
    if (invoiceListData.length === 0) {
      return;
    }

    const csvData = invoiceListData.map((item, index) => {
      let hotelName = "";
      try {
        const reservation = JSON.parse(item.reservation_response);
        hotelName = reservation.hotel_details?.hotel_name || "";
      } catch (e) {
        hotelName = "";
      }

      return {
        Sr: index + 1,
        "Invoice No": item.invoice_no,
        "Passenger Name": item.lead_passenger,
        "Hotel Name": hotelName,
        "Total Amount": `${item.exchange_currency} ${Number(
          item.exchange_price
        ).toFixed(2)}`,
        "Commission Amount": `${item.exchange_currency} ${Number(
          item.commision_charges === null ? 0 : item.commision_charges
        )}`,
        "Payable Amount": `${item.exchange_currency} ${
          Number(item.exchange_price).toFixed(2) -
          Number(item.commision_charges)
        }`,
        Status: item.status,
      };
    });

    const csv = convertArrayOfObjectsToCSV(csvData);
    if (csv == null) return;

    const filename = "Booking_List.csv";
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [invoiceListData]);
  const downloadCSV1 = useCallback(() => {
    if (Object.keys(agentData).length === 0) {
      return;
    }
    if (agentData?.b2b_Agent_AD.length === 0) {
      return;
    }

    const csvData = agentData?.b2b_Agent_AD.map((item, index) => {
      return {
        Sr: index + 1,
        "B2B Agent": UserDetail.company_name,
        "Invoice No": item.invoice_no,
        "Total Balance": `${agentData?.currency_value} ${item.balance}`,
        "Total Received": `${agentData?.currency_value}  ${item.received}`,
        "Payment Date": moment(item.date).format("DD-MM-YYYY"),
      };
    });

    const csv = convertArrayOfObjectsToCSV(csvData);
    if (csv == null) return;

    const filename = "Transactions_List.csv";
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [agentData]);

  function convertArrayOfObjectsToCSV(array) {
    if (!array || !array.length) {
      return null;
    }

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]);

    let result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    const handlePrint = () => {
      const tableContent = `
       <h3 className='text-center'>AlSubaee Holidays</h3>
        <table border="1" style="width: 100%; border-collapse: collapse;">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Invoice No</th>
              <th>Passenger Name</th>
              <th>Hotel Name</th>
              <th>Total Amount</th>
              <th>Commission Amount</th>
              <th>Payable Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            ${filteredItems
              .map((item, index) => {
                let hotelName = "";
                try {
                  const reservation = JSON.parse(item.reservation_response);
                  hotelName = reservation.hotel_details?.hotel_name || "";
                } catch (e) {
                  hotelName = "";
                }

                return `
                <tr>
                  <td>${index + 1}</td>
                  <td>${item.invoice_no}</td>
                  <td>${item.lead_passenger}</td>
                  <td>${hotelName}</td>
                  <td>${item.exchange_currency} ${Number(
                  item.exchange_price
                ).toFixed(2)}</td>
                  <td>${item.exchange_currency} ${Number(
                  item.commision_charges === null ? 0 : item.commision_charges
                )}</td>
                  <td>${item.exchange_currency} ${
                  Number(item.exchange_price).toFixed(2) -
                  Number(item.commision_charges)
                }</td>
                  <td>${item.status}</td>
                </tr>
              `;
              })
              .join("")}
          </tbody>
        </table>
      `;

      const newWindow = window.open();
      newWindow.document.write("<html><head><title>Print Table</title>");
      newWindow.document.write("</head><body>");
      newWindow.document.write(tableContent);
      newWindow.document.write("</body></html>");
      newWindow.document.close();
      newWindow.print();
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        handlePrint={handlePrint}
        downloadCSV={downloadCSV}
        downloadPDF={handlePrint}
      />
    );
  }, [filterText, resetPaginationToggle, downloadCSV]);

  const subHeaderComponentMemo1 = React.useMemo(() => {
    const handleClear1 = () => {
      if (filterText1) {
        setResetPaginationToggle1(!resetPaginationToggle1);
        setFilterText1("");
      }
    };
    const handlePrint1 = () => {
      const tableContent = `
       <h3 className='text-center'>AlSubaee Holidays</h3>
        <table border="1" style="width: 100%; border-collapse: collapse;">
          <thead>
            <tr>
              <th>Sr</th>
              <th>B2B Agent</th>
              <th>Invoice No</th>
              <th>Total Balance</th>
              <th>Total Received</th>
              <th>Payment Date</th>
            </tr>
          </thead>
          <tbody>
            ${filteredItems1
              .map((item, index) => {
                return `
                <tr>
                  <td>${index + 1}</td>
                  <td>${UserDetail.company_name}</td>
                  <td>${item.invoice_no}</td>
                  <td>${agentData?.currency_value} ${item.balance}</td>
                  <td>${agentData?.currency_value}  ${item.received}</td>
                  <td>${moment(item.date).format("DD-MM-YYYY")}</td>
                </tr>
              `;
              })
              .join("")}
          </tbody>
        </table>
      `;

      const newWindow = window.open();
      newWindow.document.write("<html><head><title>Print Table</title>");
      newWindow.document.write("</head><body>");
      newWindow.document.write(tableContent);
      newWindow.document.write("</body></html>");
      newWindow.document.close();
      newWindow.print();
    };
    return (
      <FilterComponent1
        onFilter={(e) => setFilterText1(e.target.value)}
        onClear={handleClear1}
        filterText={filterText1}
        handlePrint={handlePrint1}
        downloadCSV={downloadCSV1}
        downloadPDF={handlePrint1}
      />
    );
  }, [filterText1, resetPaginationToggle1, downloadCSV1]);
  useEffect(() => {
    GetUserBooking();
  }, []);

  const GetUserBooking = async () => {
    const data = {
      token: UserDetail.token,
      b2b_Agent_id: UserDetail.id,
    };

    try {
      const response = await Axios.post(
        baseurl + "/api/b2b_Agent_Payment_Details",
        data
      );
      if (response.data.message === "success") {
        const rows = preprocessRows(response.data.hotels_bookings);
        var bookingtotal=response.data.hotels_bookings.reduce((sum, booking) => {
          return sum + (Number(booking.exchange_price) || 0);
        }, 0);
        var paymenttotal=response.data.hotels_bookings.reduce((sum, payment) => {
          var paymentdata=JSON.parse(payment.payment_details)

          if(payment.status==='Confirmed'){
            return sum + (Number(paymentdata.total_Price) || 0);
          }else{
            return sum+0;
          }
        }, 0);
        setTotalCount({BookingTotal:bookingtotal,PaymentTotal:paymenttotal})
        setAgentData(response.data);
        setfilteredItems1(rows);
      }
    } catch (error) {
      console.log(error);
      console.error("Error:", error);
    }
  };

  const columns = [
    {
      field: "count",
      headerName: "SR",
      width: 80,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "id",
      headerName: "Type",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => `${params.row.rowType==='Booking'?'Hotel' :params.row.id}`,
    },
    {
      field: "invoice_no",
      headerName: "Description",
      width: 150,
      headerAlign: "center",
     
      renderCell: (params) => {
        const reservation = JSON.parse(params.row.reservation_response);
        return (
          <div className="my-2">
            {params.row.rowType==='Booking'?(
              <>
               <div>{params.row.invoice_no}</div>
            <div className="my-1">{reservation.hotel_details.hotel_name}</div>
            {reservation?.hotel_details?.rooms.map((item, index) => (
              <p className="mt-0 pt-0" key={index}>
                {index + 1} {item.room_name}-
                {item.room_rates.map((rate, index) => (
                  <span key={index}>{rate.room_board}</span>
                ))}
              </p>
            ))}
              </>
            ):(
              <div>{params.row.payment_details.payment_Type}</div>
            )}
           
          </div>
        );
      },
    },
    {
      field: "check_in",
      headerName: "Check in/Check out",
      renderCell: (params) => {
        if(params.row.rowType==='Booking'){
        return(
          <div>
            <div>{moment(params.row.check_in).format("ll")}</div>
            <div>{moment(params.row.check_out).format("ll")}</div>
          </div>
        )
      }else{
        return(
          <div>
          </div>
        )
        }
      },
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "exchange_price",
      headerName: "Debit",
      width: 110,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => ` ${params.row.rowType==='Booking'?agentData?.userData?.currency_symbol:''} ${params.row.rowType==='Booking' ? params.row.exchange_price:''}`,
      
    },
    {
      field: "credit",
      headerName: "Credit",
      width: 110,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => ` ${params.row.rowType!=='Booking'?agentData?.userData?.currency_symbol:''} ${params.row.rowType!=='Booking' ? params.row.payment_details.total_Price:''}`,

    },
    {
      field: "lead_passenger",
      headerName: "Guest Name",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => ` ${params.row.rowType==='Booking'?params.row.lead_passenger:''}`,

    },
    {
      field: "date",
      headerName: "Date",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => ` ${params.row.rowType==='Booking'? moment(params.row.created_at).format("DD-MM-YYYY"): params.row.payment_details.payment_Date}`,

    },
    // {
    //     field: "Price",
    //     renderCell: (params) =>
    //       `${params.row.exchange_currency} ${params.row.exchange_price}`,
    //     headerName: "Price",
    //     width: 230,
    //     headerAlign: "center",
    //     align: "center",
    //   }
  ];
  const preprocessRows = (data) => {
    const rows = [];
    
    data.forEach((record) => {
      rows.push({
        ...record,
        rowType: "Booking", // add a row type for identification
        displayInfo: `Booking for ${record.lead_passenger}`,
      });
      
      if (record.status === "Confirmed") {
        rows.push({
          ...record,
          payment_details:JSON.parse(record.payment_details),
          id: `${record.id}-payment`, // unique ID for the payment row
          rowType: "Payment",
          displayInfo: `Payment Details for ${record.lead_passenger}`,
        });
      }
    });
    
    return rows.map((row, index) => ({
      ...row,
      count: index + 1, // The index starts from 1, not 0
    }));
  };
  return (
    <>
      <div className="agent-head">
        <h5>
          Total Transactions :{" "}
          {agentData?.hotels_bookings ? agentData?.hotels_bookings.length : "0"}
        </h5>
      </div>
      <div className="row mt-3">
        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
          <div className="agent-head">
            <h5>
              Debit: {agentData?.userData?.currency_symbol} {totalCount.BookingTotal}
            </h5>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
          <div className="agent-head">
            <h5>
              Credit: {agentData?.userData?.currency_symbol} {totalCount.PaymentTotal}
            </h5>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
          <div className="agent-head">
            <h5>
              Total Balance: {agentData?.userData?.currency_symbol} {totalCount.BookingTotal-totalCount.PaymentTotal}
            </h5>
          </div>
        </div>
      </div>
      <div id="dataTable">
        <DataGrid
          className="mt-4"
          autoHeight
          pageSizeOptions={[10, 25, 50, 100]}
          getRowHeight={() => "auto"}
          rows={filteredItems1}
          columns={columns}
          pageSize={5}
          getRowClassName={(params) =>
            params.row.rowType === 'Payment' ? 'highlighted-row' : ''
          }
        />

        {/* <DataTable
          columns={columns1}
          data={filteredItems1}
          pagination
          paginationResetDefaultPage={resetPaginationToggle1}
          subHeader
          subHeaderComponent={subHeaderComponentMemo1}
          persistTableHead
          customStyles={customStyles}
        /> */}
      </div>
    </>
  );
}

const FilterComponent = ({
  filterText,
  onFilter,
  onClear,
  handlePrint,
  downloadCSV,
  downloadPDF,
}) => (
  <>
    <div>
      <button className="agent-filter-btn" onClick={handlePrint}>
        Print
      </button>
      <button className="agent-filter-btn" onClick={downloadCSV}>
        Download CSV
      </button>
      <button className="agent-filter-btn me-1" onClick={downloadPDF}>
        Download PDF
      </button>
    </div>
    <input
      id="search"
      type="text"
      placeholder="Search..."
      value={filterText}
      onChange={onFilter}
    />
    <button type="button" className="agent-filter-btn" onClick={onClear}>
      Clear
    </button>
  </>
);
const FilterComponent1 = ({
  filterText,
  onFilter,
  onClear,
  handlePrint,
  downloadCSV,
  downloadPDF,
}) => (
  <>
    <div>
      <button className="agent-filter-btn" onClick={handlePrint}>
        Print
      </button>
      <button className="agent-filter-btn" onClick={downloadCSV}>
        Download CSV
      </button>
      <button className="agent-filter-btn me-1" onClick={downloadPDF}>
        Download PDF
      </button>
    </div>
    <input
      id="search"
      type="text"
      placeholder="Search..."
      value={filterText}
      onChange={onFilter}
    />
    <button type="button" className="agent-filter-btn" onClick={onClear}>
      Clear
    </button>
  </>
);

export default IncomeStatement;
