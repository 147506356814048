import {
  faHome,
  faKaaba,
  faMinus,
  faMosque,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import Autocomplete from "react-google-autocomplete";
import { Button, DateRangePicker, Modal, SelectPicker } from "rsuite";
import { countryListLocal } from "../Data/CountryList";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const { beforeToday } = DateRangePicker;
function HomeSearch() {
  const data = [
    "City View",
    "Haram View",
    "Kabbah View",
    "Partial Haram View",
    "Patio View",
    "Towers View",
    "Kabbah Partial View",
  ].map((item) => ({ label: item, value: item }));
  const UserDetail = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const [places, setPlace] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [countryList, setCountryList] = useState([]);
  const [adultModal, setAdultModal] = useState(false);
  const [selectedCity, setSelectedCity] = useState("makkah");
  const [loading, setLoading] = useState(false);
  const [hotelView, setHotelView] = useState(null);
  const [childAgesError, setChildAgesError] = useState({});
  const [dateRangePicker, setDateRangePicker] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState("2");
  const [selectedNationality, setSelectedNationality] = useState("");
  const [personData, setPersonData] = useState({
    adult: 2,
    children: 0,
    room: 1,
  });
  const [placeDetail, setPlaceDetail] = useState({
    city: "",
    country: "",
    countrycode: "",
    lat: 0,
    long: 0,
    checkindate: "",
    Checkoutdate: "",
  });
  const [rooms, setRooms] = useState([
    {
      adults: 2,
      children: 0,
      childrenAges: [],
    },
  ]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [dateRange, setDateRange] = useState({
    checkindate: null,
    Checkoutdate: null,
  });

  useEffect(() => {
    getUserLocation();
    const countryOptions = countryListLocal.item.map((country) => ({
      value: country.name.common,
      label: country.name.common,
      flag: country.flags.png,
      phoneCode: country.idd.root + country.idd.suffixes[0],
    }));
    setCountryList(countryOptions);
  }, []);

  function getUserLocation() {
    // Fetch user's location data
    fetch(
      "https://api.geoapify.com/v1/ipinfo?&apiKey=0708571ae58c4688824a7d01ca397041"
    )
      .then((response) => response.json())
      .then((data) => {
        setSelectedNationality(data.country.name);
        localStorage.setItem("UserCountry", data.country.name);
      })
      .catch((error) => {
        console.error("Error fetching user location:", error);
      });
  }

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false); // Update the initialRender state to false.
      return; // Exit the effect early to avoid running the code below.
    }

    if (places.length !== 0) {
      // debugger
      if (
        places.address_components.filter(
          (f) =>
            JSON.stringify(f.types) ===
            JSON.stringify(["locality", "political"])
        )[0]
      ) {
        const city1 = places.address_components.filter(
          (f) =>
            JSON.stringify(f.types) ===
            JSON.stringify(["locality", "political"])
        )[0]?.short_name;
        // console.log('city1=' + city1)
        setPlaceDetail((prevPlaceDetail) => ({
          ...prevPlaceDetail,
          city: city1,
        }));
      } else {
        const city1 = places.address_components.filter(
          (f) =>
            JSON.stringify(f.types) ===
            JSON.stringify(["administrative_area_level_2", "political"])
        )[0]?.short_name;
        // console.log('city1=' + city1)
        setPlaceDetail((prevPlaceDetail) => ({
          ...prevPlaceDetail,
          city: city1,
        }));
      }

      if (
        places.address_components.filter(
          (f) =>
            JSON.stringify(f.types) ===
            JSON.stringify(["locality", "political"])
        )[0]
      ) {
        const displayCity1 = places.address_components.filter(
          (f) =>
            JSON.stringify(f.types) ===
            JSON.stringify(["locality", "political"])
        )[0]?.long_name;
        // console.log('displayCity1=' + displayCity1)
      } else {
        const displayCity1 = places.address_components.filter(
          (f) =>
            JSON.stringify(f.types) ===
            JSON.stringify(["administrative_area_level_2", "political"])
        )[0]?.long_name;
        // console.log('displayCity1=' + displayCity1)
      }

      if (
        places.address_components.filter(
          (f) =>
            JSON.stringify(f.types) === JSON.stringify(["country", "political"])
        )[0]
      ) {
        const country_code = places.address_components.filter(
          (f) =>
            JSON.stringify(f.types) === JSON.stringify(["country", "political"])
        )[0]?.short_name;
        // console.log('country_code=' + country_code)
        setPlaceDetail((prevPlaceDetail) => ({
          ...prevPlaceDetail,
          countrycode: country_code,
        }));
      }
      if (
        places.address_components.filter(
          (f) =>
            JSON.stringify(f.types) === JSON.stringify(["country", "political"])
        )[0]
      ) {
        const country = places.address_components.filter(
          (f) =>
            JSON.stringify(f.types) === JSON.stringify(["country", "political"])
        )[0]?.long_name;
        setPlaceDetail((prevPlaceDetail) => ({
          ...prevPlaceDetail,
          country: country,
        }));
      }
      var address = places.formatted_address;
      var latitude = places.geometry.location.lat();
      setPlaceDetail((prevPlaceDetail) => ({
        ...prevPlaceDetail,
        lat: latitude,
      }));
      var longitude = places.geometry.location.lng();
      setPlaceDetail((prevPlaceDetail) => ({
        ...prevPlaceDetail,
        long: longitude,
      }));
      const latlng = new window.google.maps.LatLng(latitude, longitude);
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ latLng: latlng }, function (results, status) {
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            const addressComponents = results[0].address_components;
            const lastAddressComponent =
              addressComponents[addressComponents.length - 1];
            const country = lastAddressComponent.short_name;
            const state =
              addressComponents[addressComponents.length - 3].long_name;
            const city =
              addressComponents[addressComponents.length - 4].long_name;
          }
        }
      });
    }
  }, [places, initialRender]);

  useEffect(() => {
    const adults = rooms.reduce((acc, item) => acc + item.adults, 0);
    const children = rooms.reduce((acc, item) => acc + item.children, 0);
    const roomss = rooms.length;
    setPersonData((prevPersonData) => ({
      ...prevPersonData,
      adult: adults,
      children: children,
      room: roomss,
    }));
  }, [rooms]);

  const handleDateRangeChange = (value) => {
    setDateRangePicker(value);
    if (value === null) {
      setPlaceDetail((prevPlaceDetail) => ({
        ...prevPlaceDetail,
        checkindate: "",
        Checkoutdate: "",
      }));
      setDateRange(null);
    } else {
      setPlaceDetail((prevPlaceDetail) => ({
        ...prevPlaceDetail,
        checkindate: moment(value[0]).format("YYYY-MM-DD"),
        Checkoutdate: moment(value[1]).format("YYYY-MM-DD"),
      }));

      setDateRange(value);
    }
  };

  const toggleAdultModal = () => {
    let ageStatus = false;
    let messages = {}; // Store error messages for each room and child

    for (let i = 0; i < rooms.length; i++) {
      const { children, childrenAges } = rooms[i];

      if (children !== childrenAges.length) {
        for (let j = 0; j < children; j++) {
          if (!childrenAges[j]) {
            // Check if age is missing for a specific child
            messages[
              `room-${i}-child-${j}`
            ] = `Please select child age.`;
            ageStatus = true;
          }
        }
      }
    }

    if (ageStatus) {
      setChildAgesError(messages); // Store messages for each specific child missing an age
      return;
    }

    setChildAgesError({});
    setAdultModal(!adultModal);
  };

  const handleAdultIncrement = (roomIndex) => {
    // Increment the number of adults for a specific room
    const updatedRooms = [...rooms];
    if (updatedRooms[roomIndex].adults < 6) {
      updatedRooms[roomIndex].adults += 1;
      setRooms(updatedRooms);
    }
  };

  const handleAdultDecrement = (roomIndex) => {
    // Decrement the number of adults for a specific room
    const updatedRooms = [...rooms];
    if (updatedRooms[roomIndex].adults > 1) {
      updatedRooms[roomIndex].adults -= 1;
      setRooms(updatedRooms);
    }
  };

  const handleChildrenIncrement = (roomIndex) => {
    // Increment the number of children for a specific room, if less than 4
    const updatedRooms = [...rooms];
    if (updatedRooms[roomIndex].children < 4) {
      updatedRooms[roomIndex].children += 1;
      setRooms(updatedRooms);
    }
  };

  const handleChildrenDecrement = (roomIndex) => {
    // Decrement the number of children for a specific room
    const updatedRooms = [...rooms];
    if (updatedRooms[roomIndex].children > 0) {
      updatedRooms[roomIndex].children -= 1;
      updatedRooms[roomIndex].childrenAges.pop();
      setRooms(updatedRooms);
    }
  };

  const handleChildAgeChange = (roomIndex, childIndex, value) => {
    // Update the age of a specific child for a specific room
    const updatedRooms = [...rooms];
    updatedRooms[roomIndex].childrenAges[childIndex] = value;
    setRooms(updatedRooms);
  };
  const addRoom = () => {
    // Add a new room with default values
    if (rooms.length < 9) {
      setRooms([...rooms, { adults: 2, children: 0, childrenAges: [] }]);
    }
  };

  const removeLastRoom = () => {
    if (rooms.length > 1) {
      // Remove the last room by slicing the array
      setRooms(rooms.slice(0, -1));
    }
  };
  const handleDeleteRoomByIndex = (roomIndex) => {
    const updatedRooms = rooms.filter((room, index) => index !== roomIndex);
    setRooms(updatedRooms);
  };
  const childAgearray = [
    "1 Year",
    "2 Year",
    "3 Year",
    "4 Year",
    "5 Year",
    "6 Year",
    "7 Year",
    "8 Year",
    "9 Year",
    "10 Year",
  ];
  const Searchhotels = async () => {
    if (dateRange.Checkoutdate === null || dateRange.Checkoutdate === "") {
      toast.info("Please Select Hotel Stay.");
      return;
    } else if (moment(placeDetail.checkindate).isSame(moment(), "day")) {
      toast.info("Please Select a Future Check in Date.");
      return;
    }
    setLoading(true);
    if (selectedLocation === "1") {
      localStorage.setItem("SearchLocation", "All");
    } else {
      localStorage.setItem("SearchLocation", "null");
    }
    var destination_name = "";
    var country = "";
    var lat = "";
    var lon = "";
    var pin = "";
    if (selectedLocation === "2") {
      destination_name = "Makkah";
      country = "Saudi Arabia";
      lat = 21.4240968;
      lon = 39.81733639999999;
      pin = "SA";
    } else if (selectedLocation === "3") {
      destination_name = "Madinah";
      country = "Saudi Arabia";
      lat = 24.4672132;
      lon = 39.6024496;
      pin = "SA";
    } else {
      if (selectedCity === "makkah") {
        destination_name = "Makkah";
        country = "Saudi Arabia";
        lat = 21.4240968;
        lon = 39.81733639999999;
        pin = "SA";
      } else {
        destination_name = "Madinah";
        country = "Saudi Arabia";
        lat = 24.4672132;
        lon = 39.6024496;
        pin = "SA";
      }
    }
    const FormData = {
      room_View: hotelView === null ? "" : hotelView,
      b2b_agent_id: UserDetail.id,
      destination_name: destination_name,
      country: country,
      lat: lat,
      long: lon,
      pin: pin,
      cityd: destination_name,
      country_code: pin,
      check_in: placeDetail.checkindate,
      check_out: placeDetail.Checkoutdate,
      slc_nationality: String(selectedNationality),
      ...rooms.reduce(
        (acc, item, index) => {
          acc.adult += item.adults;
          acc.child += item.children;
          acc.room += 1;
          acc.Adults.push(item.adults);
          acc.children.push(item.children);

          // Dynamically create child ages for each room
          acc[`child_ages${index + 1}`] = item.childrenAges.map((age) =>
            age ? parseInt(age, 10) : 2
          );

          acc.rooms_counter.push(acc.room);
          return acc;
        },
        {
          adult: 0,
          child: 0,
          room: 0,
          Adults: [],
          children: [],
          rooms_counter: [],
        }
      ),
    };
    const queryString = new URLSearchParams(FormData).toString();
    navigate(`/hotel_listing?${queryString}`);
  };
  const HandleLocationChange = (value) => {
    setSelectedLocation(value);
  };

  const handleHotelView = (value) => {
    setHotelView(value);
  };
  const handlecitychange = (event) => {
    setSelectedCity(event.target.value);
  };
  const searchoption = [
    { label: "Mecca", value: "2" },
    { label: "Medina", value: "3" },
  ];

  return (
    <>
      <ToastContainer />
      <Modal
        open={adultModal}
        onClose={toggleAdultModal}
        className="centered-modal"
      >
        <Modal.Header>
          <Modal.Title>Select Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
            {rooms.map((room, roomIndex) => (
              <div key={roomIndex}>
                <div
                  className="hotel-detail-border d-flex text-center fw-bold mb-2"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p className="fw-bold m-1">Room {roomIndex + 1}</p>
                  {roomIndex > 0 && ( // Check if roomIndex is greater than 0
                    <button
                      className="delete-room-button m-1"
                      onClick={() => handleDeleteRoomByIndex(roomIndex)}
                    >
                      Delete
                    </button>
                  )}
                </div>
                <div>
                  <div className="select-child">
                    <div class="f4878764f1">
                      <label class="a984a491d9 fw-bold" for="group_adults">
                        Adults
                      </label>
                    </div>
                    <div class="d-flex fff">
                      <button
                        className="adult-modal-btn"
                        name={`adults-${roomIndex}`}
                        onClick={() => handleAdultDecrement(roomIndex)}
                      >
                        <i class="fas fa-minus">
                          <FontAwesomeIcon size="sm" icon={faMinus} />
                        </i>
                      </button>
                      <span className="d723d73d5f fw-bold" id="input">
                        {room.adults}
                      </span>
                      <button
                        className="adult-modal-btn"
                        name={`adults-${roomIndex}`}
                        onClick={() => handleAdultIncrement(roomIndex)}
                        id="increment"
                      >
                        <i class="fas fa-plus">
                          <FontAwesomeIcon size="sm" icon={faPlus} />
                        </i>
                      </button>
                    </div>
                  </div>
                  <div className="select-child">
                    <div class="f4878764f1">
                      <label class="a984a491d9 fw-bold" for="group_adults">
                        Children
                      </label>
                    </div>
                    <div class="d-flex fff">
                      <button
                        className="adult-modal-btn"
                        onClick={() => handleChildrenDecrement(roomIndex)}
                        id="decrement"
                      >
                        <i class="fas fa-minus">
                          <FontAwesomeIcon size="sm" icon={faMinus} />
                        </i>
                      </button>
                      <span className="d723d73d5f fw-bold" id="input">
                        {room.children}
                      </span>
                      <button
                        className="adult-modal-btn"
                        onClick={() => handleChildrenIncrement(roomIndex)}
                        id="increment"
                      >
                        <i class="fas fa-plus">
                          <FontAwesomeIcon size="sm" icon={faPlus} />
                        </i>
                      </button>
                    </div>
                  </div>
                  <div
                    className="select-child"
                    style={{ justifyContent: "left" }}
                  >
                    {room.children > 0 && (
                      <div className="row w-100 ">
                        <p>
                          To find you a place to stay that fits your entire
                          group along with correct prices, we need to know how
                          old your child.
                        </p>
                        {Array.from(
                          { length: room.children },
                          (_, childIndex) => (
                            <div
                              key={childIndex}
                              className="m-1 "
                              style={{ width: "45%", alignItems: "center" }}
                            >
                              <label>Child {childIndex + 1} (Age)</label>
                              <select
                                class="form-select child-age-select"
                                name="child1"
                                value={room.childrenAges[childIndex]}
                                onChange={(e) =>
                                  handleChildAgeChange(
                                    roomIndex,
                                    childIndex,
                                    e.target.value
                                  )
                                }
                                aria-label="Default select example"
                              >
                                <option selected>Age needed</option>
                                {childAgearray.map((item, index) => (
                                  <option key={index} value={index + 1}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                              {childAgesError[
                                `room-${roomIndex}-child-${childIndex}`
                              ] && (
                                <p className="text-danger">
                                  {
                                    childAgesError[
                                      `room-${roomIndex}-child-${childIndex}`
                                    ]
                                  }
                                </p>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end align-items-center">
          <div className="select-child">
            <div class="f4878764f1 pe-4">
              <label class="a984a491d9 fw-bold" for="group_adults">
                Room
              </label>
            </div>
            <div class="d-flex fff">
              <button
                className="adult-modal-btn"
                onClick={removeLastRoom}
                id="decrement"
              >
                <i class="fas fa-minus">
                  <FontAwesomeIcon size="sm" icon={faMinus} />
                </i>
              </button>
              <span className="d723d73d5f fw-bold" id="input">
                {rooms.length}
              </span>
              <button
                className="adult-modal-btn"
                onClick={addRoom}
                id="increment"
              >
                <i class="fas fa-plus">
                  <FontAwesomeIcon size="sm" icon={faPlus} />
                </i>
              </button>
            </div>
          </div>
          <button className="btn ms-2 theme-btn-1" onClick={toggleAdultModal}>
            Apply
          </button>
        </Modal.Footer>
      </Modal>
      <div className="search-container">
        {/* <ul class="nav nav-tabs" role="tablist">
          <li role="bravo_hotel">
            <a
              class={` ${selectedLocation===2?'activ-search':''} `}
              aria-controls="bravo_hotel"
              role="tab"
              onClick={()=>HandleLocationChange(2)}
              data-toggle="tab"
            >
              Mecca
            </a>
          </li>
          <li role="bravo_space">
            <a
              class={` ${selectedLocation===3?'activ-search':''} `}
              aria-controls="bravo_space"
              onClick={()=>HandleLocationChange(3)}
              role="tab"
              data-toggle="tab"
            >
              Medina
            </a>
          </li>
          <li role="bravo_hotel">
            <a
              class={` ${selectedLocation===1?'activ-search':''} `}
              aria-controls="bravo_hotel"
              role="tab"
              onClick={()=>HandleLocationChange(1)}
              data-toggle="tab"
            >
              Others
            </a>
          </li>
        </ul> */}
        <div className="search-content">
          <div className="row">
            {selectedLocation === "1" && (
              <div className="col-md-2 col-sm-6 col-12 my-1">
                <select
                  onChange={handlecitychange}
                  value={selectedCity}
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option value="makkah">Mecca</option>
                  <option value="medina">Medina</option>
                </select>
              </div>
            )}
            <div className="col-md-3 col-sm-6 col-12 my-1">
              {/* <select class="form-select" value={selectedLocation} onChange={HandleLocationChange} >              
                <option value="2">Mecca</option>
                <option value="3">Medina</option>
              </select> */}
              <SelectPicker
                class="form-select"
                data={searchoption}
                searchable={false}
                value={selectedLocation}
                style={{ width: "100%" }}
                onChange={HandleLocationChange}
                renderValue={(value) => {
                  const selectedLocation = searchoption.find(
                    (option) => option.value === value
                  );
                  return selectedLocation ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        className="me-1"
                        icon={
                          selectedLocation.label === "Mecca"
                            ? faKaaba
                            : faMosque
                        }
                      />
                      {selectedLocation.label}
                    </div>
                  ) : (
                    value
                  );
                }}
                renderMenuItem={(label, item) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FontAwesomeIcon
                      className="me-1"
                      icon={label === "Mecca" ? faKaaba : faMosque}
                    />
                    {label} {/* Use label parameter for the country name */}
                  </div>
                )}
              />
            </div>
            <div className="col-md-3 col-sm-6 col-12 my-1">
              <DateRangePicker
                onChange={handleDateRangeChange}
                format="dd-MM-yyyy"
                value={dateRange}
                showOneCalendar={isMobile}
                disabledDate={beforeToday()}
                className="w-100 text-left "
              />
            </div>
            <div className="col-md-3 col-sm-6 col-12 my-1">
              <button
                onClick={toggleAdultModal}
                className="btn text-left  adult-btn  w-100 btn-block "
                style={{ color: "black", background: "none" }}
              >
                {personData.adult} Adults . {personData.children} Children .{" "}
                {personData.room} Room
              </button>
            </div>
            {/* <div className={` ${selectedLocation===1?'col-md-2':'col-md-3'} col-sm-6 col-12 my-1`}>
            <SelectPicker placeholder='Select View' value={hotelView} onChange={handleHotelView} data={data} block />
            </div> */}
            <div
              className={` ${
                selectedLocation === 1 ? "col-md-2" : "col-md-3"
              } col-sm-6 col-12 my-1`}
            >
              <button
                disabled={
                  dateRangePicker === null || selectedLocation === null
                    ? true
                    : false
                }
                onClick={Searchhotels}
                className="theme-btn-1 w-100 ps-4 pe-4 py-2"
              >
                Find <FontAwesomeIcon className="ms-2 " icon={faSearch} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default HomeSearch;
