import React, { useState, useRef, useEffect } from "react";
import logo from "../../Assets/Images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faEyeSlash,
  faEye,
  faUsers,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faBank,
} from "@fortawesome/free-solid-svg-icons";
import Plus from "@rsuite/icons/legacy/Plus";
import { toast, ToastContainer } from "react-toastify";
import { ApiEndPoint, ApiToken } from "../../Data/Api";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader";
import { Tooltip, Whisper } from "rsuite";
import PasswordStrengthBar from "react-password-strength-bar";
import { SelectPicker } from "rsuite";
import { countryListLocal } from "../../Data/CountryList";
import { faCreditCard, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { Uploader, Button,InputPicker } from "rsuite";
import { Country, City } from "country-state-city";
import OtpInput from "react-otp-input";
function Register() {
  
  const baseurl = ApiEndPoint();
  const token = ApiToken();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [initialCity, setInitialCity] = useState([]);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [activeStep, setActiveStep] = useState(1); // Active step state
  const [paymentDocument, setPaymentDocument] = useState([]);
  const [fileIdProf, setFileIdProf] = useState(null);
  const [filesUploadDocument, setFilesUploadDocument] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [otp, setOtp] = useState("");
  const currentUpload = useRef(null);
  const [uploading, setUploading] = useState(false);
  const [personalInfoErrors, setPersonalInfoErrors] = useState({});
  const [errors, setErrors] = useState({});
  const [requirementsStatus, setRequirementsStatus] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    specialChar: false
  });
  const [formData, setFormData] = useState({
    personalInfo: {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      country: "",
      city: "",
      phnono: "",
      confirmPassword: "",
    },
    companyInfo: {
      companyName: "",
      address: "",
      contactNo: "",
      website: "",
    },
    billingInfo: {
      accountName: "",
      accountNumber: "",
      IBAN: "",
      accountCredit: "",
    },
  });

  useEffect(() => {
    // const countryOptions = countryListLocal.item.map((country) => ({
    //   value: country.cca2,
    //   label: country.name.common,
    //   flag: country.flags.png,
    //   phoneCode: country.idd.root + country.idd.suffixes[0],
    // }));
    const countryOptions = countryListLocal.item
    .filter((country) => 
      country.name.common !== "Mayotte" && 
      country.name.common !== "United States Virgin Islands" && 
      country.name.common !== "Niue"
    ) // Exclude specified countries
    .map((country) => ({
      value: country.cca2,
      label: country.name.common,
      flag: country.flags.png,
      phoneCode:country.cca2==='US'? country.idd.root:country.idd.root + (country.idd.suffixes[0] || ""), // Handle case where suffixes may not exist
    }));
    setCountryList(countryOptions);
  }, []);
  const handleChange = (e, section) => {
    const { name, value } = e.target;
    if(section==='personalInfo' && name==='password'){
      setRequirementsStatus({
        length: value.length >= 11,
        lowercase: /[a-z]/.test(value),
        uppercase: /[A-Z]/.test(value),
        number: /\d/.test(value),
        specialChar: /[#.\-?!@$%^&*]/.test(value)
      });
    }
    setFormData({
      ...formData,
      [section]: {
        ...formData[section],
        [name]: value,
      },
    });
  };
  const handleselectCountry = (value) => {
    if (value !== null) {
      // GetCities(value);
      var allcity = City.getCitiesOfCountry(value);
      const uniqueCities = allcity.filter((city, index, self) =>
        index === self.findIndex((c) => c.name === city.name)
      );
      const cityOptions = uniqueCities.map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setInitialCity(cityOptions.slice(0, 130));
      setCityList(cityOptions);
    }
    const cont = countryList.find((item) => item.value === value);
    setFormData({
      ...formData,
      ["personalInfo"]: {
        ...formData["personalInfo"],
        country: cont?.label,
        city:'',
        phnono: cont?.phoneCode
      },
      ["companyInfo"]: {
        ...formData["companyInfo"],
        contactNo: cont?.phoneCode
      },
    });
    
  };

  const GetCities = async (country) => {
    var data = {
      country_Code: country,
    };
    try {
      const response = await axios.post(
        baseurl + "/api/get_Cities_Using_Code",
        data
      );
      const cityOptions = response.data.country.map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setInitialCity(cityOptions.slice(0, 130));
      setCityList(cityOptions);
    } catch (error) {
      console.log(error);
    }
  };
  const handleselectCity = (value) => {
    setFormData({
      ...formData,
      ["personalInfo"]: {
        ...formData["personalInfo"],
        city: value,
      },
    });
  };
  const handleNext = () => {
    if (activeStep === 1) {
      if (handlepersonalinfo()) {
        return;
      } else {
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 2) {
      setActiveStep(activeStep + 1);
    }
  };
  const handleFileChange = (fileList) => {
    const bytesInMB = 1048576; // 1 MB in bytes
    let hasLargeFile = false;
    fileList.forEach((file) => {
      const fileSizeInBytes = file.blobFile.size;
      const fileSizeInMB = fileSizeInBytes / bytesInMB;

      // If any file exceeds the size limit, show an error and stop processing
      if (fileSizeInMB > 2) {
        toast.error(`File "${file.name}" exceeds the maximum limit of 2 MB.`);
        hasLargeFile = true; // Mark that there is a large file
        return; // Break out of the loop
      }
    });
    if (hasLargeFile) {
      return;
    }
    setFilesUploadDocument(fileList);
  };
  const handleUpload = (file) => {
    const bytesInMB = 1048576;
    const fileSizeInBytes = file.blobFile.size;
    const fileSizeInMB = fileSizeInBytes / bytesInMB;
    if (fileSizeInMB > 2) {
      toast.error("File size exceeds the maximum limit of 2 MB.");
      return;
    }
    setUploading(true);
    previewFile(file.blobFile, (value) => {
      setFileIdProf(value);
      setUploading(false);
    });
  };
  const removeImage = () => {
    setFileIdProf(null); // Clears the previewed image
    setUploading(false); // Resets uploading state
  };
  const previewFile = (file, callback) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  };
  //Form Validation
  const handlepersonalinfo = () => {
    let newErrors = {};
    if (!formData.personalInfo.email) {
      newErrors.email = "Please Enter Email";
    } else if (!ValidEmail(formData.personalInfo.email)) {
      newErrors.email = "Please Enter Valid Email.";
    }

    if (!formData.personalInfo.firstName) {
      newErrors.firstName = "Please Enter First Name.";
    } else if (!/^[a-zA-Z\s]*$/.test(formData.personalInfo.firstName)) {
      newErrors.firstName = "First Name cannot contain special characters.";
    } else if (formData.personalInfo.firstName.length > 20) {
      newErrors.firstName = "First Name cannot be longer than 20 characters.";
    }

    if (!formData.personalInfo.lastName) {
      newErrors.lastName = "Please Enter Last Name.";
    } else if (!/^[a-zA-Z\s]*$/.test(formData.personalInfo.lastName)) {
      newErrors.lastName = "Last Name cannot contain special characters.";
    } else if (formData.personalInfo.lastName.length > 20) {
      newErrors.lastName = "Last Name cannot be longer than 20 characters.";
    }

    if (!formData.personalInfo.country) {
      newErrors.country = "Please Select Country.";
    }

    if (!formData.personalInfo.city) {
      newErrors.city = "Please Select City.";
    }

    if (!formData.personalInfo.phnono) {
      newErrors.phnono = "Please Enter Phone Number.";
    } else if (!/^\+?\d{8,15}$/.test(formData.personalInfo.phnono)) {
      newErrors.phnono = "Contact Number must be between 8 and 15 digits and contain only numbers.";
    }
    const { length, lowercase, uppercase, number, specialChar } = requirementsStatus;
    const isPasswordValid = length && lowercase && uppercase && number && specialChar;
    if (!isPasswordValid) {
      newErrors.password = "Password does not meet all requirements.";
    } 
    if (!formData.personalInfo.confirmPassword) {
      newErrors.confirmPassword = "Please Enter Confirm Password.";
    } else if (formData.personalInfo.confirmPassword !== formData.personalInfo.password) {
      newErrors.confirmPassword = "Password and confirm password do not match.";
    }
    setPersonalInfoErrors(newErrors);
    if(Object.keys(newErrors).length === 0){
      return false;
    }else{
      return true;
    }
  };

  function ValidEmail(email) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  }
  const handlecompanyinfo = () => {
    
    let newErrors = {};

    if (!formData.companyInfo.companyName) {
      newErrors.companyName = "Please Enter Company Name";
    } else if (
      formData.companyInfo.companyName.length < 3 ||
      formData.companyInfo.companyName.length > 50
    ) {
      newErrors.companyName = "Company Name must be between 10 and 50 characters.";
    }

    if (!formData.companyInfo.address) {
      newErrors.address = "Please Enter Address";
    } else if (
      formData.companyInfo.address.length < 10 ||
      formData.companyInfo.address.length > 50
    ) {
      newErrors.address = "Address must be between 10 and 50 characters.";
    }

    if (!formData.companyInfo.contactNo) {
      newErrors.contactNo = "Please Enter Contact Number.";
    } else if (!/^\+?\d{8,15}$/.test(formData.companyInfo.contactNo)) {
      newErrors.contactNo = "Contact Number must be between 8 and 15 digits.";
    }

    if (!formData.companyInfo.website) {
      newErrors.website = "Please Enter Website URL.";
    } else if (
      formData.companyInfo.website.length < 10 ||
      formData.companyInfo.website.length > 50
    ) {
      newErrors.website = "Website URL must be between 10 and 50 characters.";
    }
    if (!isValidURL(formData.companyInfo.website)) {
      newErrors.website = "Please enter a valid website URL, including 'http://' or 'https://'.";
    }
    if (!fileIdProf) {
      newErrors.fileIdProf = "Please Upload Id Proof.";
    }

    if (filesUploadDocument.length === 0) {
      newErrors.filesUploadDocument = "Please Upload Business Document.";
    }

    setErrors(newErrors);
    if(Object.keys(newErrors).length === 0){
      return false;
    }else{
      return true;
    }
  };
  function isValidURL(url) {
    const pattern = /^(https?:\/\/)?([\w\d-]+\.)+[\w-]{2,}(\/[\w\d-_.~:?#[\]@!$&'()*+,;=]*)*\/?$/;
    return pattern.test(url);
  }
  const handleSubmit = async () => {
    let newErrors = {};
    if (otp==='') {
      newErrors.empty = "Please enter otp.";
      setErrors(newErrors);
      return;
    }
    if (otp.length < 4) {
      newErrors.wrong = "Please enter full otp.";
      setErrors(newErrors);
      return;
    }
    setIsLoading(true);
    var verification = await VerifyOtp();
    if(!verification){
      setIsLoading(false);
      return;
    }
    const submitData = new FormData();
    // Append the data from the original object to the FormData
    submitData.append("token", token);
    submitData.append("email", formData.personalInfo.email);
    submitData.append("password", formData.personalInfo.password);
    submitData.append("title", ""); // Title is empty in the original data
    submitData.append("first_name", formData.personalInfo.firstName);
    submitData.append("last_name", formData.personalInfo.lastName);
    submitData.append("company_name", formData.companyInfo.companyName);
    submitData.append("company_address", formData.companyInfo.address);
    submitData.append("company_Website", formData.companyInfo.website);
    submitData.append("company_Phone_no", formData.companyInfo.contactNo);
    submitData.append("country", formData.personalInfo.country); // Country is empty in the original data
    submitData.append("city", formData.personalInfo.city); // City is empty in the original data
    submitData.append("phone_no", formData.personalInfo.phnono);
    submitData.append("zip_code", ""); // Zip code is empty in the original data
    submitData.append("otp_code", ""); // OTP code is empty in the original data
    submitData.append("personal_Details", "Active");
    submitData.append("comapany_Details", "Active");
    // submitData.append("billing_Details", "Active");
    submitData.append("id_Card", fileIdProf);
    filesUploadDocument.forEach((item, index) => {
      submitData.append(
        `business_Document[${index}]`,
        item.blobFile,
        item.name
      );
    });
    // submitData.append("account_Name", formData.billingInfo.accountName);
    // submitData.append("account_Number", formData.billingInfo.accountNumber);
    // submitData.append("IBAN_Number", formData.billingInfo.IBAN);
    // submitData.append("account_Credit", formData.billingInfo.accountCredit);
    // paymentDocument.forEach((item, index) => {
    //   submitData.append(`account_Document[${index}]`, item.blobFile, item.name);
    // });
    try {
      const response = await axios.post(
        baseurl + "/api/register/customer/submit",
        submitData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setIsLoading(false);
      if (response.data.status === "success") {
        navigate("/submit");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };
  const VerifyOtp = async () => {
    const data = {
      token: token,
      email: formData.personalInfo.email,
      otp:otp,
    };
    try {
      const response = await axios.post(baseurl + "/api/register_Verify_Otp", data);
      if (response.data.status === "success") {
        return true;
      } else {
        toast.error(response.data.message);
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  const SendOtp = async () => {
    if (handlecompanyinfo()) {
      return;
    }
    setOtp('');
    setIsLoading(true);
    const data = {
      token: token,
      email: formData.personalInfo.email,
      first_name:formData.personalInfo.firstName,
      last_name:formData.personalInfo.lastName,
    };
    try {
      const response = await axios.post(baseurl + "/api/register_Verify_Mail", data);
      setIsLoading(false);
      if (response.data.status === "success") {
        toast.success("We've sent a OTP to your email. Please check your inbox and enter the OTP to proceed.")
        setActiveStep(activeStep + 1);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  // Previous step function
  const handlePrevious = () => {
    if (activeStep > 1) {
      setActiveStep(activeStep - 1);
    }
  };
  const handleChangeotp = (otpValue) => {
    setOtp(otpValue);
  };
  const handleCreateCity = (value) => {
    const newCity = { label: value, value: value.toLowerCase().replace(/\s+/g, '-') };
    setCityList((prev) => [...prev, newCity]); // Add new city to options
   
  };
  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <div className="login">
        <div className="container ">
          <div>
            <NavLink to="/login">
              <img src={logo} className="login-logo mt-3 " />
            </NavLink>
          </div>
          <div className="w-100 mt-4">
            <div className="register-form">
              <div className="register-steps-list">
              {activeStep === 1 && (
                <div className="register-step-item ">
                  <div
                    className={`register-step-icon ${
                      activeStep === 1 ? "active-step" : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faUsers} />
                  </div>
                  <div className="register-step-text">
                    <div className="step-heading">Personal</div>
                    <div className="step-value">Enter Information</div>
                  </div>
                </div>
              )}
                 {activeStep === 2 && (
                <div className="register-step-item ">
                  <div
                    className={`register-step-icon ${
                      activeStep === 2 ? "active-step" : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faBank} />
                  </div>
                  <div className="register-step-text">
                    <div className="step-heading">Company Details</div>
                    <div className="step-value">Enter Information</div>
                  </div>
                </div>
                 )}
                 {activeStep === 3 && (
                <div className="register-step-item ">
                  <div
                    className={`register-step-icon ${
                      activeStep === 3 ? "active-step" : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                  <div className="register-step-text">
                    <div className="step-heading">Verify Email</div>
                    <div className="step-value">Enter OTP</div>
                  </div>
                </div>
                 )}
              </div>
              {activeStep === 1 && (
                <div className="Personal-info-form">
                  <h4 className="mt-3">Personal Information</h4>
                  <p className="mt-2">Enter Your Detail</p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mt-3">
                        <label>Email</label>
                        <input
                          className="form-control mt-2"
                          type="email"
                          name="email"
                          autocomplete="off"
                          id='registeremail'
                          value={formData.personalInfo.email}
                          onChange={(e) => handleChange(e, "personalInfo")}
                          placeholder="example@gmail.com"
                        />
                         {personalInfoErrors.email && <span className="text-danger">{personalInfoErrors.email}</span>}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mt-3">
                        <label>First Name</label>
                        <input
                          className="form-control mt-2"
                          type="text"
                          name="firstName"
                          value={formData.personalInfo.firstName}
                          onChange={(e) => handleChange(e, "personalInfo")}
                          placeholder="Enter your first name"
                        />
                         {personalInfoErrors.firstName && <span className="text-danger">{personalInfoErrors.firstName}</span>}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mt-3">
                        <label>Last Name</label>
                        <input
                          className="form-control mt-2"
                          type="text"
                          name="lastName"
                          value={formData.personalInfo.lastName}
                          onChange={(e) => handleChange(e, "personalInfo")}
                          placeholder="Enter your last name"
                        />
                         {personalInfoErrors.lastName && <span className="text-danger">{personalInfoErrors.lastName}</span>}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className=" form-group mt-3">
                        <label>Select Country</label>
                        <div className="mt-2">
                          <SelectPicker
                            data={countryList}
                            labelKey="label"
                            valueKey="value"
                            value={formData.personalInfo.country}
                            onChange={handleselectCountry}
                            searchable={true} // Optional: enables searching
                            placeholder="Select a country..."
                            style={{ width: "100%" }} // Optional: sets width of the select picker
                            renderValue={(value) => {
                              const selectedCountry = countryList.find(
                                (option) => option.value === value
                              );
                              return selectedCountry ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={selectedCountry.flag}
                                    alt=""
                                    style={{
                                      width: 20,
                                      height: 15,
                                      marginRight: 8,
                                    }}
                                  />
                                  {selectedCountry.label}
                                </div>
                              ) : (
                                value
                              );
                            }}
                            renderMenuItem={(label, item) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={item.flag}
                                  alt={item.label}
                                  style={{
                                    width: 20,
                                    height: 15,
                                    marginRight: 8,
                                  }}
                                />
                                {label}{" "}
                                {/* Use label parameter for the country name */}
                              </div>
                            )}
                          />
                           {personalInfoErrors.country && <span className="text-danger">{personalInfoErrors.country}</span>}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className=" form-group mt-3">
                        <label>Select City</label>
                        <div className="mt-2">
                          <InputPicker
                            data={cityList}
                            labelKey="label"
                            valueKey="value"
                            creatable
                            onCreate={handleCreateCity}
                            value={formData.personalInfo.city}
                            onChange={handleselectCity}
                            searchable={true}
                            virtualized // Enables virtualization for better performance
                            searchBy={(keyword, label) =>
                              label
                                .toLowerCase()
                                .includes(keyword.toLowerCase())
                            } // Custom search function for efficiency
                            placeholder="Select a city..."
                            // menuMaxHeight={320} // Set max height to avoid displaying too many items
                            style={{ width: "100%" }}
                          />
                           {personalInfoErrors.city && <span className="text-danger">{personalInfoErrors.city}</span>}<br/>
                          <small>If desired city is not available then please enter and create your city name.</small>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mt-3">
                        <label>Phone Number</label>
                        <input
                          className="form-control mt-2"
                          type="text"
                          name="phnono"
                          onKeyPress={(event) => {
                            if (!/[0-9+]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          value={formData.personalInfo.phnono}
                          onChange={(e) => handleChange(e, "personalInfo")}
                          placeholder="Enter phone number"
                        />
                          {personalInfoErrors.phnono && <span className="text-danger">{personalInfoErrors.phnono}</span>}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group  mt-3">
                        <label>
                          Password
                        </label>
                        <div className="input-group input-group-merge ">
                          <input
                            className="form-control mt-2"
                            type={showNewPassword ? "text" : "password"}
                            name="password"
                            autocomplete="new-password"
                            value={formData.personalInfo.password}
                            onChange={(e) => handleChange(e, "personalInfo")}
                            placeholder="*********"
                          />
                          <span
                            className="input-group-text"
                            onClick={toggleNewPasswordVisibility}
                            style={{ cursor: "pointer", marginTop: "auto" }}
                          >
                            <FontAwesomeIcon
                              icon={showNewPassword ? faEyeSlash : faEye}
                            />
                          </span>
                        </div>
                        <div id="requirements" className="password-requirements">
                            <p className={`requirement ${requirementsStatus.length ? "text-success" : "text-danger"}`} id="length">
                              Min. 11 characters
                            </p>
                            <p className={`requirement ${requirementsStatus.lowercase ? "text-success" : "text-danger"}`} id="lowercase">
                              Include lowercase letter
                            </p>
                            <p className={`requirement ${requirementsStatus.uppercase ? "text-success" : "text-danger"}`} id="uppercase">
                              Include uppercase letter
                            </p>
                            <p className={`requirement ${requirementsStatus.number ? "text-success" : "text-danger"}`} id="number">
                              Include number
                            </p>
                            <p className={`requirement ${requirementsStatus.specialChar ? "text-success" : "text-danger"}`} id="characters">
                              Include a special character: #.-?!@$%^&*
                            </p>
                          </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mt-3">
                        <label>Confirm Password</label>
                        <div className="input-group input-group-merge ">
                          <input
                            className="form-control mt-2"
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirmPassword"
                            autocomplete="new-password"
                            value={formData.personalInfo.confirmPassword}
                            onChange={(e) => handleChange(e, "personalInfo")}
                            placeholder="*********"
                          />
                          <span
                            className="input-group-text"
                            onClick={toggleConfirmPasswordVisibility}
                            style={{ cursor: "pointer", marginTop: "auto" }}
                          >
                            <FontAwesomeIcon
                              icon={showConfirmPassword ? faEyeSlash : faEye}
                            />
                          </span>
                        </div>
                        {personalInfoErrors.confirmPassword  && <span className="text-danger">{personalInfoErrors.confirmPassword }</span>}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeStep === 2 && (
                <div className="company-info-form">
                  <h4 className="mt-3">Company Information</h4>
                  <p className="mt-2">Enter Your Detail</p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mt-3">
                        <label>Company Name</label>
                        <input
                          className="form-control mt-2"
                          type="text"
                          name="companyName"
                          value={formData.companyInfo.companyName}
                          onChange={(e) => handleChange(e, "companyInfo")}
                          placeholder="Enter Company Name"
                        />
                        {errors.companyName && <span className="text-danger">{errors.companyName}</span>}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mt-3">
                        <label>Address</label>
                        <input
                          className="form-control mt-2"
                          type="text"
                          name="address"
                          value={formData.companyInfo.address}
                          onChange={(e) => handleChange(e, "companyInfo")}
                          placeholder="123 Main St, City, State, Zip"
                        />
                         {errors.address && <span className="text-danger">{errors.address}</span>}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mt-3">
                        <label>Contact No</label>
                        <input
                          className="form-control mt-2"
                          type="text"
                          name="contactNo"
                          value={formData.companyInfo.contactNo}
                          onChange={(e) => handleChange(e, "companyInfo")}
                          placeholder="+123 456 7899"
                        />
                          {errors.contactNo && <span className="text-danger">{errors.contactNo}</span>}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mt-3">
                        <label>Website</label>
                        <input
                          className="form-control mt-2"
                          type="text"
                          name="website"
                          value={formData.companyInfo.website}
                          onChange={(e) => handleChange(e, "companyInfo")}
                          placeholder="www.example.com"
                        />
                         {errors.website && <span className="text-danger">{errors.website}</span>}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mt-3">
                        <label>Id Proof</label>
                        <div>
                          <Uploader
                            fileList={[]}
                            onChange={(files) => {
                              setFileList(files); // Update the file list with the new file
                            }}
                            listType="picture"
                            accept=".png, .jpg, .jpeg"
                            action="//jsonplaceholder.typicode.com/posts/"
                            fileListVisible={false}
                            onUpload={handleUpload}
                          >
                            <button style={{ width: 300, height: 150 }}>
                              {uploading && (
                                <Loader backdrop center size="md" />
                              )}
                              {fileIdProf ? (
                                <img
                                  src={fileIdProf}
                                  alt="Preview"
                                  width="100%"
                                  height="100%"
                                />
                              ) : (
                                <Plus style={{ fontSize: 20 }} />
                              )}
                            </button>
                          </Uploader>
                        </div>
                        {errors.fileIdProf && <span className="text-danger">{errors.fileIdProf}</span>}
                        {fileIdProf && (
                          <div className="text-center">
                            <Button
                              appearance="link"
                              color="red"
                              onClick={removeImage}
                            >
                              Remove
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group d-upload mt-3">
                        <label>Upload Business Document</label>
                        <div>
                          <Uploader
                            className="mt-2"
                            defaultFileList={filesUploadDocument}
                            listType="picture-text"
                            accept=".png, .jpg, .jpeg, .pdf"
                            onChange={handleFileChange}
                            autoUpload={false}
                            action="//jsonplaceholder.typicode.com/posts/"
                          >
                            <Button>Select files...</Button>
                          </Uploader>
                          {errors.filesUploadDocument && <span className="text-danger">{errors.filesUploadDocument}</span>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeStep === 3 && (
                <div className="company-info-form">
                  <h4 className="mt-3">Enter OTP</h4>
                  <p className="mt-2">
                    An OTP has been sent to your email address, Please enter OTP
                    to verify your email.
                  </p>
                  <div className="row justify-content-center">
                    <div class="mb-3 w-50 my-4 otp-field">
                      <OtpInput
                        value={otp}
                        onChange={handleChangeotp}
                        renderInput={(props) => <input {...props} />}
                        numInputs={5} // Number of OTP input fields
                        renderSeparator={<span>-</span>} // Separator between fields
                        isInputNum={true} // Set to true if you want numeric inputs
                        shouldAutoFocus={true} // Autofocus on first input field
                        inputStyle={{
                          width: "2.5rem",
                          height: "2.5rem",
                          margin: "0 0.5rem",
                          fontSize: "1.3rem",
                          borderRadius: "4px",
                          border: "1px solid #ccc",
                        }}
                      />
                    </div>
                    {errors.empty && <span className="text-danger text-center">{errors.empty}</span>}
                  {errors.wrong && <span className="text-danger text-center">{errors.wrong}</span>}
                  </div>
                  

                </div>
              )}
              <div className="d-flex justify-content-between mt-4 align-items-center">
                <div>
                  {activeStep > 1 && (
                    <button
                      className="btn btn-secondary"
                      onClick={handlePrevious}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} /> Previous
                    </button>
                  )}
                </div>
                {activeStep < 2 && (
                  <button
                    className="theme-btn-1 ps-4 pe-4 py-2"
                    onClick={handleNext}
                  >
                    Next{" "}
                    <FontAwesomeIcon className="ms-2" icon={faArrowRight} />
                  </button>
                )}
                {activeStep === 2 && (
                  <button
                    type="submit"
                    onClick={SendOtp}
                    className="theme-btn-1 ps-4 pe-4 py-2"
                  >
                    Send OTP
                  </button>
                )}
                {activeStep === 3 && (
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="theme-btn-1 ps-4 pe-4 py-2"
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 text-center">
                <p class="text-muted fw-bold">
                  Back to{" "}
                  <a class=" ms-1">
                    <NavLink to="/login">
                      <b>Log In</b>
                    </NavLink>
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="login-footer mb-4">
            <p>© 2024 Alsubaee All Rights Reserved</p>
          </div>
        </div>
      </div>
    </>
  );
}
export default Register;
