const initialState = {
    selectedCurrRates: {},
    baseCurrRates: {},
  };
  
  const currencyReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_SELECTED_CURR':
        return {
          ...state,
          selectedCurrRates: action.payload,
        };
      case 'SET_BASE_CURR':
        return {
          ...state,
          baseCurrRates: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default currencyReducer;
  