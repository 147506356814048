import React from "react";
import Layout from "../Components/Layout";
import image6 from "../Assets/Images/06.svg";
import image7 from "../Assets/Images/07.svg";
import image8 from "../Assets/Images/08.svg";
import { Accordion } from "rsuite";
function Faqs() {
  return (
    <Layout>
      <section>
        <div class="container">
          <div class="row mb-5">
            <div class="col-xl-10 mt-4 mx-auto text-center">
              <h2 className="text-dark">
                AlSubaee Holidays- Largest Market Place to Search Hotel Rooms in Makkah
                and Madina
              </h2>
              <div class="hstack mt-3 gap-3 flex-wrap justify-content-center">
                <h6 class="bg-mode shadow rounded-2 fw-normal d-inline-block py-2 px-4">
                  <img src={image6} class="h-20px me-2" alt="" />
                  14K+ Global Customers
                </h6>

                <h6 class="bg-mode shadow rounded-2 fw-normal d-inline-block py-2 px-4">
                  <img src={image7} class="h-20px me-2" alt="" />
                  10K+ Happy Customers
                </h6>

                <h6 class="bg-mode shadow rounded-2 fw-normal d-inline-block py-2 px-4">
                  <img src={image8} class="h-20px me-2" alt="" />
                  1M+ Subscribers
                </h6>
              </div>
            </div>
          </div>
          <Accordion defaultActiveKey={1} bordered>
            <Accordion.Panel header="How do I make a hotel booking?" eventKey={1}>
                <p>You can easily make a booking by entering your destination and travel dates in the search bar. Browse through the available hotels, select your preferred option, and complete the booking by providing your details and payment information.</p>
            </Accordion.Panel>
            <Accordion.Panel header="Can I cancel or modify my reservation?" eventKey={2}>
                <p>Yes, you can cancel or modify your reservation depending on the hotel's cancellation policy. Please check the specific hotel's policy on your booking confirmation for details.</p>
            </Accordion.Panel>
            <Accordion.Panel header="What payment methods are accepted?" eventKey={3}>
              <p>We accept major credit and debit cards, including Visa, MasterCard, and American Express. Some hotels may offer additional payment options, such as PayPal or bank transfers.</p>
            </Accordion.Panel>
            <Accordion.Panel header="How do I know if my booking is confirmed?" eventKey={4}>
              <p>Once your booking is complete, you will receive a confirmation email with all your booking details. If you don't receive it within a few minutes, please check your spam/junk folder or contact our support team.</p>
            </Accordion.Panel>
            <Accordion.Panel header="Are there any fees for canceling a booking?" eventKey={5}>
              <p>Cancellation fees vary based on the hotel and the cancellation policy associated with your reservation. Some bookings offer free cancellation up to a certain date, while others may have non-refundable options.</p>
            </Accordion.Panel>
            <Accordion.Panel header="What should I do if I need special accommodations?" eventKey={6}>
              <p>If you need special accommodations, such as wheelchair access or dietary requirements, please contact our customer support team or the hotel directly to make arrangements before your stay.</p>
            </Accordion.Panel>
            <Accordion.Panel header="Can I book multiple rooms at once?" eventKey={7}>
              <p>Yes, you can book multiple rooms for the same stay. After selecting the number of rooms and guests during the booking process, you can add the rooms to your booking.</p>
            </Accordion.Panel>
            <Accordion.Panel header="Is my payment information secure?" eventKey={8}>
              <p>Yes, we use industry-standard encryption to ensure that your payment information is secure and protected. We are committed to maintaining your privacy and protecting your personal data.</p>
            </Accordion.Panel>
            <Accordion.Panel header="Who do I contact if I have issues with my booking?" eventKey={9}>
              <p>If you have any issues or questions about your booking, please reach out to our customer support team. You can contact us via email, phone, or live chat for assistance.</p>
            </Accordion.Panel>
          </Accordion>
        </div>
      </section>
    </Layout>
  );
}
export default Faqs;
