import React, { useEffect, useState } from "react";
import logo from "../Assets/Images/logo.png";
import user from "../Assets/Images/user.png";
import { NavLink, useNavigate } from "react-router-dom";
import { CurrencyConverter } from "../Data/Api";
import axios from "axios";
import { useDispatch,useSelector } from "react-redux";
import { setSelectedCurr, setBaseCurr ,setLoginUser} from "../reducers/hotelActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faHome } from "@fortawesome/free-solid-svg-icons";
function NavBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedcurr, setSelectedCurrency] = useState("SAR");
  const options = [
    { value: "SAR", label: "SAR", imgSrc: "https://flagcdn.com/w320/sa.png" },
    { value: "USD", label: "USD", imgSrc: "https://flagcdn.com/w320/us.png" },
    { value: "GBP", label: "GBP", imgSrc: "https://flagcdn.com/w320/gb.png" },
    { value: "EUR", label: "EUR", imgSrc: "https://flagcdn.com/w320/eu.png" },
    { value: "PKR", label: "PKR", imgSrc: "https://flagcdn.com/w320/pk.png" },
  ];
  const userdetail =useSelector((state) => state.user.user);
  useEffect(() => {
    BaseCurrRates();
    // localStorage.setItem('DefaultCurrency', 'SAR');
    const storedCurrency = localStorage.getItem("selectedCurrency");
    if (storedCurrency) {
      setSelectedCurrency(storedCurrency); // Default currency exists, show "OK"
    } else {
      localStorage.setItem("selectedCurrency", "SAR");
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    dispatch(setLoginUser(null));
    navigate("/login");
  };
  const handlecurrchange = (event) => {
    setSelectedCurrency(event.target.value);
    SelectedCurrRates(event.target.value);
    localStorage.setItem("selectedCurrency", event.target.value);
  };

  const SelectedCurrRates = (currency) => {
    var token = CurrencyConverter();
    const config = {
      method: "get",
      url:
        "https://v6.exchangerate-api.com/v6/" + token + "/latest/" + currency, // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        response.data.selectedcurrency = currency;
        dispatch(setSelectedCurr(response.data));
      })
      .catch((error) => {
        dispatch(setSelectedCurr(null));
        console.error(error);
      });
  };
  const BaseCurrRates = () => {
    var token = CurrencyConverter();
    const config = {
      method: "get",
      url: "https://v6.exchangerate-api.com/v6/" + token + "/latest/SAR", // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        dispatch(setBaseCurr(response.data));
      })
      .catch((error) => {
        dispatch(setBaseCurr(null));
        console.error(error);
      });
  };
  return (
    <>
      {userdetail?.billing_Details !== "Active" && (
        <div>
        <div
          class="alert alert-warning mb-0 alert-dismissible fade show"
          role="alert"
        >
          <strong>
            Hi {userdetail.first_name} {userdetail.last_name}!
          </strong>{" "}
          Your billing {userdetail?.comapany_Details === "Active" ? '':'and company' } details  are currently empty please provide the necessary
          information.
          <NavLink target="blank" to="/dashboard/edit-profile">
            Click Here
          </NavLink>
          <br/>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
        </div>
      )}
      <nav class=" navbar navbar-expand-lg ">
        <div className="container">
          <NavLink class="navbar-brand" to="/">
            <img src={logo} height="90" width="80" />
          </NavLink>
          <ul class="navbar-nav mobile-menu mr-auto mt-2 mt-lg-0">
              <li style={{ margin: "auto" }} class="nav-item">
                <select
                  class="form-select"
                  value={selectedcurr}
                  onChange={handlecurrchange}
                  aria-label="Default select example"
                >
                  {options.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.value}
                    </option>
                  ))}
                </select>
              </li>
              <li class="nav-item">
                <div class="nav-link d-flex gap-2 nav-user">
                  <span>
                    <img
                      src={user}
                      alt="user-image"
                      width="32"
                      class="rounded-circle"
                    />
                  </span>
                  <div className="dropdown">
                    <span
                      className="d-lg-flex flex-column "
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ cursor: "pointer" }} // Adding cursor to indicate clickable
                    >
                      <p
                        style={{ fontSize: ".936rem" }}
                        className="my-0 fw-bold "
                      >
                        {userdetail.title} {userdetail.first_name}{" "}
                        {userdetail.last_name}  <FontAwesomeIcon icon={faAngleDown}/>
                      </p>
                      {/* <p style={{ fontSize: ".765rem" }} className="my-0 text-white">Developer</p> */}
                    </span>

                    {/* Dropdown menu */}
                    <ul
                      className="dropdown-menu mobile-drop-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <li>
                        <NavLink target="blank" to="/dashboard">
                          <a className="dropdown-item">Dashboard</a>
                        </NavLink>
                      </li>
                      <li>
                        <a className="dropdown-item" onClick={handleLogout}>
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          <div
            class="collapse navbar-collapse d-flex justify-content-end"
            id="navbarTogglerDemo03"
          >
            <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
            <li style={{ margin: "auto" }} class="nav-item">
              <NavLink to='/' className='text-decoration-none text-dark'>
               <p className="h6 me-3 mb-0"><FontAwesomeIcon icon={faHome}/> Home</p>
               </NavLink>
              </li>
              <li style={{ margin: "auto" }} class="nav-item">
              <NavLink to='/about_us' className='text-decoration-none text-dark'>
               <p className="h6 me-3 mb-0"> About us</p>
               </NavLink>
              </li>
              <li style={{ margin: "auto" }} class="nav-item">
              <NavLink to='/contact_us' className='text-decoration-none text-dark'>
               <p className="h6 me-3 mb-0"> Contact Us</p>
               </NavLink>
              </li>
              <li style={{ margin: "auto" }} class="nav-item">
                <select
                  class="form-select"
                  value={selectedcurr}
                  onChange={handlecurrchange}
                  aria-label="Default select example"
                >
                  {options.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.value}
                    </option>
                  ))}
                </select>
              </li>
              <li class="nav-item">
                <div class="nav-link d-flex gap-2 nav-user">
                  <span>
                    <img
                      src={user}
                      alt="user-image"
                      width="32"
                      class="rounded-circle"
                    />
                  </span>
                  <div className="dropdown">
                    <span
                      className="d-lg-flex flex-column "
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ cursor: "pointer" }} // Adding cursor to indicate clickable
                    >
                      <p
                        style={{ fontSize: ".936rem" }}
                        className="my-0 fw-bold "
                      >
                        {userdetail.title} {userdetail.first_name}{" "}
                        {userdetail.last_name} <FontAwesomeIcon icon={faAngleDown}/>
                      </p>
                      {/* <p style={{ fontSize: ".765rem" }} className="my-0 text-white">Developer</p> */}
                    </span>

                    {/* Dropdown menu */}
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <li>
                        <NavLink target="blank" to="/dashboard">
                          <a className="dropdown-item">Dashboard</a>
                        </NavLink>
                      </li>
                      <li>
                        <a className="dropdown-item" onClick={handleLogout}>
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="mobile-menu w-100">
        <ul class=" d-flex container  mr-auto mt-2 mt-lg-0">
            <li style={{ margin: "auto" }} class="nav-item">
              <NavLink to='/' className='text-decoration-none text-dark'>
               <p className="h6 me-3 mb-0"><FontAwesomeIcon icon={faHome}/> Home</p>
               </NavLink>
              </li>
              <li style={{ margin: "auto" }} class="nav-item">
              <NavLink to='/about_us' className='text-decoration-none text-dark'>
               <p className="h6 me-3 mb-0"> About us</p>
               </NavLink>
              </li>
              <li style={{ margin: "auto" }} class="nav-item">
              <NavLink to='/contact_us' className='text-decoration-none text-dark'>
               <p className="h6 me-3 mb-0"> Contact Us</p>
               </NavLink>
              </li>
            </ul>
            </div>
      </nav>
    </>
  );
}
export default NavBar;
