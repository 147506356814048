import React, { useState, useEffect,useRef } from "react";
import Layout from "../Components/Layout";
import img from "../Assets/Images/1.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBowlFood,
  faMapMarkerAlt,
  faHandPointRight,
  faMinus,
  faPlus,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { Carousel } from "rsuite";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import axios from "axios";
import { ApiEndPoint, ApiToken } from "../Data/Api";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../Components/Loader";
import {
  setHotelCheckout,
  setHotelDetail,
  setSearchResults,
  setSearchQuery,
} from "../reducers/hotelActions";
import ModifySearch from "../Components/ModifySearch";
import {
  DateRangePicker,
  Popover,
  Whisper,
  Modal,
  Button,
  Placeholder,
} from "rsuite";
const { beforeToday } = DateRangePicker;
function HotelDetail() {
  const toastId = "Booth-Selected";
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const todayDate = new Date();
  const token = ApiToken();
  const baseurl = ApiEndPoint();
  const { index } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [isWhisperOpen, setIsWhisperOpen] = useState(false);
  const [isCustomSearch, setIsCustomSearch] = useState(false);
  const [isFacilities, setIsFacilities] = useState(10);
  const [isHotelDetail, setIsHotelDetail] = useState(680);
  const BaseCurrRates = useSelector((state) => state.currency.baseCurrRates);
  const SelectedCurrRates = useSelector(
    (state) => state.currency.selectedCurrRates
  );
  const [childAgesError, setChildAgesError] = useState({});
  const [numberOfNights, setNumberOfNights] = useState("");
  const UserDetail = useSelector((state) => state.user.user);
  const HotelDetail = useSelector((state) => state.hotelDetail.hotelDetail);
  const ReduxSearchData = useSelector((state) => state.hotelSearch.searchQuery);
  // console.log(HotelDetail);
  // console.log(ReduxSearchData);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [hotelSelectedRooms, setHotelSelectedRooms] = useState([]);
  const [result, setResult] = useState([]);
  const [priceConversion, setPriceConversion] = useState(false);
  const [currencyRates, setCurrencyRates] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [dateRange, setDateRange] = useState({
    checkindate: null,
    Checkoutdate: null,
  });
  const [personData, setPersonData] = useState({
    adult: 2,
    children: 0,
    room: 1,
  });
  const [rooms, setRooms] = useState([
    {
      adults: 2,
      children: 0,
      childrenAges: [],
    },
  ]);
  const [CancellationArr, setCancellationArr] = useState([]);
  const [loadingCancellation, setLoadingCancellation] = useState(true);
  const [roomName, setRoomName] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleCloseWhisper = () => {
    let ageStatus = false;
    let messages = {}; // Store error messages for each room and child

    for (let i = 0; i < rooms.length; i++) {
      const { children, childrenAges } = rooms[i];

      if (children !== childrenAges.length) {
        for (let j = 0; j < children; j++) {
          if (!childrenAges[j]) {
            // Check if age is missing for a specific child
            messages[
              `room-${i}-child-${j}`
            ] = `Please select child age.`;
            ageStatus = true;
          }
        }
      }
    }

    if (ageStatus) {
      setChildAgesError(messages); // Store messages for each specific child missing an age
      return;
    }

    setChildAgesError({});
    setIsWhisperOpen(false);
    
  };
  useEffect(() => {
    let NewArray = [];
    let occurrences = {};
    for (let i = 0; i < ReduxSearchData?.Adults.length; i++) {
      let adult = ReduxSearchData?.Adults[i];
      let child = ReduxSearchData?.children[i];
      let pairKey = `${adult}-${child}`;

      occurrences[pairKey] = (occurrences[pairKey] || 0) + 1;

      if (occurrences[pairKey] === 1) {
        NewArray.push({
          adult,
          child,
          qty: occurrences[pairKey],
          status: false,
        });
      } else {
        NewArray.find(
          (entry) => entry.adult === adult && entry.child === child
        ).qty = occurrences[pairKey];
      }
    }
    setResult(NewArray);
    const checkInDatec = new Date(ReduxSearchData.check_in);
    const checkOutDatec = new Date(ReduxSearchData.check_out);
    let Checkin = moment(ReduxSearchData.check_in);
    let checkout = moment(ReduxSearchData.check_out);
    let daysBetween = Math.abs(checkout.diff(Checkin, "days"));
    setNumberOfNights(daysBetween);
    setDateRange([checkInDatec, checkOutDatec]);
    const roomsData = ReduxSearchData.rooms_counter.map((_, index) => {
      const childAgesKey = `child_ages${index + 1}`;
      return {
        adults: ReduxSearchData.Adults[index],
        children: ReduxSearchData.children[index],
        childrenAges: ReduxSearchData[childAgesKey], // Assuming this is empty for now, you can modify as needed
      };
    });
    setRooms(roomsData);
  }, [ReduxSearchData]);
  useEffect(() => {
    var currdata = localStorage.getItem("AllHotelCurr");
    if (currdata !== "null" && currdata !== null) {
      setPriceConversion(true);
      setCurrencyRates(currdata);
    }
  }, []);
  useEffect(() => {
    const adults = rooms.reduce((acc, item) => acc + item.adults, 0);
    const children = rooms.reduce((acc, item) => acc + item.children, 0);
    const roomss = rooms.length;
    setPersonData((prevPersonData) => ({
      ...prevPersonData,
      adult: adults,
      children: children,
      room: roomss,
    }));
  }, [rooms]);
  const calculateMarkup = (price) => {
    if (Object.keys(HotelDetail).length !== 0) {
      let markupprice = 0;
      let adminmarkupprice = 0;
      let clientmarkupprice = 0;
      let finalpricemarkup = Number(price);
      if (Number(HotelDetail.admin_markup) !== 0) {
        if (HotelDetail.admin_markup_type === "Percentage") {
          markupprice = (price * Number(HotelDetail.admin_markup)) / 100;
        } else {
          markupprice = Number(HotelDetail.admin_markup);
        }
        adminmarkupprice = markupprice;
        finalpricemarkup += markupprice;
      }
      if (Number(HotelDetail.customer_markup) !== 0) {
        if (HotelDetail.customer_markup_type === "Percentage") {
          markupprice = (price * Number(HotelDetail.customer_markup)) / 100;
        } else {
          markupprice = Number(HotelDetail.customer_markup);
        }
        clientmarkupprice = markupprice;
        finalpricemarkup += markupprice;
      }

      if (Number(finalpricemarkup) % 1 !== 0) {
        return Number(finalpricemarkup).toFixed(2);
      }
      return finalpricemarkup;
    }
  };
  const selectedRoomData = (index, event, provider) => {
    event.preventDefault();
    event.stopPropagation();

    const selectedroom = HotelDetail.rooms_options[index];
    const firstRoomRequestType = selectedRooms.length > 0 ? HotelDetail.rooms_options[selectedRooms[0].index].request_type : null;

    // Check if the request_type matches with the first selected room, if not show an error and return
    if (firstRoomRequestType !== null && selectedroom.request_type !== firstRoomRequestType) {
      if (!toast.isActive(toastId)) {  
      toast.error("Please book the available rooms or only request the room.",{
          toastId,
        });
      }
        return;
    }

    if (provider === "Custome_hotel") {
        if (selectedRooms.some((room) => room.index === index)) {
            const updatedSelectedRooms = selectedRooms.filter(
                (room) => room.index !== index
            );
            const newroom = hotelSelectedRooms.filter(
                (item) => item.index !== index
            );
            setSelectedRooms(updatedSelectedRooms);
            setHotelSelectedRooms(newroom);
        } else {
            const key = { rateKey: selectedroom.booking_req_id, index: index };
            const newSelectedRoom = {
                room_rate_key: JSON.stringify(key),
                rooms_qty: selectedroom.rooms_qty,
                index: index,
            };
            setSelectedRooms([...selectedRooms, newSelectedRoom]);
            setHotelSelectedRooms([...hotelSelectedRooms, key]);
        }
    } else {
        for (let i = 0; i < result.length; i++) {
            if (
                result[i].adult === selectedroom.adults &&
                result[i].child === selectedroom.childs &&
                result[i].qty === selectedroom.rooms_qty
            ) {
                if (result[i].status === false) {
                    const key = { rateKey: selectedroom.booking_req_id, index: index };
                    const newSelectedRoom = {
                        room_rate_key: JSON.stringify(key),
                        rooms_qty: selectedroom.rooms_qty,
                        index: index,
                    };
                    result[i].status = true;
                    setSelectedRooms([...selectedRooms, newSelectedRoom]);
                    setHotelSelectedRooms([...hotelSelectedRooms, key]);
                } else {
                    if (selectedRooms.some((room) => room.index === index)) {
                        const updatedSelectedRooms = selectedRooms.filter(
                            (room) => room.index !== index
                        );
                        const newroom = hotelSelectedRooms.filter(
                            (item) => item.index !== index
                        );
                        setSelectedRooms(updatedSelectedRooms);
                        setHotelSelectedRooms(newroom);
                        result[i].status = false;
                    } else {
                        toast.error("Select the room according to the search criteria.");
                    }
                }
            }
        }
    }
};

  const SelectedRoomQuitity = (event, index) => {
    const qty = event.target.value;
    const updatedRooms = selectedRooms.map((room) => {
      if (room.index === index) {
        return {
          ...room,
          rooms_qty: qty, // update rooms_qty for the specific index
        };
      }
      return room; // keep the other rooms unchanged
    });

    setSelectedRooms(updatedRooms);
  };
  const BookRoom = async () => {
    if (hotelSelectedRooms.length === 0) {
      toast.error("Please Select Room First.");
      return;
    }
    if (HotelDetail.hotel_provider !== "Custome_hotel") {
      if (hotelSelectedRooms.length !== result.length) {
        toast.error("Please Select the room according to the search criteria.");
        return;
      }
    }
    if (HotelDetail.hotel_provider === "Custome_hotel") {
      var customresult = checkcustomhotelvalidation();
      if (customresult == false) {
        toast.error("Please Select the room according to the search criteria.");
        return;
      }
    }
    setIsLoading(true);
    const roomdata = {
      rooms_select_data: JSON.stringify(selectedRooms),
      hotel_index: index,
      hotelbeds_select_room: hotelSelectedRooms,
    };

    const finaldata = {
      token: token,
      b2b_agent_id:UserDetail.id,
      request_data: JSON.stringify(roomdata),
      selected_hotel: JSON.stringify(HotelDetail),
      selected_hotel_details: JSON.stringify({
        check_in: ReduxSearchData?.check_in,
        check_out: ReduxSearchData?.check_out,
        hotel_address: HotelDetail.hotel_address,
        hotel_country: HotelDetail.hotel_country,
        hotel_city: HotelDetail.hotel_city,
        latitude: HotelDetail.longitude,
        longitude: HotelDetail.latitude,
      }),
      req_index: index,
    };

    try {
      const response = await axios.post(
        baseurl + "/api/hotels/checkavailability_Live",
        finaldata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
          },
        }
      );
      if (response.data.status === "error") {
        setIsLoading(false);
        toast.error(response.data.message);
        return;
      }
      if (response.data !== "") {
        if (response.data.hotels_data.rooms_list.length === 0) {
          setIsLoading(false);
          toast.info(
            "Sorry, the selected room is no longer available. Please choose a different room or adjust your search criteria."
          );
          return;
        }
        if (isCustomSearch) {
          dispatch(setSearchQuery(ReduxSearchData));
        }
        dispatch(setHotelCheckout(response.data.hotels_data));
        navigate("/hotel_checkout");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };
  const checkcustomhotelvalidation = () => {
    let nadult = 0;
    let nchild = 0;

    selectedRooms.forEach((item) => {
      const hroom = HotelDetail.rooms_options[item.index];
      nadult += Number(hroom.adults) * Number(item.rooms_qty);
      nchild += Number(hroom.childs) * Number(item.rooms_qty);
    });
    // console.log(nadult);
    // console.log(nchild);
    if (nadult >= ReduxSearchData.adult && nchild >= ReduxSearchData.child) {
      return true;
    } else {
      return false;
    }
  };

  const renderPrice = (price, currency) => {
    var selectedcurrency = localStorage.getItem("selectedCurrency");
    var currencyData = JSON.parse(currencyRates);
    if (currencyData.length === 0 || currency === selectedcurrency) {
      if (Number(price) % 1 !== 0) {
        return Number(price).toFixed(2);
      }
      return Number(price);
    } else {
      var filterroomcurrdata = currencyData.filter(
        (item) => item.base_code === currency
      );

      const gbpprice = filterroomcurrdata[0].conversion_rates[selectedcurrency]; // Use square brackets to access the property
      var baseprice = Number(gbpprice) * Number(price);
      if (Number(baseprice) % 1 !== 0) {
        return Number(baseprice).toFixed(2);
      }
      return baseprice;
    }
  };
  const hamdlemorefacilities = () => {
    setIsFacilities(isFacilities + 10);
  };
  const hamdlemorefacilitiesremove = () => {
    setIsFacilities(10);
  };
  const handleDateRangeChange = (value) => {
    setDateRange(value);
  };
  const handleAdultIncrement = (roomIndex) => {
    // Increment the number of adults for a specific room
    const updatedRooms = [...rooms];
    if (updatedRooms[roomIndex].adults < 6) {
      updatedRooms[roomIndex].adults += 1;
      setRooms(updatedRooms);
    }
  };
  const handleAdultDecrement = (roomIndex) => {
    // Decrement the number of adults for a specific room
    const updatedRooms = [...rooms];
    if (updatedRooms[roomIndex].adults > 1) {
      updatedRooms[roomIndex].adults -= 1;
      updatedRooms[roomIndex].childrenAges.pop();
      setRooms(updatedRooms);
    }
  };
  const handleChildrenIncrement = (roomIndex) => {
    // Increment the number of children for a specific room, if less than 4
    const updatedRooms = [...rooms];
    if (updatedRooms[roomIndex].children < 4) {
      updatedRooms[roomIndex].children += 1;
      setRooms(updatedRooms);
    }
  };
  const handleChildrenDecrement = (roomIndex) => {
    // Decrement the number of children for a specific room
    const updatedRooms = [...rooms];
    if (updatedRooms[roomIndex].children > 0) {
      updatedRooms[roomIndex].children -= 1;
      updatedRooms[roomIndex].childrenAges.pop();
      setRooms(updatedRooms);
    }
  };
  const handleChildAgeChange = (roomIndex, childIndex, value) => {
    // Update the age of a specific child for a specific room
    const updatedRooms = [...rooms];
    updatedRooms[roomIndex].childrenAges[childIndex] = value;
    setRooms(updatedRooms);
  };
  const addRoom = () => {
    // Add a new room with default values
    if (rooms.length < 9) {
      setRooms([...rooms, { adults: 2, children: 0, childrenAges: [] }]);
    }
  };
  const removeLastRoom = () => {
    if (rooms.length > 1) {
      // Remove the last room by slicing the array
      setRooms(rooms.slice(0, -1));
    }
  };
  const handleDeleteRoomByIndex = (roomIndex) => {
    const updatedRooms = rooms.filter((room, index) => index !== roomIndex);
    setRooms(updatedRooms);
  };
  const childAgearray = [
    "1 Year",
    "2 Year",
    "3 Year",
    "4 Year",
    "5 Year",
    "6 Year",
    "7 Year",
    "8 Year",
    "9 Year",
    "10 Year",
  ];
  const speaker = (
    <Popover style={{ width: "21em" }} title="Select Detail">
      {rooms.map((room, roomIndex) => (
        <div key={roomIndex} className="availability_search">
          <div
            className="hotel-detail-border d-flex text-center fw-bold mb-2"
            style={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className="fw-bold m-1">Room {roomIndex + 1}</p>
            {roomIndex > 0 && ( // Check if roomIndex is greater than 0
              <button
                className="delete-room-button m-1"
                onClick={() => handleDeleteRoomByIndex(roomIndex)}
              >
                Delete
              </button>
            )}
          </div>
          <div>
            <div className="select-child">
              <div class="f4878764f1">
                <label class="a984a491d9 fw-bold" for="group_adults">
                  Adults
                </label>
              </div>
              <div class="d-flex fff">
                <button
                  className="adult-modal-btn"
                  name={`adults-${roomIndex}`}
                  onClick={() => handleAdultDecrement(roomIndex)}
                >
                  <i class="fas fa-minus">
                    <FontAwesomeIcon size="sm" icon={faMinus} />
                  </i>
                </button>
                <span className="d723d73d5f fw-bold" id="input">
                  {room.adults}
                </span>
                <button
                  className="adult-modal-btn"
                  name={`adults-${roomIndex}`}
                  onClick={() => handleAdultIncrement(roomIndex)}
                  id="increment"
                >
                  <i class="fas fa-plus">
                    <FontAwesomeIcon size="sm" icon={faPlus} />
                  </i>
                </button>
              </div>
            </div>
            <div className="select-child">
              <div class="f4878764f1">
                <label class="a984a491d9 fw-bold" for="group_adults">
                  Children
                </label>
              </div>
              <div class="d-flex fff">
                <button
                  className="adult-modal-btn"
                  onClick={() => handleChildrenDecrement(roomIndex)}
                  id="decrement"
                >
                  <i class="fas fa-minus">
                    <FontAwesomeIcon size="sm" icon={faMinus} />
                  </i>
                </button>
                <span className="d723d73d5f fw-bold" id="input">
                  {room.children}
                </span>
                <button
                  className="adult-modal-btn"
                  onClick={() => handleChildrenIncrement(roomIndex)}
                  id="increment"
                >
                  <i class="fas fa-plus">
                    <FontAwesomeIcon size="sm" icon={faPlus} />
                  </i>
                </button>
              </div>
            </div>
            <div className="select-child" style={{ justifyContent: "left" }}>
              {room.children > 0 && (
                <div
                  style={{ flexWrap: "wrap" }}
                  className="d-flex justify-content-between"
                >
                  {Array.from({ length: room.children }, (_, childIndex) => (
                    <div key={childIndex} className="m-1 ">
                      <label>Child {childIndex + 1} (Age)</label>
                      <select
                        class="form-select child-age-select"
                        name="child1"
                        value={room.childrenAges[childIndex]}
                        onChange={(e) =>
                          handleChildAgeChange(
                            roomIndex,
                            childIndex,
                            e.target.value
                          )
                        }
                        aria-label="Default select example"
                      >
                        <option selected>Age needed</option>
                        {childAgearray.map((item, index) => (
                          <option key={index} value={index + 1}>
                            {item}
                          </option>
                        ))}
                      </select>
                      {childAgesError[
                                `room-${roomIndex}-child-${childIndex}`
                              ] && (
                                <p className="text-danger">
                                  {
                                    childAgesError[
                                      `room-${roomIndex}-child-${childIndex}`
                                    ]
                                  }
                                </p>
                              )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
      <div>
        <div className="select-child availability_search mt-4">
          <div class="f4878764f1 pe-4">
            <label class="a984a491d9 fw-bold" for="group_adults">
              Room
            </label>
          </div>
          <div class="d-flex fff">
            <button
              className="adult-modal-btn"
              onClick={removeLastRoom}
              id="decrement"
            >
              <i class="fas fa-minus">
                <FontAwesomeIcon size="sm" icon={faMinus} />
              </i>
            </button>
            <span className="d723d73d5f fw-bold" id="input">
              {rooms.length}
            </span>
            <button
              className="adult-modal-btn"
              onClick={addRoom}
              id="increment"
            >
              <i class="fas fa-plus">
                <FontAwesomeIcon size="sm" icon={faPlus} />
              </i>
            </button>
          </div>
          <button onClick={handleCloseWhisper} className="theme-btn-1 ps-3 pe-3 py-1">Apply</button>
        </div>
      </div>
    </Popover>
  );
  const GetCustonHotelsRooms = async () => {
    if (dateRange === null) {
      toast.error("Please Select Checkin and Checkout Date.");
      return;
    }
    const roomsSummary = rooms.reduce(
      (acc, item, index) => {
        acc.adult += item.adults;
        acc.child += item.children;
        acc.room += 1;
        acc.Adults.push(item.adults);
        acc.children.push(item.children);
        acc[`child_ages${index + 1}`] = item.childrenAges.map((age) =>
          age ? parseInt(age, 10) : 2
        );
        acc.rooms_counter.push(acc.room);
        return acc;
      },
      {
        adult: 0,
        child: 0,
        room: 0,
        Adults: [],
        children: [],
        rooms_counter: [],
      }
    );
    ReduxSearchData.check_in = moment(dateRange[0]).format("YYYY-MM-DD");
    ReduxSearchData.check_out = moment(dateRange[1]).format("YYYY-MM-DD");
    ReduxSearchData.adult = roomsSummary.adult;
    ReduxSearchData.child = roomsSummary.child;
    ReduxSearchData.children = roomsSummary.children;
    ReduxSearchData.Adults = roomsSummary.Adults;
    ReduxSearchData.room = roomsSummary.room;
    ReduxSearchData.rooms_counter = roomsSummary.rooms_counter;
    ReduxSearchData.hotel_provider = HotelDetail.hotel_provider;
    ReduxSearchData.token = token;
    ReduxSearchData.hotel_Id = HotelDetail.hotel_code;
    roomsSummary.rooms_counter.forEach((_, index) => {
      ReduxSearchData[`child_ages${index + 1}`] =
        roomsSummary[`child_ages${index + 1}`];
    });
    for (let i = 0; i < rooms.length; i++) {
      const { children, childrenAges } = rooms[i];

      if (children !== childrenAges.length) {
        toast.info(
          `Room ${
            i + 1
          }: You selected ${children} children but provided age(s) for only ${
            childrenAges.length
          }. Please select the ages for all children.`
        );
        return;
      }
    }
    setIsLoading(true);
    try {
      const response = await axios.post(
        baseurl + "/api/custom_Search_Hotels",
        ReduxSearchData
      );
      setIsLoading(false);
      if (response.data.status === "success") {
        setIsCustomSearch(true);
        dispatch(setHotelDetail(response.data.hotel_details));
        // var x = []; // Initialize an array
        //   x[0] = response.data.hotel_details;
        // dispatch(setSearchResults(x));
        setSelectedRooms([]);
        setHotelSelectedRooms([]);
        let NewArray = [];
        let occurrences = {};
        for (let i = 0; i < ReduxSearchData?.Adults.length; i++) {
          let adult = ReduxSearchData?.Adults[i];
          let child = ReduxSearchData?.children[i];
          let pairKey = `${adult}-${child}`;

          occurrences[pairKey] = (occurrences[pairKey] || 0) + 1;

          if (occurrences[pairKey] === 1) {
            NewArray.push({
              adult,
              child,
              qty: occurrences[pairKey],
              status: false,
            });
          } else {
            NewArray.find(
              (entry) => entry.adult === adult && entry.child === child
            ).qty = occurrences[pairKey];
          }
        }
        setResult(NewArray);
        const checkInDatec = new Date(ReduxSearchData.check_in);
        const checkOutDatec = new Date(ReduxSearchData.check_out);
        let Checkin = moment(ReduxSearchData.check_in);
        let checkout = moment(ReduxSearchData.check_out);
        let daysBetween = Math.abs(checkout.diff(Checkin, "days"));
        setNumberOfNights(daysBetween);
        setDateRange([checkInDatec, checkOutDatec]);
        const roomsData = ReduxSearchData.rooms_counter.map((_, index) => {
          const childAgesKey = `child_ages${index + 1}`;
          return {
            adults: ReduxSearchData.Adults[index],
            children: ReduxSearchData.children[index],
            childrenAges: ReduxSearchData[childAgesKey], // Assuming this is empty for now, you can modify as needed
          };
        });
        setRooms(roomsData);
      } else {
        HotelDetail.rooms_options=[]
        dispatch(setHotelDetail(HotelDetail));
        // toast.error(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const ShowCancellationPolicy = async (id, cancell_arr, name) => {
    setRoomName(name);
    if (
      HotelDetail.hotel_provider !== "travelenda" &&
      HotelDetail.hotel_provider !== "Stuba"
    ) {
      setLoadingCancellation(false);
      setCancellationArr(cancell_arr);
      setOpen(true);
      return;
    }
    setLoadingCancellation(true);
    var data = {
      token: token,
      booking_req_id: id,
      hotel_provider: HotelDetail.hotel_provider,
    };
    setOpen(true);
    try {
      const response = await axios.post(
        baseurl + "/api/all_Hotel_Cancellation_Policy",
        data
      );
      if (response.status === 200) {
        if( HotelDetail.hotel_provider === "travelenda"){
          const firstCancellationDeadline = response.data.find(item => item.CancellationDeadline)?.CancellationDeadline;
          const deadline=new Date(firstCancellationDeadline);
          const nowdate= new Date();
          if(deadline < nowdate || firstCancellationDeadline===''){
            setCancellationArr([]);
            setLoadingCancellation(false);
          }else{
            setCancellationArr(response.data);
             setLoadingCancellation(false);
          }

        }else{
          setCancellationArr(response.data);
          setLoadingCancellation(false);
        }
      }
    } catch (error) {
      setLoadingCancellation(false);
      console.error(error);
    }
  };
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Modal.Header className="cancel-modal-header">
          <Modal.Title className="fw-bold">
            {roomName} - Cancellation Policy
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingCancellation ? (
            <Placeholder.Paragraph />
          ) : (
            <div>
              {CancellationArr.length !== 0 ? (
                CancellationArr.map((policy, index) => (
                  <div key={index}>
                    {policy.type === "Fix Amount" ||
                    policy.type === "Amount" ? (
                      <>
                        <h6>
                          <FontAwesomeIcon
                            className="text-success"
                            icon={faHandPointRight}
                          />{" "}
                          {priceConversion == false
                            ? HotelDetail.hotel_curreny
                            : Object.keys(SelectedCurrRates).length === 0
                            ? BaseCurrRates.base_code
                            : SelectedCurrRates.base_code}{" "}
                          {priceConversion == false
                            ? calculateMarkup(Number(policy.amount))
                            : renderPrice(
                                calculateMarkup(Number(policy.amount)),
                                HotelDetail.hotel_curreny
                              )}{" "}
                          {policy.type} will be charged after{" "}
                          {moment(policy.from_date).format("lll")}
                        </h6>
                      </>
                    ) : (
                      <h6 className="mb-2 mt-2">
                        <FontAwesomeIcon
                          className="text-success"
                          icon={faHandPointRight}
                        />{" "}
                        {policy.amount} {policy.type} will be charged after{" "}
                        {moment(policy.from_date).format("lll")}
                      </h6>
                    )}
                  </div>
                ))
              ) : (
                <h6 className="text-danger text-center mb-2 mt-2">
                  Non-Refundable
                </h6>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="ghost">
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      {isLoading && <Loader />}
      <ToastContainer />
      <Layout>
        <ModifySearch />
        <div className="container hotel-home-section">
          <div className="row mt-5">
            <div className="col-md-6 d-flex align-items-center col-sm-6 col-12 ">
              <Carousel autoplay className="custom-slider">
                {HotelDetail?.hotel_gallery.map((item) => (
                  <img className="hotel-listing-top-img" src={item} />
                ))}
              </Carousel>
            </div>
            <div className="col-md-6 col-sm-6 col-12 ">
              <div className="hotel-content">
                <h3>{HotelDetail?.hotel_name}</h3>
                <h6 className="view">
                  <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
                  {HotelDetail?.hotel_address}
                </h6>

                {HotelDetail.hotel_provider === "tbo" ||
                HotelDetail.hotel_provider === "travelenda" ||
                HotelDetail.hotel_provider === "GoGlobal" ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: HotelDetail.description,
                    }}
                  />
                ) : (
                  <p className="mt-2">
                    {HotelDetail?.description.slice(0, isHotelDetail)}
                  </p>
                )}

                {HotelDetail?.description.length > isHotelDetail && (
                  <p
                    onClick={() => setIsHotelDetail(isHotelDetail + 250)}
                    className="text-primary cursor-pointer"
                  >
                    View More
                  </p>
                )}
                <p className="fw-bold mt-2">Hotel Facilities</p>
                <div className="d-flex justify-content-between align-items-center">
                  <ul class="hotel-room room_tabs" role="tablist">
                    {HotelDetail?.hotel_facilities
                      .slice(0, isFacilities)
                      .map((item, index) => (
                        <li key={index} role="bravo_room">
                          {item}
                        </li>
                      ))}
                  </ul>
                </div>
                {HotelDetail?.hotel_facilities.length > 10 && (
                  <div className="text-center">
                    {isFacilities <= HotelDetail?.hotel_facilities.length ? (
                      <p
                        onClick={hamdlemorefacilities}
                        className="text-primary cursor-pointer"
                      >
                        View More
                      </p>
                    ) : (
                      <p
                        onClick={hamdlemorefacilitiesremove}
                        className="text-danger cursor-pointer"
                      >
                        Remove
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 col-sm-8 col-md-8 mt-2">
              <h4 className="mb-2 text-theme-color">Modify Search</h4>
              <div className="row">
                <div className="col-md-4 col-sm-6 col-12">
                  <DateRangePicker
                    onChange={handleDateRangeChange}
                    value={dateRange}
                    showOneCalendar={isMobile}
                    disabledDate={beforeToday()}
                    format="dd-MM-yyyy"
                    className="w-100 text-left "
                  />
                </div>
                <div className="col-md-4 col-sm-6 col-12">
                  <Whisper
                   open={isWhisperOpen}
                    placement="bottom"
                    speaker={speaker}
                    preventClose
                  >
                    <button
                     onClick={()=>setIsWhisperOpen(true)}
                      className="btn text-left  adult-btn  w-100 btn-block "
                      style={{ color: "black", background: "none" }}
                    >
                      {personData.adult} Adults . {personData.children} Children
                      . {personData.room} Room
                    </button>
                  </Whisper>
                </div>
                <div className="col-md-4 col-sm-6 col-12">
                  <button
                    onClick={GetCustonHotelsRooms}
                    className="theme-btn-1 w-100 ps-4 pe-4 py-2"
                  >
                    <FontAwesomeIcon className="ms-2 " icon={faRefresh} />{" "}
                    Modify
                  </button>
                </div>
              </div>
              <h4 className="mt-2 text-theme-color">Room Options</h4>
              <div class="card-body pt-2 p-0">
              {HotelDetail.rooms_options.length >0 ? (
                <div class="vstack gap-4">
                  {HotelDetail.rooms_options.map((item, index) => (
                    <div key={index} class="card shadow p-3">
                      <div class="row g-4">
                        <div class="col-md-5 position-relative">
                          {item.room_supplier_code !== "" && (
                            <div class="position-absolute top-0 start-0 z-index-1 mt-3 ms-4">
                              <div class="badge text-bg-danger">
                                {item.room_supplier_code}
                              </div>
                            </div>
                          )}
                          {item.rooms_images && item.rooms_images.length > 0 ? (
                            <img
                              style={{
                                height: "16em",
                                width: "100%",
                                objectFit: "cover",
                              }}
                              src={item.rooms_images[0]}
                              alt={item.room_name}
                            />
                          ) : (
                            <img
                              style={{
                                height: "16em",
                                width: "100%",
                                objectFit: "cover",
                              }}
                              src={img}
                              alt="Card image"
                            />
                          )}
                        </div>

                        <div class="col-md-7">
                          <div class="card-body d-flex flex-column h-100 p-0">
                            <h5 class="card-title">
                              <a>
                                {" "}
                                {item.room_name} ({item.board_id})
                              </a>
                            </h5>

                            {item.rooms_facilities &&
                            item.rooms_facilities.length > 0 ? (
                              <ul class="nav nav-divider mb-2">
                                {item.rooms_facilities
                                  .slice(0, 5)
                                  .map((item, index) => (
                                    <li key={index} class="nav-item">
                                      {item}
                                    </li>
                                  ))}
                              </ul>
                            ) : null}
                            {item.request_type === "1" && (
                              <div className="room-request">
                                <h6>Room on Request</h6>
                              </div>
                            )}
                            <div class="d-flex justify-content-between mt-2">
                              <h6>{item.adults}  {Number(item.adults)===1?'Adult':'Adults'}</h6>
                              <h6> {item.childs} {Number(item.childs)===1?'Children':'Childrens'}</h6>
                              <h6> {item.rooms_qty} {Number(item.rooms_qty)===1?'Room':'Rooms'}</h6>
                            </div>
                            <p
                              class="text-success text-center mb-2 mt-2"
                              onClick={() =>
                                ShowCancellationPolicy(
                                  item.booking_req_id,
                                  item.cancliation_policy_arr,
                                  item.room_name
                                )
                              }
                              style={{ color: "green", cursor: "pointer" }}
                            >
                              Show Cancellation Policy
                            </p>
                            <div class="d-sm-flex justify-content-sm-between align-items-center mt-auto">
                              <div class=" align-items-center">
                                <h5>
                                  {priceConversion == false
                                    ? HotelDetail.hotel_curreny
                                    : Object.keys(SelectedCurrRates).length ===
                                      0
                                    ? BaseCurrRates.base_code
                                    : SelectedCurrRates.base_code}{" "}
                                  {priceConversion == false
                                    ? calculateMarkup(
                                        Number(item?.room_Promotions_Exist) ===
                                          1
                                          ? item.rooms_selling_price_Promotion
                                          : item.rooms_total_price
                                      )
                                    : renderPrice(
                                        calculateMarkup(
                                          Number(
                                            item?.room_Promotions_Exist
                                          ) === 1
                                            ? item.rooms_selling_price_Promotion
                                            : item.rooms_total_price
                                        ),
                                        HotelDetail.hotel_curreny
                                      )}
                                  {item?.room_Promotions_Exist &&
                                    item.room_Promotions_Exist === "1" && (
                                      <sub style={{ color: "#808080ad" }}>
                                        <del>
                                          {priceConversion == false
                                            ? HotelDetail.hotel_curreny
                                            : Object.keys(SelectedCurrRates)
                                                .length === 0
                                            ? BaseCurrRates.base_code
                                            : SelectedCurrRates.base_code}{" "}
                                          {priceConversion === false
                                            ? calculateMarkup(
                                                item.rooms_total_price
                                              )
                                            : renderPrice(
                                                calculateMarkup(
                                                  item.rooms_total_price
                                                ),
                                                HotelDetail.hotel_curreny
                                              )}
                                        </del>
                                      </sub>
                                    )}
                                </h5>
                                <small>
                                  {priceConversion == false
                                    ? HotelDetail.hotel_curreny
                                    : Object.keys(SelectedCurrRates).length ===
                                      0
                                    ? BaseCurrRates.base_code
                                    : SelectedCurrRates.base_code}{" "}
                                  {priceConversion == false
                                    ? (
                                        calculateMarkup(
                                          Number(
                                            item?.room_Promotions_Exist
                                          ) === 1
                                            ? item.rooms_selling_price_Promotion
                                            : item.rooms_total_price
                                        ) / Number(numberOfNights)
                                      ).toFixed(2)
                                    : (
                                        renderPrice(
                                          calculateMarkup(
                                            Number(
                                              item?.room_Promotions_Exist
                                            ) === 1
                                              ? item.rooms_selling_price_Promotion
                                              : item.rooms_total_price
                                          ),
                                          HotelDetail.hotel_curreny
                                        ) / Number(numberOfNights)
                                      ).toFixed(2)}{" "}
                                  / Night
                                </small>
                                <br />
                                <small>vat and taxes included</small>
                              </div>
                              <div class="mt-3 mt-sm-0">
                                {hotelSelectedRooms.some(
                                  (item) => item.index === index
                                ) ? (
                                  <p
                                    onClick={(event) =>
                                      selectedRoomData(
                                        index,
                                        event,
                                        HotelDetail.hotel_provider
                                      )
                                    }
                                    class="btn btn-sm btn-danger w-100 mb-0"
                                  >
                                    Remove
                                  </p>
                                ) : (
                                  <p
                                    onClick={(event) =>
                                      selectedRoomData(
                                        index,
                                        event,
                                        HotelDetail.hotel_provider
                                      )
                                    }
                                    class="btn btn-sm btn-primary mb-0"
                                  >
                                    Select Room
                                  </p>
                                )}
                                {HotelDetail.hotel_provider ===
                                  "Custome_hotel" &&
                                  hotelSelectedRooms.some(
                                    (item) => item.index === index
                                  ) && (
                                    <div>
                                      <p>Select room Qty</p>
                                      <select
                                        onChange={(event) =>
                                          SelectedRoomQuitity(event, index)
                                        }
                                        className="my-1 form-select"
                                        aria-label="Default select example"
                                      >
                                        {Array.from(
                                          { length: item.room_Quantity  },
                                          (_, i) => (
                                            <option key={i} value={i + 1}>
                                              {i + 1}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                ):(
                  <div class="vstack gap-4">
                    <h6>Sorry, no rooms are available for your requested dates.</h6>
                    </div>
                )}
              </div>
            </div>
            <div className="col-12 col-sm-4 col-md-4 mt-2">
              <div
                class="sticky-element"
                data-sticky
                data-margin-top="100"
                data-sticky-for="1199"
              >
                <div class="card card-body border">
                  <div class="d-sm-flex justify-content-sm-between align-items-center mb-3">
                    <div>
                      <h5 class="card-title mb-0">Selected Rooms</h5>
                    </div>
                  </div>
                  {hotelSelectedRooms.length === 0 ? (
                    <div>
                      <p className="text-center">No Room Selected.</p>
                    </div>
                  ) : (
                    <>
                      {hotelSelectedRooms.map((item, index) => (
                        <div className="d-flex justify-content-between align-items-center">
                          <p key={index}>
                            {index + 1 + ")"}{" "}
                            {HotelDetail.rooms_options[item.index].room_name}
                          </p>
                          {priceConversion == false
                            ? HotelDetail.hotel_curreny
                            : Object.keys(SelectedCurrRates).length === 0
                            ? BaseCurrRates.base_code
                            : SelectedCurrRates.base_code}{" "}
                          {priceConversion == false
                            ? calculateMarkup(
                                Number(
                                  HotelDetail.rooms_options[item.index]
                                    .room_Promotions_Exist
                                ) === 1
                                  ? HotelDetail.rooms_options[item.index]
                                      .rooms_selling_price_Promotion
                                  : HotelDetail.rooms_options[item.index]
                                      .rooms_total_price
                              )
                            : renderPrice(
                                calculateMarkup(
                                  Number(
                                    HotelDetail.rooms_options[item.index]
                                      .room_Promotions_Exist
                                  ) === 1
                                    ? HotelDetail.rooms_options[item.index]
                                        .rooms_selling_price_Promotion
                                    : HotelDetail.rooms_options[item.index]
                                        .rooms_total_price
                                ),
                                HotelDetail.hotel_curreny
                              )}
                          {HotelDetail.hotel_provider === "Custome_hotel" &&
                            selectedRooms.find((r) => r.index === item.index) &&
                            ` * ${
                              selectedRooms.find((r) => r.index === item.index)
                                .rooms_qty
                            }`}
                        </div>
                      ))}
                    </>
                  )}

                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <button
                      type="button"
                      disabled
                      className="btn btn-outline-success w-100"
                    >
                      Total :{" "}
                      {priceConversion === false
                        ? HotelDetail.hotel_curreny
                        : Object.keys(SelectedCurrRates).length === 0
                        ? BaseCurrRates.base_code
                        : SelectedCurrRates.base_code}{" "}
                      {priceConversion === false
                        ? calculateMarkup(
                            hotelSelectedRooms.reduce((sum, item) => {
                              const room =
                                HotelDetail.rooms_options[item.index];
                              const roomPrice =
                                Number(room.room_Promotions_Exist) === 1
                                  ? room.rooms_selling_price_Promotion
                                  : room.rooms_total_price;

                              // Multiply by rooms_qty only if the hotel provider is "Custome_hotel"
                              const roomsQty =
                                HotelDetail.hotel_provider === "Custome_hotel"
                                  ? selectedRooms.find(
                                      (r) => r.index === item.index
                                    ).rooms_qty
                                  : 1; // Default to 1 if not a custom hotel

                              return sum + Number(roomPrice) * roomsQty;
                            }, 0)
                          )
                        : renderPrice(
                            calculateMarkup(
                              hotelSelectedRooms.reduce((sum, item) => {
                                const room =
                                  HotelDetail.rooms_options[item.index];
                                const roomPrice =
                                  Number(room.room_Promotions_Exist) === 1
                                    ? room.rooms_selling_price_Promotion
                                    : room.rooms_total_price;

                                // Multiply by rooms_qty only if the hotel provider is "Custome_hotel"
                                const roomsQty =
                                  HotelDetail.hotel_provider === "Custome_hotel"
                                    ? selectedRooms.find(
                                        (r) => r.index === item.index
                                      ).rooms_qty
                                    : 1; // Default to 1 if not a custom hotel

                                return sum + Number(roomPrice) * roomsQty;
                              }, 0)
                            ),
                            HotelDetail.hotel_curreny
                          )}
                    </button>
                  </div>
                  <div class="d-grid mt-2">
                    <button
                      onClick={BookRoom}
                      className="theme-btn-1 ps-4 pe-4 py-2"
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default HotelDetail;
