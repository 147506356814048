import React,{useState} from "react";
import logo from "../../Assets/Images/logo.png";
import { ApiEndPoint,ApiToken } from "../../Data/Api";
import axios from "axios";
import { ToastContainer,toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader";
import { setLoginUser } from "../../reducers/hotelActions";
import { useDispatch } from "react-redux";
function Login() {
  const token=ApiToken();
  const baseurl=ApiEndPoint();
  const navigate=useNavigate();
  const dispatch=useDispatch();
  const [userData, setUserData] = useState({ username: '', password: '' })
  const [loader, setLoader] = useState(false)
  const [loginerror, setLoginerror] = useState('')
  const HandleLogin = async (e) => {
    e.preventDefault();
    const data = {
      token:token,
      email: userData.username,
      password: userData.password
    }
    setLoader(true)
    try {
      const response = await axios.post(
        baseurl + '/api/login_customer_submit',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setLoader(false)
      if (response.data.status === 'error') {
        if(response.data.error_code===1){
          setLoginerror('Your account has not been approved from AlSubaee Team. Please wait for 24 to 48 hours or contact at info@alsubaee.com.');
        }else if(response.data.error_code===2){
          setLoginerror('Please use the correct password or change password by using forgot password option.');
        }
        else if(response.data.error_code===4){
          setLoginerror('Your entered email has not been registered with us, Please use correct email or Sign up to create account with us.')
        }else{
          setLoginerror(response.data.message);
        }
       
      } else {
        dispatch(setLoginUser(response.data.b2b_agent))
        const loginTime = new Date().getTime();
        localStorage.setItem("loginTime", loginTime);
        localStorage.setItem("authToken", response.data.b2b_agent.token);
        navigate("/");
      }
    } catch (error) {
      setLoader(false)
      console.error('Error:', error)
    }
  };
  function handleChange (e) {
    setUserData({ ...userData, [e.target.name]: e.target.value })
  }
  const ForgotPassword=()=>{
    localStorage.removeItem("ForgetStepCount");
    navigate("/forgot_password");
  }
  return (
    <>
    {loader &&(<Loader/>)}
    <ToastContainer/>
      <div className="login1">
        <div className="container">
          <div>
            <img src={logo} className="login-logo mt-3 hide-996" />
          </div>
          <div className="d-flex w-100">
            <div className="w-100  show-text-center hide-996">
              <h3 className="login-text">Welcome to Al Subaee Holidays</h3>
              <p className="login-text-1">
                Customer Service | Experience | Streamline
              </p>
            </div>
            <div className="w-100">
              <form onSubmit={HandleLogin} className="login-form">
                <img src={logo}  className="login-form-logo" />
                <div>
                  <p className="mt-3 signin-text">
                    Please sign into your account
                  </p>
                  <div className="form-group mt-4">
                    <label>Email ID</label>
                    <input
                      className="form-control mt-2"
                      type="text"
                      name="username"
                      value={userData.username}
                      onChange={handleChange}
                      placeholder="example@gmail.com"
                    />
                  </div>
                  <div className="form-group mt-3">
                  <a  class="text-muted float-end forget-password-link">
                  <p onClick={ForgotPassword}  className='text-primary forget-password-link' >
                    <small>Forgot your password?</small>
                    </p>
                    </a>
                    <label>Password</label>
                    <input
                      className="form-control mt-2"
                      type="password"
                      name="password"
                      value={userData.password}
                      onChange={handleChange}
                      placeholder="Password"
                    />
                  </div>
                  {loginerror && <span className="text-danger text-center">{loginerror}</span>}
                  <button disabled={(userData.password==='' || userData.username==='')} type="submit" className="theme-button">Login</button>
                  <p className="mt-3 d-flex">
                    New on our platform?{" "} <NavLink className='theme-text-color nav-link ms-1' to='/register'> Sign Up </NavLink>{" "}
                  
                  </p>
                </div>
              </form>
            </div>
          </div>
          <div className="login-footer">
            <p>© 2024 Alsubaee All Rights Reserved</p>
          </div>
        </div>
      </div>
    </>
  );
}
export default Login;
