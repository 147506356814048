import React, { useEffect, useState } from 'react'
import img2 from '../Assets/Images/contact.svg'
import Layout from '../Components/Layout'
import { ToastContainer, toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ApiEndPoint,ApiToken } from '../Data/Api'
import { faGlobe, faHeadset, faInbox } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import axios from 'axios'
const ContactusNew = ({ onLogout }) => {
  const baseurl=ApiEndPoint();
  const token=ApiToken();
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    subject: '',
    phnno: '',
    message: ''
  })
  const [isBooking, setIsBooking] = useState(false)
  const handleChange = event => {
    const { value, name } = event.target
    setFormData(prevdata => ({
      ...prevdata,
      [name]: value
    }))
  }

  const isValidEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const submitForm = async () => {
    if (formData.name === '') {
      toast.info('Please Enter Your Name.')
      return
    } else if (formData.email === '') {
      toast.info('Please Enter Your Email.')
      return
    } else if (formData.subject === '') {
      toast.info('Please Enter Subject.')
      return
    } else if (formData.phnno === '') {
      toast.info('Please Enter Phone Number.')
      return
    } else if (formData.message === '') {
      toast.info('Please Enter Message.')
      return
    } else if (!isValidEmail(formData.email)) {
      toast.info('Please Enter a valid email.')
      return
    }
    setIsBooking(true);
    var data={
      token:token,
      first_name:formData.name,
      last_name:'',
      email:formData.email,
      subject:formData.subject,
      contact:formData.phnno,
      message:formData.message,
    }
    try{
      const response= await axios.post(baseurl+'/api/mail_Check_Alsubaee_Contact_Us',data);
      setIsBooking(false)
      if(response.data==='Success'){
        setFormData({
          email: '',
          name: '',
          subject: '',
          phnno: '',
          message: ''
        })
        toast.success('Your request has been submitted successfully, Alsubaee Team will contact you within 24 to 48 hours.');
       
      }
    }catch(error){
      setIsBooking(false)
      console.log(error);
    }
  }
  return (
    <>
      <ToastContainer />
      <Layout logout={onLogout}>
        <section class='pt-4 pt-md-5'>
          <div class='container'>
            <div class='row mb-5'>
              <div class='col-xl-10'>
                <h3>
                  We’d love to hear from you! Whether you have questions,
                  feedback, or need support, feel free to reach out to us
                </h3>
                <p class='lead mb-0'>Here’s how you can get in touch:</p>
              </div>
            </div>

            <div class='row g-4'>
              <div class='col-md-6 col-xl-4'>
                <div class='card card-body shadow text-center align-items-center h-100'>
                  <div class='icon-lg bg-info bg-opacity-10 text-info rounded-circle mb-2'>
                    <i class='bi bi-headset fs-5'>
                      <FontAwesomeIcon icon={faHeadset} />
                    </i>
                  </div>
                  <h5>Call us</h5>

                  <div class='d-grid gap-3 d-sm-block'>
                  <a href="tel://+966593940580">
                    <button class='btn btn-sm btn-primary-soft'>
                      <i class='bi bi-phone me-2'></i> +966 59 394 0580
                    </button>
                    </a>
                    <a href="tel://+14752379600">
                    <button class='btn btn-sm btn-light'>
                      <i class='bi bi-telephone me-2'></i>+1 475 237 9600
                    </button>
                    </a>
                  </div>
                </div>
              </div>

              <div class='col-md-6 col-xl-4'>
                <div class='card card-body shadow text-center align-items-center h-100'>
                  <div class='icon-lg bg-danger bg-opacity-10 text-danger rounded-circle mb-2'>
                    <i class='bi bi-inboxes-fill fs-5'>
                      <FontAwesomeIcon icon={faInbox} />
                    </i>
                  </div>
                  <h5>Email us</h5>

                  <a
                  href="mailto:info@AlSubaeeHolidays.com"
                    class='btn btn-link text-decoration-underline p-0 mb-0'
                  >
                    <i class='bi bi-envelope me-1'></i>info@AlSubaeeHolidays.com
                  </a>
                </div>
              </div>

              <div class='col-xl-4 position-relative'>
                

                <div class='card card-body shadow text-center align-items-center h-100'>
                  <div class='icon-lg bg-orange bg-opacity-10 text-orange rounded-circle mb-2'>
                    <i class='bi bi-globe2 fs-5'>
                      <FontAwesomeIcon icon={faGlobe} />
                    </i>
                  </div>
                  <h5>Social media</h5>

                  <ul class='list-inline mb-0'>
                    <li class='list-inline-item'>
                      {' '}
                      <a target='blank' class='btn btn-sm bg-facebook px-2 mb-0' >
                        <i class='fab fa-fw fa-facebook-f'><FontAwesomeIcon icon={faFacebook}/></i>
                      </a>{' '}
                    </li>
                    <li class='list-inline-item'>
                      {' '}
                      <a target='blank' class='btn btn-sm bg-instagram px-2 mb-0' >
                        <i class='fab fa-fw fa-instagram'><FontAwesomeIcon icon={faInstagram}/></i>
                      </a>{' '}
                    </li>
                    
                    <li class='list-inline-item'>
                      {' '}
                      <a target='blank' class='btn btn-sm bg-linkedin px-2 mb-0' >
                        <i class='fab fa-fw fa-linkedin-in'><FontAwesomeIcon icon={faLinkedin}/></i>
                      </a>{' '}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class='pt-0 pt-lg-5'>
          <div class='container'>
            <div class='row g-4 g-lg-5 align-items-center'>
              <div class='col-lg-6 text-center'>
                <img style={{width:'100%'}} src={img2} alt='' />
              </div>

              <div class='col-lg-6'>
                <div class='card bg-light p-4'>
                  {/* <figure class='position-absolute end-0 bottom-0 mb-n4 me-n2'>
                    <svg class='fill-orange' width='104.2px' height='95.2px'>
                      <circle cx='2.6' cy='92.6' r='2.6' />
                      <circle cx='2.6' cy='77.6' r='2.6' />
                      <circle cx='2.6' cy='62.6' r='2.6' />
                      <circle cx='2.6' cy='47.6' r='2.6' />
                      <circle cx='2.6' cy='32.6' r='2.6' />
                      <circle cx='2.6' cy='17.6' r='2.6' />
                      <circle cx='2.6' cy='2.6' r='2.6' />
                      <circle cx='22.4' cy='92.6' r='2.6' />
                      <circle cx='22.4' cy='77.6' r='2.6' />
                      <circle cx='22.4' cy='62.6' r='2.6' />
                      <circle cx='22.4' cy='47.6' r='2.6' />
                      <circle cx='22.4' cy='32.6' r='2.6' />
                      <circle cx='22.4' cy='17.6' r='2.6' />
                      <circle cx='22.4' cy='2.6' r='2.6' />
                      <circle cx='42.2' cy='92.6' r='2.6' />
                      <circle cx='42.2' cy='77.6' r='2.6' />
                      <circle cx='42.2' cy='62.6' r='2.6' />
                      <circle cx='42.2' cy='47.6' r='2.6' />
                      <circle cx='42.2' cy='32.6' r='2.6' />
                      <circle cx='42.2' cy='17.6' r='2.6' />
                      <circle cx='42.2' cy='2.6' r='2.6' />
                      <circle cx='62' cy='92.6' r='2.6' />
                      <circle cx='62' cy='77.6' r='2.6' />
                      <circle cx='62' cy='62.6' r='2.6' />
                      <circle cx='62' cy='47.6' r='2.6' />
                      <circle cx='62' cy='32.6' r='2.6' />
                      <circle cx='62' cy='17.6' r='2.6' />
                      <circle cx='62' cy='2.6' r='2.6' />
                      <circle cx='81.8' cy='92.6' r='2.6' />
                      <circle cx='81.8' cy='77.6' r='2.6' />
                      <circle cx='81.8' cy='62.6' r='2.6' />
                      <circle cx='81.8' cy='47.6' r='2.6' />
                      <circle cx='81.8' cy='32.6' r='2.6' />
                      <circle cx='81.8' cy='17.6' r='2.6' />
                      <circle cx='81.8' cy='2.6' r='2.6' />
                      <circle cx='101.7' cy='92.6' r='2.6' />
                      <circle cx='101.7' cy='77.6' r='2.6' />
                      <circle cx='101.7' cy='62.6' r='2.6' />
                      <circle cx='101.7' cy='47.6' r='2.6' />
                      <circle cx='101.7' cy='32.6' r='2.6' />
                      <circle cx='101.7' cy='17.6' r='2.6' />
                      <circle cx='101.7' cy='2.6' r='2.6' />
                    </svg>
                  </figure> */}

                  <div class='card-header bg-light p-0 pb-3'>
                    <h3 class='mb-0'>Send us message</h3>
                  </div>

                  <div class='card-body p-0'>
                    <div class='row g-4'>
                      <div class='col-md-6'>
                        <label class='form-label'>Your name</label>
                        <input
                          type='text'
                          class='form-control'
                          value={formData.name}
                          onChange={handleChange}
                          placeholder='Enter Name...'
                          name='name'
                          required
                        />
                      </div>
                      <div class='col-md-6'>
                        <label class='form-label'>Email address</label>
                        <input
                          type='email'
                          class='form-control'
                          onChange={handleChange}
                          value={formData.email}
                          placeholder='Email'
                          name='email'
                          required
                        />
                      </div>
                      <div class='col-md-6'>
                        <label class='form-label'>Subject</label>
                        <input
                          type='text'
                          class='form-control'
                          value={formData.subject}
                          onChange={handleChange}
                          placeholder='Enter Subject...'
                          name='subject'
                          required
                        />
                      </div>
                      <div class='col-md-6'>
                        <label class='form-label'>Mobile number</label>
                        <input
                          type='text'
                          class='form-control'
                          value={formData.phnno}
                          onChange={handleChange}
                          placeholder='+123456789'
                          name='phnno'
                          required
                        />
                      </div>
                      <div class='col-12'>
                        <label class='form-label'>Message</label>
                        <textarea
                          class='form-control'
                          placeholder='What’s on your mind'
                          name='message'
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </div>
                      <div class='col-12'>
                        <button
                          type='submit'
                          disabled={isBooking}
                          onClick={submitForm}
                          className='btn btn-dark mb-0'
                        >
                          {' '}
                          {isBooking ? <Spinner /> : 'Submit'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

const Spinner = () => {
  return (
    <div className='spinner-border' role='status'>
      <span className='visually-hidden'>Loading...</span>
    </div>
  )
}
export default ContactusNew
