import React, { useState } from "react";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { ApiEndPoint } from "../../Data/Api";
import { Tooltip, Whisper } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import PasswordStrengthBar from "react-password-strength-bar";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
function Settingage({ userData }) {
  const [error, seterror] = useState({});
  const baseurl = ApiEndPoint();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [paswordDetail, setPasswordDetail] = useState({
    oldpass: "",
    newpass: "",
    confirmpass: "",
  });
  const [requirementsStatus, setRequirementsStatus] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    specialChar: false,
  });
  const handlePasswordChange = (event) => {
    const { value, name } = event.target;
    if (name === "newpass") {
      setRequirementsStatus({
        length: value.length >= 11,
        lowercase: /[a-z]/.test(value),
        uppercase: /[A-Z]/.test(value),
        number: /\d/.test(value),
        specialChar: /[#.\-?!@$%^&*]/.test(value),
      });
    }
    setPasswordDetail({
      ...paswordDetail,
      [name]: value,
    });
  };
  const submitpassword = async () => {
    let newErrors = {};
    if (!paswordDetail.oldpass) {
      newErrors.oldpass = "Enter Current Password.";
      seterror(newErrors);
      return;
    }
    const { length, lowercase, uppercase, number, specialChar } =
      requirementsStatus;
    const isPasswordValid =
      length && lowercase && uppercase && number && specialChar;
    if (!isPasswordValid) {
      return;
    } else if (!paswordDetail.confirmpass) {
      newErrors.confirmpass = "Enter Confirm Password.";
      seterror(newErrors);
      return;
    } else if (paswordDetail.newpass !== paswordDetail.confirmpass) {
      newErrors.match = "Password not match.";
      seterror(newErrors);
      return;
    }
    setIsBooking(true);
    const data = {
      token: userData.token,
      current_password: paswordDetail.oldpass,
      new_password: paswordDetail.newpass,
      cnew_password: paswordDetail.confirmpass,
      id: userData.id,
    };

    try {
      const response = await Axios.post(
        baseurl + "/api/agent_change_password",
        data
      );
      setIsBooking(false);
      if (response.data.status !== "error") {
        toast.success(response.data.message);
        setPasswordDetail({ oldpass: "", newpass: "", confirmpass: "" });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setIsBooking(false);
      console.log(error);
      console.error("Error:", error);
    }
  };
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  return (
    <>
      <ToastContainer />
      <div style={{ background: "#f7f7fa" }}>
        <h3 style={{ color: "#000" }} className="text-center p-3">
          Change Password
        </h3>
        <div className="hr-devider"></div>

        <div class="form-group p-4">
          <label>Current Password</label>
          <div className="input-group input-group-merge ">
          <input
            type={showOldPassword ? "text" : "password"}
            onChange={handlePasswordChange}
            value={paswordDetail.oldpass}
            name="oldpass"
            class="form-control mt-1"
            placeholder="Enter current password"
          />
           <span
              className="input-group-text"
              onClick={toggleOldPasswordVisibility}
              style={{ cursor: "pointer", marginTop: "auto" }}
            >
              <FontAwesomeIcon icon={showOldPassword ? faEyeSlash : faEye} />
            </span>
          </div>
          {error.oldpass && (
            <span className="text-danger">{error.oldpass}</span>
          )}
        </div>
        <div class="form-group p-4">
          <label>New Password</label>
          <div className="input-group input-group-merge ">
            <input
              type={showNewPassword ? "text" : "password"}
              class="form-control mt-1"
              onChange={handlePasswordChange}
              value={paswordDetail.newpass}
              name="newpass"
              placeholder="Enter new password"
            />
            <span
              className="input-group-text"
              onClick={toggleNewPasswordVisibility}
              style={{ cursor: "pointer", marginTop: "auto" }}
            >
              <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye} />
            </span>
          </div>
          <div id="requirements" className="password-requirements">
            <p
              className={`requirement ${
                requirementsStatus.length ? "text-success" : "text-danger"
              }`}
              id="length"
            >
              Min. 11 characters
            </p>
            <p
              className={`requirement ${
                requirementsStatus.lowercase ? "text-success" : "text-danger"
              }`}
              id="lowercase"
            >
              Include lowercase letter
            </p>
            <p
              className={`requirement ${
                requirementsStatus.uppercase ? "text-success" : "text-danger"
              }`}
              id="uppercase"
            >
              Include uppercase letter
            </p>
            <p
              className={`requirement ${
                requirementsStatus.number ? "text-success" : "text-danger"
              }`}
              id="number"
            >
              Include number
            </p>
            <p
              className={`requirement ${
                requirementsStatus.specialChar ? "text-success" : "text-danger"
              }`}
              id="characters"
            >
              Include a special character: #.-?!@$%^&*
            </p>
          </div>
        </div>
        <div class="form-group px-4">
          <label>Confirm New Password</label>
          <div className="input-group input-group-merge ">
            <input
              type={showConfirmPassword ? "text" : "password"}
              class="form-control mt-1"
              onChange={handlePasswordChange}
              value={paswordDetail.confirmpass}
              name="confirmpass"
              placeholder="Enter confirm password"
            />
            <span
              className="input-group-text"
              onClick={toggleConfirmPasswordVisibility}
              style={{ cursor: "pointer", marginTop: "auto" }}
            >
              <FontAwesomeIcon
                icon={showConfirmPassword ? faEyeSlash : faEye}
              />
            </span>
          </div>
          {error.confirmpass && (
            <span className="text-danger">{error.confirmpass}</span>
          )}
          {error.match && <span className="text-danger">{error.match}</span>}
        </div>

        <div className="p-4 text-end">
          <button className="btn btn-primary" onClick={submitpassword}>
            {isBooking ? "Please Wait...." : "Submit"}
          </button>
        </div>
      </div>
    </>
  );
}
export default Settingage;
