import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCardAlt, faStar } from "@fortawesome/free-regular-svg-icons";
import { Stripe } from "stripe";
import { StripeClientSecret } from "../Data/Api";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Checkbox, Placeholder } from "rsuite";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import moment from "moment";
import { countryListLocal } from "../Data/CountryList";
import Select from "react-select";
import {
  faAngleDown,
  faBank,
  faDollar,
} from "@fortawesome/free-solid-svg-icons";
import { ApiEndPoint, ApiToken,CurrencyConverter } from "../Data/Api";
import axios from "axios";
import BookingLoader from "../Components/BookingLoader";
import { useNavigate } from "react-router-dom";
var StripePayment = 0;
var StripeCurrency = "";
var ServiceCharges = 0;
var Baseexhangeprice = 0;
var Baseexhangerate = 0;
var adminprice = 0;
function HotelCheckout() {
  const token = ApiToken();
  const baseurl = ApiEndPoint();
  const navigate = useNavigate();
  const todayDate = new Date();
  const BaseCurrRates = useSelector((state) => state.currency.baseCurrRates);
  const SelectedCurrRates = useSelector(
    (state) => state.currency.selectedCurrRates
  );
  const UserDetail = useSelector((state) => state.user.user);
  const ReduxSearchData = useSelector((state) => state.hotelSearch.searchQuery);
  const HotelCheckout = useSelector(
    (state) => state.hotelCheckout.hotelCheckout
  );
  // console.log(HotelCheckout);
  let Checkin = moment(HotelCheckout?.checkIn);
  let checkout = moment(HotelCheckout?.checkOut);
  let daysBetween = Math.abs(checkout.diff(Checkin, "days"));
  var Secretkey = StripeClientSecret();
  const stripe = new Stripe(Secretkey);
  const elements = useElements();
  const stripeInstance = useStripe();
  const [error, setError] = useState(null);
  const [cardHolderName, setCardHolderName] = useState("");
  const [creditError,setCreditError]=useState('');
  const [savemarkup, setSavemarkup] = useState({
    totalroomprice: 0,
    totalmealprice: 0,
    admin: 0,
    client: 0,
    final: 0,
  });

  const [gestinfo, setGestinfo] = useState({
    title: "",
    firstname: '',
    lastname:'',
    dateofbirth: "",
    numbercode: "",
    email: UserDetail.email,
    phonenumber: UserDetail.phone_no,
    gender: "",
    pno: "",
    pexpiry: "",
    country: "",
    nationality: "",
    postalcode: "",
  });
  const [loaderLoading, setLoaderLoading] = useState(false);
  // const [selectedValue, setSelectedValue] = useState(
  //   HotelCheckout.hotel_provider !== "Custome_hotel" &&
  //     HotelCheckout.hotel_provider !== "custom_hotel_provider" &&
  //     new Date(
  //       HotelCheckout?.rooms_list[0].cancliation_policy_arr[0].from_date
  //     ) > todayDate
  //     ? "nopayment"
  //     : "cardpayment"
  // );
  const [selectedValue, setSelectedValue] = useState("cardpayment");
  const [message, setMessage] = useState(true);
  const [isBooking, setIsBooking] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [totalRoomsCount, setTotalRoomsCount] = useState(0);
  const [totalAdults, setTotalAdults] = useState(0);
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [otherGuestData, setOtherGuestData] = useState(false);
  const [selectedNationality, setSelectedNationality] = useState("");
  const [selectedcurr, setSelectedCurr] = useState(
    localStorage.getItem("DefaultCurrency")
  );
  const [priceConversion, setPriceConversion] = useState(false);
  const [currencyRates, setCurrencyRates] = useState([]);
  const [mealTypes, setMealTypes] = useState([]);
  const [selectedMeals, setSelectedMeals] = useState([]);
  const [mealTypesLoader, setMealTypesLoader] = useState(false);
  const adultCount = ReduxSearchData.adult;
  const childCount = ReduxSearchData.child;
  const [guests, setGuests] = useState(Array(adultCount).fill({}));
  const [childs, setChilds] = useState(Array(childCount).fill({}));
  const childsArray = Array.from({ length: childCount });
  const adultsArray = Array.from({ length: adultCount - 1 });

  useEffect(() => {
    const countryOptions = countryListLocal.item.map((country) => ({
      value: country.name.common,
      label: country.name.common,
      flag: country.flags.png,
      phoneCode:
        country.cca3 === "USA"
          ? country.idd.root
          : country.idd.root + country.idd.suffixes[0],
    }));
    var usercountry=countryOptions.find(item=> item.label===UserDetail.country);
    if(usercountry){
      setSelectedCountry(usercountry);
      setSelectedNationality(usercountry.value);
      setGestinfo((prevdata) => ({
        ...prevdata,
        numbercode: usercountry.phoneCode,
        nationality: usercountry.value,
      }));
    }
   
    var totalroom = HotelCheckout.rooms_list.reduce((sum, item) => {
      return sum + Number(item.selected_qty);
    }, 0);
    var totaladults = HotelCheckout.rooms_list.reduce((sum, item) => {
      return sum + Number(item.selected_qty) * item.adults;
    }, 0);
    setTotalAdults(totaladults);
    setTotalRoomsCount(totalroom);
    var currdata = localStorage.getItem("AllHotelCurr");
    if (currdata !== "null" && currdata !== null) {
      setPriceConversion(true);
      setCurrencyRates(currdata);
    }
    setCountryList(countryOptions);
    GetMealTypes();
    totalcount();
  }, []);

  const GetMealTypes = async () => {
    setMealTypesLoader(true);
    var data = {
      token: token,
      // hotel_Id:522,
      hotel_Id: HotelCheckout.hotel_id,
      start_Date: HotelCheckout?.checkIn,
      end_Date: HotelCheckout?.checkOut,
    };
    try {
      const response = await axios.post(
        baseurl + "/api/get_Meal_Types_Custom_Hotel",
        data
      );
      // console.log(response);
      setMealTypesLoader(false);
      if (response.data.status === "success") {
        setMealTypes(response.data.meal_Types);
      }
    } catch (error) {
      setMealTypesLoader(false);
      console.log(error);
    }
  };
  const totalcount = () => {
    let allroomsprice = 0;
    let markupprice = 0;
    let adminmarkupprice = 0;
    let clientmarkupprice = 0;
    let finalpricemarkup = 0;
    allroomsprice = HotelCheckout.rooms_list.reduce(
      (sum, item) =>
        sum +
        Number(
          item.room_Promotions_Exist === "1"
            ? item.rooms_total_price_Promotion
            : item.rooms_total_price
        ),
      0
    );
    savemarkup.totalroomprice = allroomsprice;
    finalpricemarkup = allroomsprice;
    if (Number(HotelCheckout.admin_markup) !== 0) {
      if (HotelCheckout.admin_markup_type === "Percentage") {
        markupprice =
          (allroomsprice * Number(HotelCheckout.admin_markup)) / 100;
      } else {
        markupprice = Number(HotelCheckout.admin_markup);
      }
      adminmarkupprice = markupprice;
      finalpricemarkup += markupprice;
      savemarkup.admin = adminmarkupprice;
      savemarkup.final = finalpricemarkup;
    }
    if (Number(HotelCheckout.customer_markup) !== 0) {
      if (HotelCheckout.customer_markup_type === "Percentage") {
        markupprice =
          (finalpricemarkup * Number(HotelCheckout.customer_markup)) / 100;
      } else {
        markupprice = Number(HotelCheckout.customer_markup);
      }
      clientmarkupprice = markupprice;
      finalpricemarkup += markupprice;
      savemarkup.client = clientmarkupprice;
      savemarkup.final = finalpricemarkup;
    } else {
      savemarkup.final = allroomsprice;
    }
  };
  const handlegestchange = (e) => {
    const { value, name } = e.target;
    setGestinfo((prevgestinfo) => ({ ...prevgestinfo, [name]: value }));
  };
  const handlePayment = async () => {
    if (cardHolderName === "") {
      setError("Enter Card Holder Name.");
      return { success: false };
    }
    const response = await stripe.paymentIntents.create({
      amount: (Number(StripePayment) * 100).toFixed(0), // Amount in cents
      currency: StripeCurrency,
      description: "Live payment",
      payment_method_types: ["card"],
    });
    var Client_Secret = response.client_secret;

    try {
      // Confirm the payment intent with the payment method
      const { paymentIntent, error } = await stripeInstance.confirmCardPayment(
        Client_Secret,
        {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: cardHolderName,
            },
          },
        }
      );

      if (error) {
        setError(error.message);
        console.error(error);
        setLoaderLoading(false);
        setIsBooking(false);
        return { success: false, data: "" };
      } else {
        return { success: true, data: paymentIntent };
      }
    } catch (error) {
      console.error("Error:", error);
      setIsBooking(false);
      setLoaderLoading(false);

      return { success: false, data: "" };
    }
  };
  const handlecardnamechange = (event) => {
    setCardHolderName(event.target.value);
  };

  const calculateMarkup = (price) => {
    if (Object.keys(HotelCheckout).length !== 0) {
      let markupprice = 0;
      let adminmarkupprice = 0;
      let clientmarkupprice = 0;
      let finalpricemarkup = Number(price);
      if (Number(HotelCheckout.admin_markup) !== 0) {
        if (HotelCheckout.admin_markup_type === "Percentage") {
          markupprice = (price * Number(HotelCheckout.admin_markup)) / 100;
        } else {
          markupprice = Number(HotelCheckout.admin_markup);
        }
        adminmarkupprice = markupprice;
        finalpricemarkup += markupprice;
      }
      if (Number(HotelCheckout.customer_markup) !== 0) {
        if (HotelCheckout.customer_markup_type === "Percentage") {
          markupprice =
            (Number(price) * Number(HotelCheckout.customer_markup)) / 100;
        } else {
          markupprice = Number(HotelCheckout.customer_markup);
        }
        clientmarkupprice = markupprice;
        finalpricemarkup += markupprice;
      }

      if (Number(finalpricemarkup) % 1 !== 0) {
        return Number(finalpricemarkup).toFixed(2);
      }
      return finalpricemarkup;
    }
  };
  // const calculateServiceCharges = (price) => {
  //   var charges = (2.5 / 100) * price;
  //   if (Number(charges) % 1 !== 0) {
  //     return Number(charges).toFixed(2);
  //   }
  //   return charges;
  // };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setSelectedNationality(selectedOption.value);
    setGestinfo((prevdata) => ({
      ...prevdata,
      phonenumber: selectedOption.phoneCode,
      numbercode: selectedOption.phoneCode,
      nationality: selectedOption.value,
    }));
  };
  const ShowOtherGuestForm = () => {
    setOtherGuestData(!otherGuestData);
  };

  const otherGuestInfo = (e, guestIndex, digit) => {
    const selectedValue = e.target.value;
    if (digit === 2) {
      setChilds((prevChilds) => {
        const updatedChilds = [...prevChilds];
        updatedChilds[guestIndex] = {
          ...updatedChilds[guestIndex],
          title: selectedValue,
        };
        return updatedChilds;
      });
    } else if (digit === 1) {
      setGuests((prevGuests) => {
        const updatedGuests = [...prevGuests];
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          other_title: selectedValue,
        };
        return updatedGuests;
      });
    }
  };
  const otherGuestFirstName = (e, guestIndex, digit) => {
    // Handle the first name input and update the state
    const firstName = e.target.value;
    if (digit === 2) {
      setChilds((prevChilds) => {
        const updatedChilds = [...prevChilds];
        updatedChilds[guestIndex] = {
          ...updatedChilds[guestIndex],
          firstName: firstName,
        };
        return updatedChilds;
      });
    } else if (digit === 1) {
      setGuests((prevGuests) => {
        const updatedGuests = [...prevGuests];
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          other_first_name: firstName,
        };
        return updatedGuests;
      });
    }
  };
  const otherGuestLastName = (e, guestIndex, digit) => {
    // Handle the last name input and update the state
    const lastName = e.target.value;
    if (digit === 2) {
      setChilds((prevChilds) => {
        const updatedChilds = [...prevChilds];
        updatedChilds[guestIndex] = {
          ...updatedChilds[guestIndex],
          lastName: lastName,
        };
        return updatedChilds;
      });
    } else if (digit === 1) {
      setGuests((prevGuests) => {
        const updatedGuests = [...prevGuests];
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          other_last_name: lastName,
        };
        return updatedGuests;
      });
    }
  };
  const otherGuestNationality = (e, guestIndex, digit) => {
    // Handle the last name input and update the state
    const newvalue = e.target.value;
    if (digit === 2) {
      setChilds((prevChilds) => {
        const updatedChilds = [...prevChilds];
        updatedChilds[guestIndex] = {
          ...updatedChilds[guestIndex],
          nationality: newvalue,
        };
        return updatedChilds;
      });
    } else if (digit === 1) {
      setGuests((prevGuests) => {
        const updatedGuests = [...prevGuests];
        updatedGuests[guestIndex] = {
          ...updatedGuests[guestIndex],
          other_passport_country: newvalue,
        };
        return updatedGuests;
      });
    }
  };
  const handleCheckboxContinue = () => {
    setIsChecked(!isChecked);
  };

  const confirmbooking = async () => {
    // console.log(selectedMeals)
    if (gestinfo.title === "") {
      toast.error("Please Select Lead Guest Title.");
      return;
    } else if (gestinfo.firstname === "") {
      toast.error("Please Enter Lead Guest First Name.");
      return;
    } else if (gestinfo.lastname === "") {
      toast.error("Please Enter Lead Guest Last Name.");
      return;
    } else if (gestinfo.email === "") {
      toast.error("Please Enter Lead Guest Email.");
      return;
    } else if (selectedNationality === "") {
      toast.error("Please Select Lead Guest Nationality.");
      return;
    } else if (gestinfo.phonenumber.length < 5) {
      toast.error("Please Enter Phone Number.");
      return;
    }
    if (isChecked) {
      setIsBooking(true);
      setLoaderLoading(true);
      var paymentmethod = "slc_stripe";
      if (HotelCheckout.hotel_provider === "Custome_hotel") {
        if (HotelCheckout?.rooms_list[0]?.request_type !== "1") {
          if (selectedValue === "cardpayment") {
            var status = await handlePayment();
            if (status.success === false) {
              setLoaderLoading(false);
              setIsBooking(false);
              return;
            }
            setMessage(false);
          } else if (selectedValue === "bankpayment") {
            var status = { success: false, data: "" };
            paymentmethod = "Bank_Payment";
          } 
          // else if (selectedValue === "creditpayment") {
          //   var status = await CheckCreditAmount();
          //   if(status.success === false){
          //     setLoaderLoading(false);
          //     setIsBooking(false);
          //     return;
          //   }
          //   paymentmethod = "Credit_Payment";
          // }  else {
          //   var status = { success: false, data: "" };
          //   paymentmethod = "nopayment";
          // }
        } else {
          var status = { success: false, data: "" };
        }
      } else {
        var status = await handlePayment();
        if (status.success === false) {
          setLoaderLoading(false);
          setIsBooking(false);
          return;
        }
        setMessage(false);
      }
      var title = [];
      var firstname = [];
      var lastname = [];
      var nationality = [];
      if (guests.length !== 0) {
        guests.forEach((person) => {
          if (person.other_title && person.other_first_name) {
            title.push(person.other_title);
            firstname.push(person.other_first_name);
            lastname.push(person.other_last_name);
            nationality.push(person.other_passport_country);
          }
        });
      }

      var Childtitle = [];
      var Childfirstname = [];
      var Childlastname = [];
      var Childnationality = [];
      if (childs.length !== 0) {
        childs.forEach((children) => {
          if (children.title && children.firstName) {
            Childtitle.push(children.title);
            Childfirstname.push(children.firstName);
            Childlastname.push(children.lastName);
            Childnationality.push(children.nationality);
          }
        });
      }
      setAdminPrice(savemarkup.final);
      setBasePrice(savemarkup.final);
      const totalHotelPrice = HotelCheckout.rooms_list.reduce((sum, item) => {
        return sum + item.room_Promotions_Exist === "1"
          ? Number(item.rooms_total_price_Promotion)
          : Number(item.rooms_total_price);
      }, 0);
      var exchangehotelprice = 0;
      var exchangeclientcomission = 0;
      if (priceConversion) {
        exchangehotelprice = renderPrice(
          totalHotelPrice,
          HotelCheckout.currency
        );
        if (Number(savemarkup.client) !== 0 || savemarkup.client !== "") {
          exchangeclientcomission = renderPrice(
            Number(savemarkup.client),
            HotelCheckout.currency
          );
        }
      } else {
        exchangehotelprice = totalHotelPrice;
        exchangeclientcomission = savemarkup.client;
      }

      const jsonString = {
        lead_title: gestinfo.title,
        lead_first_name: gestinfo.firstname,
        lead_last_name: gestinfo.lastname,
        lead_email: gestinfo.email,
        lead_date_of_birth: gestinfo.dateofbirth,
        lead_country: selectedNationality,
        lead_phone: gestinfo.phonenumber,
        other_title: title,
        other_first_name: firstname,
        other_last_name: lastname,
        other_nationality: nationality,
        child_title: Childtitle,
        child_first_name: Childfirstname,
        child_last_name: Childlastname,
        child_nationality: Childnationality,
        slc_pyment_method: paymentmethod,
        name: "on",
        base_exchange_rate: "1",
        base_currency: "SAR",
        selected_exchange_rate: "1",
        exchange_price: StripePayment,
        admin_markup: HotelCheckout.admin_markup,
        client_markup: HotelCheckout.customer_markup,
        exchange_currency: StripeCurrency,
      };
      const customersearch = {
        token: token,
        b2b_agent_id: UserDetail.id,
        city_name: HotelCheckout.destinationName,
        destination: HotelCheckout.destinationName,
        room_searching: ReduxSearchData.room,
        child_searching: ReduxSearchData.child,
        adult_searching: ReduxSearchData.adult,
        adult_per_room: ReduxSearchData.adult,
        child_per_room: ReduxSearchData.children,
        country_nationality: ReduxSearchData.slc_nationality,
        check_in: ReduxSearchData.check_in,
        check_out: ReduxSearchData.check_out,
        request_all_data: JSON.stringify(ReduxSearchData),
      };
      const phpArray = {
        token: token,
        meal_Details:JSON.stringify(selectedMeals),
        // service_charges: ServiceCharges,
        lead_title: gestinfo.title,
        admin_exchange_currency: "GBP",
        admin_exchange_rate: adminprice,
        admin_exchange_total_markup_price: adminprice,
        lead_first_name: gestinfo.firstname,
        lead_last_name: gestinfo.lastname,
        lead_email: gestinfo.email,
        lead_date_of_birth: gestinfo.dateofbirth,
        lead_country: selectedNationality,
        lead_phone: gestinfo.phonenumber,
        other_title: title,
        other_first_name: firstname,
        other_last_name: lastname,
        other_nationality: nationality,
        slc_pyment_method: paymentmethod,
        payment_details: JSON.stringify(status.data),
        base_exchange_rate: Baseexhangeprice,
        base_currency: "SAR",
        selected_exchange_rate: StripePayment,
        selected_currency: StripeCurrency,
        exchange_price: StripePayment,
        admin_markup: HotelCheckout.admin_markup,
        client_markup: HotelCheckout.customer_markup,
        exchange_currency: StripeCurrency,
        request_data: JSON.stringify(jsonString),
        creditAmount: StripePayment,
        hotel_checkout_select: JSON.stringify(HotelCheckout),
        customer_search_data: JSON.stringify(customersearch),
        payable_price: totalHotelPrice,
        client_commission_amount: savemarkup.client,
        admin_commission_amount: savemarkup.admin,
        total_markup_price: Number(totalHotelPrice) + Number(savemarkup.client),
        currency: HotelCheckout.currency,
        exchange_payable_price: exchangehotelprice,
        exchange_client_commission_amount: exchangeclientcomission,
        exchange_total_markup_price:
          Number(exchangehotelprice) + Number(exchangeclientcomission),
        exchange_rate:
          StripeCurrency === HotelCheckout.currency ? 1 : Baseexhangerate,
        admin_exchange_amount: exchangehotelprice,
        exchange_admin_commission_amount: savemarkup.admin,
        admin_exchange_currency: StripeCurrency,
        admin_exchange_rate:
          StripeCurrency === HotelCheckout.currency ? 1 : Baseexhangerate,
        admin_exchange_total_markup_price:
          Number(exchangehotelprice) + Number(exchangeclientcomission),
      };
      try {
        const response = await axios.post(
          baseurl + "/api/hotels/reservation_Live",
          phpArray,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
              "Access-Control-Allow-Headers":
                "Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale",
            },
          }
        );
        if (response.data.message === "Internal server error") {
          setLoaderLoading(false);
          setIsBooking(false);
          toast.error("Please Search Hotel Again.");
        } else if (response.data.message === "Insufficient allotment") {
          setIsBooking(false);
          setLoaderLoading(false);
          toast.error("Insufficient allotment.");
        } else if (response.data.status === "error") {
          setIsBooking(false);
          setLoaderLoading(false);
          toast.error(response.data.message);
        } else if (response.data.status === "success") {
          // sendEmail(response.data.Invoice_data)
          // sendOwnerMessage(response.data.Invoice_data);
          setIsBooking(false);
          setLoaderLoading(false);
          navigate(`/hotel_voucher/${response.data.Invoice_id}`);
        }
      } catch (error) {
        setIsBooking(false);
        setLoaderLoading(false);
        console.error("Error:", error);
      }
    } else {
      toast.error("Please Agree with Terms and Conditions.");
    }
  };
  const CheckCreditAmount=async()=>{
    var data ={
      token:token,
      b2b_Agent_Id:UserDetail.id,
    }
    try{
      const response= await axios.post(baseurl+'/api/b2b_Check_Credit_Limit_Approved',data);
      if(response.data.status==="success"){
        if(priceConversion===false){
          if(Number(response?.data?.remaining_Amount)>=Number(StripePayment) ){
            setCreditError('')
            return { success: true, data: "" }
          }else{
            setCreditError('Your current credit balance is not enough to cover the total booking price. Please choose another payment option or add more credit through your dashboard to complete your booking.');
            return { success: false, data: "" }
          }
        }else{
          var newPrice=await ConvertCreditAmount(response?.data?.remaining_Amount,response?.data?.customer_Data?.currency_symbol);
          if(Number(newPrice)>=Number(StripePayment) ){
            setCreditError('')
            return { success: true, data: "" }
          }else{
            setCreditError('Your current credit balance is not enough to cover the total booking price. Please choose another payment option or add more credit through your dashboard to complete your booking.');
            return { success: false, data: "" }
          }
        }
      }
    }catch(error){
      console.log(error);
      return { success: false, data: "" }
    }
  }
  const setAdminPrice = (price) => {
    if (currencyRates.length === 0) {
      return;
    }
    var currencyData = JSON.parse(currencyRates);
    if (BaseCurrRates !== null && StripeCurrency === "SAR") {
      const adminrate = BaseCurrRates.conversion_rates["GBP"]; // Use square brackets to access the property
      var admingbpprice = Number(adminrate) * Number(price);
      if (Number(admingbpprice) % 1 !== 0) {
        adminprice = Number(admingbpprice).toFixed(2);
      } else {
        adminprice = admingbpprice;
      }
    } else if (currencyData.length !== 0 && BaseCurrRates !== null) {
      var filterroomcurrdata = currencyData.filter(
        (item) => item.base_code === HotelCheckout.currency
      );
      const gbpprice = filterroomcurrdata[0].conversion_rates["GBP"]; // Use square brackets to access the property
      var baseprice = Number(gbpprice) * Number(price);
      if (Number(baseprice) % 1 !== 0) {
        adminprice = Number(baseprice).toFixed(2);
      } else {
        adminprice = baseprice;
      }
    }
  };
  const setBasePrice = (price) => {
    if (currencyRates.length === 0) {
      return;
    }
    var currencyData = JSON.parse(currencyRates);
    if (StripeCurrency === "SAR") {
      if (Number(price) % 1 !== 0) {
        Baseexhangeprice = Number(price).toFixed(2);
      } else {
        Baseexhangeprice = price;
      }
    } else if (currencyData.length !== 0 && BaseCurrRates !== null) {
      var filterroomcurrdata = currencyData.filter(
        (item) => item.base_code === HotelCheckout.currency
      );
      const gbpprice = filterroomcurrdata[0].conversion_rates["SAR"]; // Use square brackets to access the property
      var baseprice = Number(gbpprice) * Number(price);
      if (Number(baseprice) % 1 !== 0) {
        Baseexhangeprice = Number(baseprice).toFixed(2);
      } else {
        Baseexhangeprice = baseprice;
      }
    }
  };
  const renderPrice = (price, currency) => {
    var selectedcurrency = localStorage.getItem("selectedCurrency");
    var currencyData = JSON.parse(currencyRates);
    if (currencyData.length === 0 || currency === selectedcurrency) {
      if (Number(price) % 1 !== 0) {
        return Number(price).toFixed(2);
      }
      return Number(price);
    } else {
      var filterroomcurrdata = currencyData.filter(
        (item) => item.base_code === currency
      );
      const gbpprice = filterroomcurrdata[0].conversion_rates[selectedcurrency]; // Use square brackets to access the property
      Baseexhangerate = gbpprice;
      var baseprice = Number(gbpprice) * Number(price);
      if (Number(baseprice) % 1 !== 0) {
        return Number(baseprice).toFixed(2);
      }

      return baseprice;
    }
  };
  const ConvertCreditAmount =async (price, currency) => {
    var selectedcurrency = localStorage.getItem("selectedCurrency");
    var currencyData = JSON.parse(currencyRates);
    if (currencyData.length === 0 || currency === selectedcurrency) {
      if (Number(price) % 1 !== 0) {
        return Number(price).toFixed(2);
      }
      return Number(price);
    } else {
      var filterroomcurrdata = currencyData.filter(
        (item) => item.base_code === currency
      );
      if(filterroomcurrdata.length !==0){
      const gbpprice = filterroomcurrdata[0].conversion_rates[selectedcurrency]; // Use square brackets to access the property
      Baseexhangerate = gbpprice;
      var baseprice = Number(gbpprice) * Number(price);
      if (Number(baseprice) % 1 !== 0) {
        return Number(baseprice).toFixed(2);
      }
      return baseprice;
    }else{
      var CurrRates=await NewCurrRates(currency);
      if(CurrRates !==false){
        var conversionrate=CurrRates?.conversion_rates[StripeCurrency];
        var newprice=Number(conversionrate)*Number(price)
        if (Number(newprice) % 1 !== 0) {
          return Number(newprice).toFixed(2);
        }
        return newprice;
      }else{
        return Number(price)
      }
    }
    }
  };
  const NewCurrRates = async (currency) => {
    const token = CurrencyConverter();
    const config = {
      method: "get",
      url: `https://v6.exchangerate-api.com/v6/${token}/latest/${currency}`, // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {},
    };
  
    try {
      const response = await axios.request(config);
      return response.data; // This will return the response data
    } catch (error) {
      console.error(error);
      return false; // Return false in case of an error
    }
  };
  
  const handleMealCheckboxChange = (mealId, mealPrice,name) => {
    setSelectedMeals((prev) => {
      // Check if the meal is already selected
      const mealIndex = prev.findIndex((meal) => meal.id === mealId);
      if (mealIndex >= 0) {
        // Meal is already selected, so remove it
        const newSelectedMeals = prev.filter((meal) => meal.id !== mealId);
        updateRoomPrice(newSelectedMeals); // Update room price after removing meal
        return newSelectedMeals;
      } else {
        // Add new meal with default quantity and totalPrice
        const newMeal = {
          id: mealId,
          quantity: totalAdults,
          name:name,
          totalPrice: Number(mealPrice)* Number(totalAdults), // Default total price with quantity 1
        };
        const newSelectedMeals = [...prev, newMeal];
        updateRoomPrice(newSelectedMeals); // Update room price after adding meal
        return newSelectedMeals;
      }
    });
  };
  // const handleMealAdultCountChange = (mealId, mealPrice, quantity) => {
  //   setSelectedMeals((prev) => {
  //     const updatedMeals = prev.map((meal) =>
  //       meal.id === mealId
  //         ? {
  //             ...meal,
  //             quantity,
  //             totalPrice: Number(mealPrice) * Number(quantity),
  //           }
  //         : meal
  //     );
  //     updateRoomPrice(updatedMeals); // Update room price after changing quantity
  //     return updatedMeals;
  //   });
  // };
  const updateRoomPrice = (mealist) => {
    const totalPrice = mealist.reduce((sum, meal) => sum + Number(meal.totalPrice), 0);
    setSavemarkup((prev) => ({
      ...prev,
      totalmealprice: totalPrice,
    }));
  };
  return (
    <Layout>
      {loaderLoading && <BookingLoader message={message} />}
      <ToastContainer />
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-7 col-sm-6 col-12">
            <h4>Main Guest Information</h4>
            <div className="row">
              <div class=" mt-2 col-md-6">
                <label className="fw-bold">Title</label>
                <select
                  id="inputState"
                  name="title"
                  value={gestinfo.title}
                  onChange={handlegestchange}
                  class="form-select js-choice"
                >
                  <option selected>Select Title</option>
                  <option value="MR">Mr</option>
                  <option value="MRS">Mrs</option>
                </select>
              </div>
              <div class="form-group mt-2 col-md-6">
                <label className="fw-bold">First Name</label>
                <input
                  type="text"
                  class="form-control "
                  value={gestinfo.firstname}
                  name="firstname"
                  onChange={handlegestchange}
                  placeholder="First Name"
                />
              </div>

              <div class="form-group mt-2 col-md-6">
                <label className="fw-bold">Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  value={gestinfo.lastname}
                  name="lastname"
                  onChange={handlegestchange}
                  placeholder="Last Name"
                />
              </div>
              <div class="form-group mt-2 col-md-6">
                <label className="fw-bold">Email</label>
                <input
                  type="email"
                  class="form-control "
                  value={gestinfo.email}
                  name="email"
                  onChange={handlegestchange}
                  placeholder="Email"
                />
              </div>

              <div class="form-group field-icon-wrap mt-2 col-md-6">
                <label className="fw-bold">Country Code</label>
                <Select
                  options={countryList}
                  isSearchable={true}
                  className=""
                  onChange={handleCountryChange}
                  value={selectedCountry}
                  getOptionLabel={(option) => (
                    <div>
                      <img
                        src={option.flag}
                        alt={option.label}
                        style={{ width: "20px", marginRight: "8px" }}
                      />
                      {option.label} ({option.phoneCode})
                    </div>
                  )}
                  getOptionValue={(option) => option.value}
                />
              </div>

              <div class="form-group mt-2 col-md-6">
                <label className="fw-bold">Phone Number</label>
                <input
                  type="text"
                  class="form-control "
                  value={gestinfo.phonenumber}
                  name="phonenumber"
                  onChange={handlegestchange}
                  placeholder="Phone Number"
                />
              </div>
            </div>
            {(adultCount - 1 !== 0 || childCount !== 0) && (
              <div class="">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={ShowOtherGuestForm}
                  class="card-header border-bottom py-2 mt-2 d-flex justify-content-between"
                >
                  <h5 class="card-title mb-0">Other Guest Details </h5>
                  <span className="mt-auto mb-auto">
                    <FontAwesomeIcon icon={faAngleDown} />
                  </span>
                </div>
                {otherGuestData && (
                  <div class="card-body p-4">
                    <div className="row">
                      <div>
                        {adultsArray.map((_, index) => (
                          <div key={index} className="row pt-2">
                            <h5 className="mb-2">Guest #{index + 2}</h5>
                            <div className="col-md-4 mt-2">
                              <label className="fw-bold">Title</label>
                              <select
                                value={guests.title}
                                id={`inputState_${index}`}
                                name="title"
                                onChange={(e) => otherGuestInfo(e, index, 1)}
                                class="form-control mt-2 form-select select-styling"
                              >
                                <option selected>Select Title</option>
                                <option value="MR">Mr.</option>
                                <option value="MRS">Mrs.</option>
                              </select>
                            </div>
                            <div className="col-md-4 mt-2">
                              <label className="fw-bold">First Name</label>
                              <input
                                type="text"
                                class="form-control mt-2"
                                value={guests.firstname}
                                name="firstname"
                                placeholder="First Name"
                                onChange={(e) =>
                                  otherGuestFirstName(e, index, 1)
                                }
                              />
                            </div>
                            <div className="col-md-4 mt-2">
                              <label className="fw-bold">Last Name</label>
                              <input
                                type="text"
                                class="form-control mt-2"
                                value={guests.lastname}
                                name="lastname"
                                placeholder="Last Name"
                                onChange={(e) =>
                                  otherGuestLastName(e, index, 1)
                                }
                              />
                            </div>
                          </div>
                        ))}
                        {childsArray.map((_, index) => (
                          <div key={index} className="row pt-2">
                            <h5 className="mb-2">Child #{index + 1}</h5>
                            <div className="col-md-4 mt-2">
                              <select
                                value={guests.title}
                                id={`inputState_${index}`}
                                name="title"
                                onChange={(e) => otherGuestInfo(e, index, 2)}
                                class="form-control form-select select-styling"
                              >
                                <option selected>Select Title</option>
                                <option value="MSTR">Mr.</option>
                                <option value="MSTRS">Mrs.</option>
                              </select>
                            </div>
                            <div className="col-md-4 mt-2">
                              <input
                                type="text"
                                class="form-control"
                                name="firstname"
                                placeholder="First Name"
                                onChange={(e) =>
                                  otherGuestFirstName(e, index, 2)
                                }
                              />
                            </div>
                            <div className="col-md-4 mt-2">
                              <input
                                type="text"
                                class="form-control"
                                name="firstname"
                                placeholder="Last Name"
                                onChange={(e) =>
                                  otherGuestLastName(e, index, 2)
                                }
                              />
                            </div>
                           
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
             {HotelCheckout?.hotel_provider === "Custome_hotel" &&(
              <>
              {mealTypes.length !==0 && (
            <div>
              <h5 className="mt-3">Additional Meal</h5>
              {mealTypesLoader ? (
                <div>
                  <Placeholder.Paragraph rows={4} />
                </div>
              ) : (
                <table class="table mt-2">
                  <thead>
                    <tr className="meal-table-heading ">
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Price</th>
                      <th scope="col">Adult</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mealTypes.map((item, index) => {
                      const selectedMeal = selectedMeals.find((meal) => meal.id === item.id);
                      const quantity = totalAdults;
                      const totalPrice = Number(item.meal_Price) * Number(totalAdults);
                      var totalmealprice=0
                      if (Number(totalPrice) % 1 !== 0) {
                        totalmealprice = Number(totalPrice).toFixed(2);
                      } else {
                        totalmealprice = totalPrice;
                      }
                      return(
                      <tr key={index} >
                        <th>
                          <Checkbox
                           checked={!!selectedMeal}
                            onChange={() =>
                              handleMealCheckboxChange(item.id, item.meal_Price,item.meal_Name)
                            }
                          />
                        </th>
                        <td className="align-middle">{item.meal_Name}</td>
                        <td className="align-middle">
                        {priceConversion == false
                      ? HotelCheckout.currency
                      : Object.keys(SelectedCurrRates).length === 0
                      ? BaseCurrRates.base_code
                      : SelectedCurrRates.base_code}{" "}
                          {priceConversion == false
                      ? Number(item.meal_Price)
                      : renderPrice(Number(item.meal_Price), HotelCheckout.currency)}
                          </td>
                        <td className="align-middle">
                          {totalAdults}
                          {/* <select
                            className="px-1 w-50 py-0"
                            value={quantity}
                            onChange={(e) =>
                              handleMealAdultCountChange(
                                item.id,
                                item.meal_Price,
                                parseInt(e.target.value, 10)
                              )
                            }
                            disabled={!selectedMeal} // Disable if not selected
                          >
                            {Array.from({ length: totalAdults }, (_, i) => (
                              <option key={i} value={i + 1}>
                                {i + 1}
                              </option>
                            ))}
                          </select> */}
                        </td>
                        <td className="align-middle">
                        {priceConversion == false
                      ? HotelCheckout.currency
                      : Object.keys(SelectedCurrRates).length === 0
                      ? BaseCurrRates.base_code
                      : SelectedCurrRates.base_code}{" "}
                           {priceConversion == false
                      ? Number(totalmealprice)
                      : renderPrice(Number(totalPrice), HotelCheckout.currency)}
                          </td>
                      </tr>
                    )})}
                  </tbody>
                </table>
              )}   
            </div>
             )}
              </>
            )}
            <h5 className="mt-3">Payment Details</h5>
            {HotelCheckout?.rooms_list[0].request_type === "1" ? (
              <div class="col-12">
                <div
                  class="alert alert-success alert-dismissible fade show my-3"
                  role="alert"
                >
                  <div class="align-items-center ">
                    <h6 class="alert-heading mb-0">
                      Please note that availability is subject to confirmation.
                      Our team will check the availability and get back to you
                      shortly with the final confirmation.
                    </h6>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <ul class="hotel-room room_tabs payment_options" role="tablist">
                  <li
                    onClick={() => setSelectedValue("cardpayment")}
                    role="bravo_room "
                    className={` ${
                      selectedValue === "cardpayment" ? "select_room" : ""
                    }`}
                  >
                    <FontAwesomeIcon className="me-4" icon={faCreditCardAlt} />
                    Card
                  </li>
                  {/* {HotelCheckout.hotel_provider !== "Custome_hotel" &&
                    HotelCheckout.hotel_provider !== "custom_hotel_provider" &&
                    new Date(
                      HotelCheckout?.rooms_list[0].cancliation_policy_arr[0].from_date
                    ) > todayDate && (
                      <li
                        role="bravo_room "
                        onClick={() => setSelectedValue("nopayment")}
                        className={` ${
                          selectedValue === "nopayment" ? "select_room" : ""
                        }`}
                      >
                        <FontAwesomeIcon className="me-4" icon={faDollar} />
                        No Pre Payment
                      </li>
                    )} */}
                  {HotelCheckout?.hotel_provider === "Custome_hotel" &&
                    moment(HotelCheckout?.checkIn).diff(moment(), "hours") >
                      72 && (
                      <li
                        role="bravo_room "
                        onClick={() => setSelectedValue("bankpayment")}
                        className={` ${
                          selectedValue === "bankpayment" ? "select_room" : ""
                        }`}
                      >
                        <FontAwesomeIcon className="me-4" icon={faBank} />
                        Bank Transfer in 24 Hours
                      </li>
                    )}
                  {/* {HotelCheckout?.hotel_provider === "Custome_hotel" && (
                    <li
                      role="bravo_room "
                      onClick={() => setSelectedValue("creditpayment")}
                      className={` ${
                        selectedValue === "creditpayment" ? "select_room" : ""
                      }`}
                    >
                      <FontAwesomeIcon className="me-4" icon={faDollar} />
                      Pay With Credit
                    </li>
                  )} */}
                </ul>
                {selectedValue === "cardpayment" && (
                  <div className="row">
                    <div class="mt-2 col-md-12">
                      <label htmlFor="cardNumber" className="form-label">
                        Card number
                      </label>
                      <CardNumberElement
                        className="form-control"
                        id="cardNumber"
                      />
                    </div>
                    <div class=" mt-2 col-md-6">
                      <label htmlFor="cardNumber" className="form-label">
                        Card Holder Name
                      </label>
                      <input
                        type="text"
                        style={{ lineHeight: "0px" }}
                        class="form-control"
                        name="name"
                        value={cardHolderName}
                        onChange={handlecardnamechange}
                        placeholder="Name"
                      />
                    </div>
                    <div class="mt-2 col-md-6">
                      <label htmlFor="cardNumber" className="form-label">
                        Expiration date
                      </label>
                      <CardExpiryElement
                        className="form-control"
                        id="cardNumber"
                      />
                    </div>
                    <div class="mt-2 col-md-6">
                      <label htmlFor="cvc" className="form-label">
                        CVC
                      </label>
                      <CardCvcElement className="form-control" id="cvc" />
                    </div>
                  </div>
                )}
                {selectedValue === "nopayment" && (
                  <div className="mt-2">
                    <h6>
                      Please ensure that payment is made before the cancellation
                      date to avoid your booking being canceled.
                    </h6>
                  </div>
                )}
                {selectedValue === "bankpayment" && (
                  <div className="mt-2">
                    <p className="bank-payment-msg">
                      Please copy the bank details and make the payment within
                      24 hours. After payment, submit the transaction details
                      through the agent dashboard for verification. Your booking
                      will be confirmed once the payment is verified.
                    </p>

                    <div
                      style={{ flexWrap: "wrap" }}
                      className="d-flex mt-2 justify-content-between align-items-center"
                    >
                      <h6>Account Holder Name:</h6>
                      <h6> ABDULLAH ALSUBAEE FOR HOLIDAYS</h6>
                    </div>
                    <div
                      style={{ flexWrap: "wrap" }}
                      className="d-flex mt-2 justify-content-between align-items-center"
                    >
                      <h6>Account Number:</h6>
                      <h6>32672680000105</h6>
                    </div>
                    <div
                      style={{ flexWrap: "wrap" }}
                      className="d-flex mt-2 justify-content-between align-items-center"
                    >
                      <h6>IBAN Number:</h6>
                      <h6>SA501000032672680000105</h6>
                    </div>
                    <div
                      style={{ flexWrap: "wrap" }}
                      className="d-flex mt-2 justify-content-between align-items-center"
                    >
                      <h6>Swift No:</h6>
                      <h6>NCBKSAJE</h6>
                    </div>
                  </div>
                )}
                {/* {selectedValue === "creditpayment" && (
                  <div className="mt-4">
                    <p className="bank-payment-msg">
                      You are about to book using your available credit limit.
                      Please ensure your credit limit covers the total booking
                      cost.
                    </p>
                  </div>
                )} */}
              </div>
            )}
            <div className="row">
              {error && (
                <div
                  style={{ color: "red" }}
                  className="error mt-2"
                  role="alert"
                >
                  {error}
                </div>
              )}
               {creditError && (
                <div
                  style={{ color: "red" }}
                  className="error mt-2"
                  role="alert"
                >
                  {creditError}
                </div>
              )}
              <div className="mt-5">
                <div class="col-12 ">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      checked={isChecked} // Set the checked state
                      onChange={handleCheckboxContinue}
                      id="invalidCheck"
                      required
                    />
                    <label class="form-check-label" for="invalidCheck">
                      By continuing, you agree to the{" "}
                      <span style={{ color: "red" }}>
                        {" "}
                        Terms and conditions
                      </span>
                    </label>
                  </div>
                </div>
                <button
                  onClick={confirmbooking}
                  className="theme-btn-1 ps-4 pe-4 py-2"
                >
                  Pay Now
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-5 col-sm-6 col-12">
            <div className="checkout-summary">
              <h4>Booking Summary</h4>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mt-3"> {HotelCheckout?.hotel_name}</h5>
                <p>
                  {HotelCheckout?.stars_rating === ""
                    ? ""
                    : Array(HotelCheckout?.stars_rating)
                        .fill(0)
                        .map((_, index) => (
                          <FontAwesomeIcon key={index} icon={faStar} />
                        ))}
                </p>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <p>Check in</p>
                <p> {moment(HotelCheckout?.checkIn).format("ll")}</p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p>Check out</p>
                <p> {moment(HotelCheckout?.checkOut).format("ll")}</p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p>Duration</p>
                <p> {daysBetween} {Number(daysBetween)===1?'Night':'Nights'}</p>
              </div>
              <div className="d-flex px-2 my-2 py-1 booking-data justify-content-between fw-bold align-items-center">
                <p>Number of Rooms</p>
                <p className="mt-0">{totalRoomsCount}</p>
              </div>
              <div className="d-flex px-2 my-2 py-1 booking-data justify-content-between fw-bold align-items-center">
                <p>Number of Adults</p>
                <p className="mt-0">{ReduxSearchData.adult}</p>
              </div>
              <div className="d-flex px-2 my-2 py-1 booking-data justify-content-between fw-bold align-items-center">
                <p>Number of Childs</p>
                <p className="mt-0">{ReduxSearchData.child}</p>
              </div>
              {HotelCheckout?.rooms_list.map((item, index) => (
                <div key={index}>
                  <h5 className="mt-3">
                    Room {index + 1}: {item.room_name}
                  </h5>
                  {item.request_type === "1" && (
                    <div className=" mt-2 room-request">
                      <h6>Room on Request</h6>
                    </div>
                  )}
                  <div className="d-flex justify-content-between align-items-center">
                    <p>Meal Type</p>
                    <p>{item.board_id}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p>Adult</p>
                    <p>{item.adults}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p>Child</p>
                    <p>{item.childs}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p>No of rooms</p>
                    <p>{item.selected_qty}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p>Price</p>
                    <p>
                      {priceConversion == false
                        ? HotelCheckout.currency
                        : Object.keys(SelectedCurrRates).length === 0
                        ? BaseCurrRates.base_code
                        : SelectedCurrRates.base_code}{" "}
                      {priceConversion == false
                        ? Number(
                            calculateMarkup(
                              item.room_Promotions_Exist === "1"
                                ? item.rooms_total_price_Promotion
                                : item.rooms_total_price
                            )
                          )
                        : renderPrice(
                            Number(calculateMarkup(item.rooms_total_price)),
                            HotelCheckout.currency
                          )}
                    </p>
                  </div>
                  {item.cancliation_policy_arr &&
                  item.cancliation_policy_arr.length > 0 ? (
                    <>
                      {HotelCheckout.hotel_provider === "travelenda" &&
                      new Date(
                        item.cancliation_policy_arr.find(
                          (item) => item.CancellationDeadline
                        )?.CancellationDeadline
                      ) < todayDate ? (
                        <p className="text-danger text-center mb-2 mt-2">
                          Non-Refundable
                        </p>
                      ) : (
                        <>
                          {item.cancliation_policy_arr.map((policy, index) => {
                            const policyDate = new Date(policy.from_date);
                            const isFutureDate = policyDate > todayDate;
                            const isPriceMatch =
                              parseFloat(item.rooms_total_price) ===
                              parseFloat(policy.amount);

                            if (isFutureDate && isPriceMatch) {
                              return (
                                <p
                                  key={index}
                                  className="text-success text-center mb-2 mt-2"
                                >
                                  Free Cancellation till{" "}
                                  {moment(policyDate)
                                    .subtract(1, "days")
                                    .utc()
                                    .format("ll")}
                                </p>
                              );
                            } else if (isFutureDate || isPriceMatch) {
                              return (
                                <p
                                  key={index}
                                  className="text-warning text-center mb-2 mt-2"
                                >
                                  {policy.type === "Amount" ||
                                  policy.type === "Fix Amount" ? (
                                    <>
                                      {priceConversion == false
                                        ? HotelCheckout.currency
                                        : Object.keys(SelectedCurrRates)
                                            .length === 0
                                        ? BaseCurrRates.base_code
                                        : SelectedCurrRates.base_code}{" "}
                                      {priceConversion == false
                                        ? Number(calculateMarkup(policy.amount))
                                        : renderPrice(
                                            Number(
                                              calculateMarkup(policy.amount)
                                            ),
                                            HotelCheckout.currency
                                          )}{" "}
                                      will be charged from{" "}
                                      {moment(policyDate).utc().format("ll")}
                                    </>
                                  ) : (
                                    <>
                                      {policy.amount} {policy.type} will be
                                      charged from{" "}
                                      {moment(policyDate).utc().format("ll")}
                                    </>
                                  )}
                                </p>
                              );
                            } else if (
                              index ===
                              item.cancliation_policy_arr.length - 1
                            ) {
                              return (
                                <p
                                  key={index}
                                  className="text-danger text-center mb-2 mt-2"
                                >
                                  Non-Refundable
                                </p>
                              );
                            }
                            return null;
                          })}
                        </>
                      )}
                    </>
                  ) : (
                    <p className="text-danger text-center mb-2 mt-2">
                      Non-Refundable
                    </p>
                  )}
                </div>
              ))}
              <div className="mg-top-4">
                <div className="d-flex justify-content-between align-items-center">
                  <p>Sub Total</p>
                  <p>
                    {" "}
                    {priceConversion == false
                      ? HotelCheckout.currency
                      : Object.keys(SelectedCurrRates).length === 0
                      ? BaseCurrRates.base_code
                      : SelectedCurrRates.base_code}{" "}
                    {priceConversion == false
                      ? Number(savemarkup.final).toFixed(2)
                      : renderPrice(Number(savemarkup.final), HotelCheckout.currency)}
                  </p>
                </div>
                {selectedMeals.length !==0 && (
                <div className="d-flex justify-content-between align-items-center">
                  <p>Additional Meal</p>
                  <p>
                    {" "}
                    {priceConversion == false
                      ? HotelCheckout.currency
                      : Object.keys(SelectedCurrRates).length === 0
                      ? BaseCurrRates.base_code
                      : SelectedCurrRates.base_code}{" "}
                    {priceConversion == false
                      ? savemarkup.totalmealprice
                      : renderPrice(
                          savemarkup.totalmealprice,
                          HotelCheckout.currency
                        )}
                  </p>
                </div>
                )}
                <div className="d-flex px-2 mt-4  mb-2 py-1 booking-data justify-content-between fw-bold align-items-center">
                  <p>Total</p>
                  <p className="mt-0">
                    {priceConversion == false
                      ? (StripeCurrency = HotelCheckout.currency)
                      : (StripeCurrency =
                          Object.keys(SelectedCurrRates).length === 0
                            ? BaseCurrRates.base_code
                            : SelectedCurrRates.base_code)}{" "}
                    {priceConversion == false
                      ? (StripePayment = (Number(savemarkup.final)+Number(savemarkup.totalmealprice)).toFixed(2))
                      : (StripePayment = Number(
                          renderPrice((Number(savemarkup.final)+Number(savemarkup.totalmealprice)), HotelCheckout.currency)
                        ).toFixed(2))}
                  </p>
                </div>
                <div className="text-center">
                  <small>Vat and Taxes included</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default HotelCheckout;
