import React, { useEffect, useState } from "react";
import logo from "../../Assets/Images/logo.png";
import { NavLink } from "react-router-dom";
import { Steps } from "rsuite";
import OtpInput from "react-otp-input";
import axios from "axios";
import { ApiEndPoint, ApiToken } from "../../Data/Api";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../Components/Loader";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEyeSlash,
  faEye,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip, Whisper } from "rsuite";
import PasswordStrengthBar from "react-password-strength-bar";
function ForgetPassword() {
  const baseurl = ApiEndPoint();
  const token = ApiToken();
  const navigate = useNavigate();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [step, setStep] = useState(0); // Step management
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setError] = useState({});
  useEffect(() => {
    const stepnum = localStorage.getItem("ForgetStepCount");
    if (stepnum) {
      setStep(parseInt(stepnum));
    }
  }, []);

  const SendOtp = async (event) => {
    event.preventDefault();
    var newerror={};
    if (email === "") {
      // toast.error("Please enter email.");
      newerror.email='Please enter email.'
      setError(newerror);
      return;
    } else if (!isValidEmail(email)) {
      newerror.email='Please enter a valid email.'
      setError(newerror);
      return;
    }
    setIsLoading(true);
    const data = {
      token: token,
      email: email,
    };
    try {
      const response = await axios.post(baseurl + "/api/forgot_Password", data);
      setIsLoading(false);
      if (response.data.status === "success") {
        toast.success("We've sent a OTP to your email. Please check your inbox and enter the OTP to proceed.")
        setStep(step + 1);
        localStorage.setItem("ForgetStepCount", step + 1);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const VerifyOtp = async (event) => {
    event.preventDefault();
    var newerror={};
    if (otp === "") {
      // toast.error("Please enter OTP.");
      newerror.otp='Please enter OTP.'
      setError(newerror);
      return;
    } else if (otp.length !== 5) {
      // toast.error("Please enter full otp.");
      newerror.otp='Please enter full otp.'
      setError(newerror);
      return;
    }
    setIsLoading(true);
    const data = {
      token: token,
      otp: otp,
    };
    try {
      const response = await axios.post(
        baseurl + "/api/verify_Otp_B2B_Agent",
        data
      );
      setIsLoading(false);
      if (response.data.status === "success") {
        localStorage.setItem("otpData", otp);
        setStep(step + 1);
        localStorage.setItem("ForgetStepCount", step + 1);
      } else {
        // toast.error(response.data.message);
        newerror.otp=response.data.message
        setError(newerror);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const SubmitNewPAssword = async (event) => {
    event.preventDefault();
    if (newPassword === "") {
      toast.error("Please enter new password.");
      return;
    }else if (validatePassword(newPassword)) {
      toast.error(
        "Password must be at least 11 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      return;
    } else if (confirmPassword === "") {
      toast.error("Please enter confirm password.");
      return;
    } else if (newPassword !== confirmPassword) {
      toast.error("Password and confirm password does not match.");
      return;
    }
    const otpdata = localStorage.getItem("otpData");
    setIsLoading(true);
    const data = {
      token: token,
      otp: otpdata,
      password: newPassword,
    };
    try {
      const response = await axios.post(baseurl + "/api/reset_Password", data);
      if (response.data.status === "success") {
        toast.success(response.data.message);
        localStorage.removeItem("otpData");
        setStep(0);
        localStorage.setItem("ForgetStepCount", 0);
        setTimeout(() => {
          setIsLoading(false);
          navigate("/login"); // Navigate to the login page
        }, 2000);
      } else {
        setIsLoading(false);
        toast.error(response.data.message);
        setStep(0);
        localStorage.setItem("ForgetStepCount", 0);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_]).{11,}$/;
    if (!regex.test(password)) {
      return true;
    } else {
      return false;
    }
  };
  function isValidEmail(email) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }
  const handleemailchange = (event) => {
    setEmail(event.target.value);
  };
  const handleChangeotp = (otpValue) => {
    setOtp(otpValue);
  };
  const handlenewpasschange = (event) => {
    setNewPassword(event.target.value);
  };
  const handleconfirmpasschange = (event) => {
    setConfirmPassword(event.target.value);
  };
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const ChangeEmail=()=>{
    setStep(0);
  }
  return (
    <div className="authentication-bg ">
      <ToastContainer />
      {isLoading && <Loader />}
      <div className="container">
        <NavLink to='/'>
        <img src={logo} className="login-logo mt-3" />
        </NavLink>
      </div>
      <div class="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5 position-relative">
        <div class="container">
          <Steps current={step}>
            <Steps.Item title="Enter Email" />
            <Steps.Item title="Enter OTP" />
            <Steps.Item title="New Password" />
          </Steps>
          <div class="row justify-content-center mt-4">
            <div class="col-xxl-4 col-lg-5">
              <div class="card">
                {step === 0 && (
                  <div class="card-body p-4">
                    <div class="text-center w-75 m-auto">
                      <h4 class="text-dark-50 text-center mt-0 fw-bold">
                        Reset Password
                      </h4>
                      <p class="text-muted mb-4">
                        Enter your email address, and we'll send you an OTP to
                        verify your identity and proceed with resetting your
                        password.
                      </p>
                    </div>

                    <form onSubmit={SendOtp}>
                      <div class="mb-3">
                        <label for="emailaddress" class="form-label">
                          Email address
                        </label>
                        <input
                          class="form-control"
                          type="email"
                          id="emailaddress"
                          value={email}
                          onChange={handleemailchange}
                          required=""
                          placeholder="Enter your email"
                        />
                        {errors.email && <span className="text-danger">{errors.email}</span>}

                      </div>
                      <div class="mb-0 text-center">
                        <button class="btn btn-primary" type="submit">
                          Send OTP
                        </button>
                      </div>
                    </form>
                  </div>
                )}
                {step === 1 && (
                  <div class="card-body p-4">
                    <div class="text-center w-75 m-auto">
                      <h4 class="text-dark-50 text-center mt-0 fw-bold">
                        Enter OTP
                      </h4>
                      <p class="text-muted mb-4">
                        Please enter the OTP sent to your registered email to
                        proceed.
                      </p>
                    </div>

                    <form onSubmit={VerifyOtp}>
                      <div class="mb-3 otp-field">
                        <OtpInput
                          value={otp}
                          onChange={handleChangeotp}
                          renderInput={(props) => <input {...props} />}
                          numInputs={5} // Number of OTP input fields
                          renderSeparator={<span>-</span>} // Separator between fields
                          isInputNum={true} // Set to true if you want numeric inputs
                          shouldAutoFocus={true} // Autofocus on first input field
                          inputStyle={{
                            width: "2.5rem",
                            height: "2.5rem",
                            margin: "0 0.5rem",
                            fontSize: "1.3rem",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                          }}
                        />
                        {errors.otp && <span className="text-danger">{errors.otp}</span>}

                      </div>

                      <div class="mb-0 text-center">
                        <p onClick={ChangeEmail} className="text-primary cursor-pointer">Change Email?</p>
                        <button class="btn btn-primary" type="submit">
                          Verify OTP
                        </button>
                      </div>
                    </form>
                  </div>
                )}
                {step === 2 && (
                  <div class="card-body p-4">
                    <div class="text-center w-75 m-auto">
                      <h4 class="text-dark-50 text-center mt-0 fw-bold">
                        Enter Password
                      </h4>
                      <p class="text-muted mb-4">
                        Please enter your new password and confirm it below.
                      </p>
                    </div>

                    <form onSubmit={SubmitNewPAssword}>
                      <div class="mb-3">
                        <label for="emailaddress" class="form-label">
                          Enter Password
                          <Whisper
                            placement="top"
                            controlId="control-id-hover"
                            trigger="hover"
                            speaker={
                              <Tooltip>
                                <ul>
                                  <li> Lowercase & Uppercase</li>
                                  <li>Number (0-9)</li>
                                  <li>Special Character (!@#$%^&*)</li>
                                  <li>Atleast 11 Character</li>
                                </ul>
                              </Tooltip>
                            }
                          >
                            <FontAwesomeIcon
                              className="ms-2"
                              icon={faInfoCircle}
                            />
                          </Whisper>
                        </label>
                        <div className="input-group input-group-merge ">
                          <input
                            class="form-control"
                            type={showNewPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            value={newPassword}
                            onChange={handlenewpasschange}
                            required=""
                            placeholder="Enter New Password"
                          />
                          <span
                            className="input-group-text"
                            onClick={toggleNewPasswordVisibility}
                            style={{ cursor: "pointer", marginTop: "auto" }}
                          >
                            <FontAwesomeIcon
                              icon={showNewPassword ? faEyeSlash : faEye}
                            />
                          </span>
                        </div>
                        <PasswordStrengthBar
                          className="mt-2"
                          password={newPassword}
                        />
                      </div>
                      <div class="mb-3">
                        <label for="emailaddress" class="form-label">
                          Enter Confirm Password
                        </label>
                        <div className="input-group input-group-merge ">
                          <input
                            class="form-control"
                            type={showConfirmPassword ? "text" : "password"}
                            id="confirm_password"
                            value={confirmPassword}
                            onChange={handleconfirmpasschange}
                            required=""
                            placeholder="Enter Confirm Password"
                          />
                          <span
                            className="input-group-text"
                            onClick={toggleConfirmPasswordVisibility}
                            style={{ cursor: "pointer", marginTop: "auto" }}
                          >
                            <FontAwesomeIcon
                              icon={showConfirmPassword ? faEyeSlash : faEye}
                            />
                          </span>
                        </div>
                      </div>
                      <div class="mb-0 text-center">
                        <button class="btn btn-primary" type="submit">
                          Confirm
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </div>

              <div class="row mt-3">
                <div class="col-12 text-center">
                  <p class="text-muted">
                    Back to{" "}
                    <a class="text-muted ms-1">
                      <NavLink to="/login">
                        <b>Log In</b>
                      </NavLink>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
