export function ApiToken(){
    // RsBl0HcA9IsWWAVagvwbb61qo7sj2kVZCgytDt4z-mUTBaJ0x8Ngsm4yj826O48W6HLc3Sv3Wl78S7C53i1JuTPnZXOxNcMBRBPff-V92s9ByaJowvJ6cINFAjbBwT5-eTTNjDNjqamT41wCPfG1GjpnpIZVE7y5PNngJvgZfCjwptsYrAzwPILViyhpprje3Fn2ztwc2DC-z8eSNhhlcGBMRinLIRaUclHtOdOuiPhIfK1DUkB3HFKXNH5X725xYgL631hkwB8OgnPxglxIQRLZR6BP9zVM3KfZQBnUJHZoVw36-m8NzAVeXrFhriRDUtvgVG2YPLSZiKkCDijgxpaNCnGIfX7Wa004xUltHS1gO9ITeEqhqsadfC9U-kzokxdfFi94I1oF3QQhWYU8G5-pb7vGrlknlKuRGfbxM24dPr1Cer0AhFn1CMuzoznBh2M7PeoSi-a0segLaiLThydY3svhUvRWTQgwsebO8aLNmCypLLbPTjXr68Db
    // r9fdvwRyF35JUnD6xXdRiDELANYjtfASzPmyGol4-1PN461EY50LbXcqkfEfISsOJDrnFDJbuMzPuxTz37zFWGWBVemQGhi2SYLrr-MZ75vJSAiV73z94UOVrDz5P6R-0KjFqr9XR6P2857snQbcKTUn9YNqjBOQQIkXENeO7tmjxdTJs2KUVoXqo6fFyT9TTq99eKe288N-wyanZXxOsfABWPjtSom2oKLVz6vJnn1WeQwHSp7VnzPUqq53rn80eFXNBSMIiEXBdDmlsokRYSa0evDrQKluhnIzMYkRiazxtnkb-z5Xj0tQReTTHsLz1sgnit2mRGGzP4EIdBK8TiLuEN7GD1kmOT3CMreL7ELrI4yxmEbnYyflICtG-ySk3aZkk8iM9mRZlA7CS10Zuj-C0HEBOFW8vMzy4Eq2CET5WN62S1xe0HPAOrDVwO6jDvVpKEMwm-NiyyjkU8oTTlgYpN77pXtfFjKPTF0julnAMC6cPzxZOGBIkRv0
    return "RsBl0HcA9IsWWAVagvwbb61qo7sj2kVZCgytDt4z-mUTBaJ0x8Ngsm4yj826O48W6HLc3Sv3Wl78S7C53i1JuTPnZXOxNcMBRBPff-V92s9ByaJowvJ6cINFAjbBwT5-eTTNjDNjqamT41wCPfG1GjpnpIZVE7y5PNngJvgZfCjwptsYrAzwPILViyhpprje3Fn2ztwc2DC-z8eSNhhlcGBMRinLIRaUclHtOdOuiPhIfK1DUkB3HFKXNH5X725xYgL631hkwB8OgnPxglxIQRLZR6BP9zVM3KfZQBnUJHZoVw36-m8NzAVeXrFhriRDUtvgVG2YPLSZiKkCDijgxpaNCnGIfX7Wa004xUltHS1gO9ITeEqhqsadfC9U-kzokxdfFi94I1oF3QQhWYU8G5-pb7vGrlknlKuRGfbxM24dPr1Cer0AhFn1CMuzoznBh2M7PeoSi-a0segLaiLThydY3svhUvRWTQgwsebO8aLNmCypLLbPTjXr68Db"
};

export function DomainName(){
    return 'https://admin.alsubaee.com/'
};
export function ApiEndPoint(){
    return 'https://api.synchtravel.com' 
};
export function CurrencyConverter(){
    return 'b8d4b285d48d1e690388e999'
};
export function StripeClientSecret(){
    // return 'sk_test_51PyDsJBAX2F2eU1K16tRjqCu79oUN6KdeKf8uqBCYQkmmwVkuqDT6R60ZhsyGRA8DcxLXAcFvqGNaEBOtT9k41SG00p7MiBOOn'
    return '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'
};
// b8d4b285d48d1e690388e999 with 30k hits
//32ce58d166a15bca2a55cd7a testing
// 8885028c6faaef9cb5bc397b new