import React, { useEffect } from 'react'
import image6 from '../Assets/Images/06.svg'
import image7 from '../Assets/Images/07.svg'
import image8 from '../Assets/Images/08.svg'
import hotel1 from '../Assets/Images/2.jpeg'
import hotel2 from '../Assets/Images/3.jpeg'
import hotel3 from '../Assets/Images/5.jpeg'
import Layout from '../Components/Layout'
function AboutusNew ({ onLogout }) {
  return (
    <>
      <Layout logout={onLogout}>
      <section>
	<div class="container">
		<div class="row mb-5">
			<div class="col-xl-10 mt-4 mx-auto text-center">
				<h2 className='text-dark'>AlSubaee- Largest Market Place to Search Hotel Rooms in Makkah and Madina</h2>
				<div class="hstack mt-3 gap-3 flex-wrap justify-content-center">
					<h6 class="bg-mode shadow rounded-2 fw-normal d-inline-block py-2 px-4">
						<img src={image6} class="h-20px me-2" alt=""/>
						14K+ Global Customers
					</h6>

					<h6 class="bg-mode shadow rounded-2 fw-normal d-inline-block py-2 px-4">
						<img src={image7} class="h-20px me-2" alt=""/>
						10K+ Happy Customers
					</h6>

					<h6 class="bg-mode shadow rounded-2 fw-normal d-inline-block py-2 px-4">
						<img src={image8} class="h-20px me-2" alt=""/>
						1M+ Subscribers
					</h6>
				</div>
			</div>
		</div> 

		<div class="row g-4 about-us-img align-items-center">
			<div class="col-md-6">
				<img src={hotel1} class="rounded-3" alt=""/>
			</div>

			<div class="col-md-6">
				<div class="row g-4">
					<div class="col-md-8">
						<img src={hotel2} class="rounded-3" alt=""/>
					</div>

					<div class="col-12">
						<img src={hotel3} class="rounded-3" alt=""/>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<section class="pt-0 pt-lg-5">
	<div class="container">
		<div class="row mb-4 mb-md-5">
			<div class="col-md-12 mx-auto">
				<h4 class="mb-1 ">How we started our journey?</h4>
				<p style={{fontSize:'medium'}} class="mb-0">AlSubaee was established few years back with an aim of providing the best services to the hotel management and agents. Main idea behind the startup was to offer a no-compromise user experience for the agents to book hotel rooms with ease. It contains all the types of rooms available in various partner hotels and agents can make selection according to their customers requirements.</p>
			</div>
			<div class="col-md-12 mx-auto">
				<h4 class="mb-1 mt-2">Top Management organizing an efficient team</h4>
				<p style={{fontSize:'medium'}} class="mb-0">At AlSubaee, management and team has rich experience of working from many years and knowledge of technology and hospitality services. Our services are delivered to let the mid sized and large hotel agents handle operational challenges and do customer bookings with ease. Due to an effective idea, cost efficiency and an easy to adopt solution, more and more agents are joining our AlSubaee’ platform to make an easy booking for the required rooms.</p>
			</div>
			<div class="col-md-12 mx-auto">
				<h4 class="mb-1 mt-2">Our Target Audience</h4>
				<p style={{fontSize:'medium'}} class="mb-0">All the worldwide agents who are looking hotel rooms for their customers in Makkah and Madina must opt for the fastest growing AlSubaee’ portal to achieve their targets easily and book rooms for their customers conveniently. We offer all the room types available in our partner hotels and agents from various regions having different requirements can make use of the large range of options and can finalize their room bookings.</p>
			</div>
			<div class="col-md-12 mx-auto">
				<h4 class="mb-1 mt-2">100 % Customer Satisfaction</h4>
				<p style={{fontSize:'medium'}} class="mb-0">With an efficient and dedicated team, AlSubaee has achieved an explosive growth with 100 % customer satisfaction and we aim to have many more agents as well as their customers join us in the coming years. We believe that nothing is impossible and we are working to achieve our target by making our presence felt globally to make bookings easier in Makkah and Madina.</p>
			</div>
		</div>

		{/* <div class="row g-4">
			<div class="col-sm-6 col-lg-3">
				<div class="icon-lg bg-orange bg-opacity-10 text-orange rounded-2"><i class="fa-solid fa-dollar fs-5"></i></div>
				<h5 class="mt-2">Best Price Guarantee</h5>
				<p class="mb-0">AlSubaee has partnered with more than 500 hotels in Makkah and Madina and connected with more than 10 3rd party APIs for Worldwide hotels. We guarantee the best prices for all the deals available to the agents. Our partnered hotels on the other hand have an opportunity to get viewed by thousand of agents all around the world.</p>
			</div>

			<div class="col-sm-6 col-lg-3">
				<div class="icon-lg bg-success bg-opacity-10 text-success rounded-2"><i class="fa-solid fa-hotel fs-5"></i></div>
				<h5 class="mt-2">How to book Hotel Rooms?</h5>
				<p class="mb-0">AlSubaee offer you hotel rooms search with price comparisons. Prices given at AlSubaee website comes from partner hotels and 3rd party APIs. You can search desired room for clients from list of hotel rooms available by just specifying requirements in 1 click.</p>
			</div>

			<div class="col-sm-6 col-lg-3">
				<div class="icon-lg bg-primary bg-opacity-10 text-primary rounded-2"><i class="fa-solid fa-globe-americas fs-5"></i></div>
				<h5 class="mt-2">Why Choose Us?</h5>
				<p class="mb-0">AlSubaee provides a complete list of options, and you can decide the best room according to client needs. Booking process gets completed by taking all requirements and offering cheap hotel rates through deal hotel room deals in Makkah, Madina and all over the world.</p>
			</div>

			<div class="col-sm-6 col-lg-3">
				<div class="icon-lg bg-info bg-opacity-10 text-info rounded-2"><i class="fa-solid fa-headphones fs-5"></i></div>
				<h5 class="mt-2">Need Help?</h5>
				<p class="mb-0">if you need any help during your hotel rooms search, our team is available to help and provide support 24/7. You just need to contact us to make a confirm booking for your client and gain the status of a reliable agent. Try AlSubaee to book cheap hotel rooms now.</p>
			</div>
		</div> */}
	</div>
</section>
      </Layout>
    </>
  )
}

export default AboutusNew
