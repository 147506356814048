import React, { useState, useEffect } from "react";
import Layout from "../Components/Layout";
import img from "../Assets/Images/1.jpg";
import sale from "../Assets/Images/sale.png";
import HomeSearch from "../Components/HomeSearch";
import { ApiEndPoint, ApiToken, CurrencyConverter } from "../Data/Api";
import moment from "moment";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { Tooltip, Whisper, Loader } from "rsuite";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  setSearchResults,
  setHotelDetail,
  setSearchQuery,
} from "../reducers/hotelActions";
import Loading from "../Components/Loader";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faStarHalf,
  faStarHalfAlt,
} from "@fortawesome/free-regular-svg-icons";
import Slider from "react-slick";
import home1 from "../Assets/Images/home1.webp";
function Home() {
  const UserDetail = useSelector((state) => state.user.user);
  const BaseCurrRates = useSelector((state) => state.currency.baseCurrRates);
  const SelectedCurrRates = useSelector(
    (state) => state.currency.selectedCurrRates
  );
  const token = ApiToken();
  const baseurl = ApiEndPoint();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedcurr, setSelectedCurr] = useState(
    localStorage.getItem("selectedCurrency")
  );
  const [customLoader, setCustomLoader] = useState(false);
  const [makkahPriceConversion, setMakkahPriceConversion] = useState(false);
  const [madinahPriceConversion, setmadinahPriceConversion] = useState(false);
  const [numberOfNights, setNumberOfNights] = useState("");
  const [makkahPromotionHotels, setMakkahPromotionHotels] = useState([]);
  const [madinahPromotionHotels, setMadinahPromotionHotels] = useState([]);
  const [makkahHotels, setMakkahHotels] = useState([]);
  const [MadinahHotels, setMadinahHotels] = useState([]);
  const [makkahHotelscurr, setMakkahHotelscurr] = useState([]);
  const [MadinahHotelscurr, setMadinahHotelscurr] = useState([]);
  const [makkahSearch, setMakkahSeach] = useState({});
  const [madinahSearch, setMadinahSearch] = useState({});
  const [isloadingMakkah, setLoadingMakkah] = useState(true);
  const [isloadingMadinah, setLoadingMadinah] = useState(true);
  const [makkahDetail, setMakkahDetail] = useState({});
  const [madinahDetail, setMadinahDetail] = useState({});
  // var selectedcurr = localStorage.getItem('DefaultCurrency');
  useEffect(() => {
    getUserLocation();
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Default for large screens
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024, // Medium screens
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 768, // Tablet screens
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 480, // Mobile screens
        settings: { slidesToShow: 1 },
      },
    ],
  };
  function getUserLocation() {
    fetch(
      "https://api.geoapify.com/v1/ipinfo?&apiKey=0708571ae58c4688824a7d01ca397041"
    )
      .then((response) => response.json())
      .then((data) => {
        SearchMakkahhotels(data.country.name);
        SearchMadinahhotels(data.country.name);
      })
      .catch((error) => {
        console.error("Error fetching user location:", error);
      });
  }

  const SearchMakkahhotels = async (userCountry) => {
    const currentDate = new Date();
    const twoDaysLater = new Date(currentDate);
    twoDaysLater.setDate(currentDate.getDate() + 1);
    const threeDaysLater = new Date(currentDate);
    threeDaysLater.setDate(currentDate.getDate() + 2);
    let Checkin = moment(twoDaysLater);
    let checkout = moment(threeDaysLater);
    let daysBetween = Math.abs(checkout.diff(Checkin, "days"));
    setNumberOfNights(daysBetween);
    const FormData = {
      token: token,
      promotion_Hotel: "Active",
      room_View: "",
      b2b_agent_id: UserDetail.id,
      currency_slc: "AFN",
      currency_slc_iso: "AF",
      destination_name: "Makkah",
      country: "Saudi Arabia",
      lat: 21.4240968,
      long: 39.81733639999999,
      pin: "SA",
      cityd: "Makkah",
      country_code: "SA",
      check_in: moment(twoDaysLater).format("YYYY-MM-DD"),
      check_out: moment(threeDaysLater).format("YYYY-MM-DD"),
      slc_nationality: userCountry,
      adult: 2,
      child: 0,
      room: 1,
      Adults: [2],
      children: [0],
      child_ages1: [],
      rooms_counter: [1],
      child_ages2: [],
    };
    setMakkahSeach(FormData);
    try {
      const response = await axios.post(
        baseurl + "/api/search/hotels/new_Live",
        FormData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (response.data.hotels_list.length !== 0) {
        response.data.hotels_list = response.data.hotels_list.filter(
          (item) => Number(item.client_Id) === 48
        );
        const customeHotel = response.data.hotels_list.filter(
          (item) => Number(item.client_Id) === 48
        );
        var promotion_Hotel_makkah = response.data.hotels_list.filter((item) =>
          item.rooms_options.some((room) => room.room_Promotions_Exist === "1")
        );
        if (promotion_Hotel_makkah.length !== 0) {
          setMakkahPromotionHotels(promotion_Hotel_makkah);
        }
        const currencies = [];
        customeHotel.forEach((hotel) => {
          if (!currencies.includes(hotel.hotel_curreny)) {
            currencies.push(hotel.hotel_curreny);
          }
        });
        const currencyResponse = await AllCurrency(currencies);

        if (currencyResponse === null || currencyResponse.length === 0) {
          setMakkahHotelscurr(null);
          setMakkahPriceConversion(false);
          localStorage.setItem("AllHotelCurr", null);
        } else {
          setMakkahHotelscurr(currencyResponse);
          setMakkahPriceConversion(true);
        }
        customeHotel.forEach((hotel) => {
          if (!makkahDetail[hotel.hotel_id]) {
            fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider);
          }
        });
        setLoadingMakkah(false);
        customeHotel.sort((a, b) => {
          if (a.client_Id === 48) return -1; // `a` comes first if `cline_id` is 48
          if (b.client_Id === 48) return 1; // `b` comes first if `cline_id` is 48
          return a.client_Id - b.client_Id; // Otherwise, sort in ascending order
        });
        setMakkahHotels(customeHotel);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const AllCurrency = async (currencyArray) => {
    const token = CurrencyConverter();

    if (currencyArray.length === 0) {
      return []; // Return an empty array if no currencies are provided
    }

    const requests = currencyArray.map((currencynew) => {
      const config = {
        method: "get",
        url: `https://v6.exchangerate-api.com/v6/${token}/latest/${currencynew}`, // Replace with your API URL
        maxBodyLength: Infinity,
        headers: {},
      };

      return axios
        .request(config)
        .then((response) => {
          return response.data; // Return the response data on success
        })
        .catch((error) => {
          console.error(error);
          return null; // Return null for failed requests
        });
    });

    const results = await Promise.all(requests);
    const newdatcurr = results.filter((response) => response !== null); // Filter out null responses
    return newdatcurr;
  };
  const SearchMadinahhotels = async (userCountry) => {
    const currentDate = new Date();
    const twoDaysLater = new Date(currentDate);
    twoDaysLater.setDate(currentDate.getDate() + 1);
    const threeDaysLater = new Date(currentDate);
    threeDaysLater.setDate(currentDate.getDate() + 2);
    const FormData = {
      token: token,
      room_View: "",
      promotion_Hotel: "Active",
      b2b_agent_id: UserDetail.id,
      currency_slc: "AFN",
      currency_slc_iso: "AF",
      destination_name: "Madinah",
      country: "Saudi Arabia",
      lat: 24.4672132,
      long: 39.6024496,
      pin: "SA",
      cityd: "Madinah",
      country_code: "SA",
      check_in: moment(twoDaysLater).format("YYYY-MM-DD"),
      check_out: moment(threeDaysLater).format("YYYY-MM-DD"),
      slc_nationality: userCountry,
      adult: 2,
      child: 0,
      room: 1,
      Adults: [2],
      children: [0],
      child_ages1: [],
      rooms_counter: [1],
      child_ages2: [],
    };
    setMadinahSearch(FormData);
    try {
      const response = await axios.post(
        baseurl + "/api/search/hotels/new_Live",
        FormData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (response.data.hotels_list.length !== 0) {
        response.data.hotels_list = response.data.hotels_list.filter(
          (item) => Number(item.client_Id) === 48
        );
        const customeHotel = response.data.hotels_list.filter(
          (item) => Number(item.client_Id) === 48
        );
        var promotion_Hotel_madinah = response.data.hotels_list.filter((item) =>
          item.rooms_options.some((room) => room.room_Promotions_Exist === "1")
        );
        if (promotion_Hotel_madinah.length !== 0) {
          setMadinahPromotionHotels(promotion_Hotel_madinah);
        }
        const currencies = [];
        customeHotel.forEach((hotel) => {
          if (!currencies.includes(hotel.hotel_curreny)) {
            currencies.push(hotel.hotel_curreny);
          }
        });
        const currencyResponse = await AllCurrency(currencies);
        if (currencyResponse === null || currencyResponse.length === 0) {
          setMadinahHotelscurr(null);
          setmadinahPriceConversion(false);
          localStorage.setItem("AllHotelCurr", null);
        } else {
          setMadinahHotelscurr(currencyResponse);
          setmadinahPriceConversion(true);
        }
        customeHotel.forEach((hotel) => {
          if (!makkahDetail[hotel.hotel_id]) {
            fetchHotelDetailsMadinah(hotel.hotel_id, hotel.hotel_provider);
          }
        });
        setLoadingMadinah(false);
        customeHotel.sort((a, b) => {
          if (a.client_Id === 48) return -1; // `a` comes first if `cline_id` is 48
          if (b.client_Id === 48) return 1; // `b` comes first if `cline_id` is 48
          return a.client_Id - b.client_Id; // Otherwise, sort in ascending order
        });
        setMadinahHotels(customeHotel);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const fetchHotelDetailsMadinah = async (hotelId, provider) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: token,
    };
    try {
      const response = await axios.post(
        baseurl + "/api/hotels/mata_Live",
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      setMadinahDetail((prevDetails) => ({
        ...prevDetails,
        [hotelId]: response.data,
      }));
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
    }
  };

  const fetchHotelDetails = async (hotelId, provider) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: token,
    };
    try {
      const response = await axios.post(
        baseurl + "/api/hotels/mata_Live",
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      setMakkahDetail((prevDetails) => ({
        ...prevDetails,
        [hotelId]: response.data,
      }));
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
    }
  };
  const calculateMarkup = (ctype, cvalue, atype, avalue, price) => {
    let markupprice = 0;
    let adminmarkupprice = 0;
    let clientmarkupprice = 0;
    let finalpricemarkup = Number(price);
    if (Number(avalue) !== 0) {
      if (atype === "Percentage") {
        markupprice = (price * Number(avalue)) / 100;
      } else {
        markupprice = Number(avalue);
      }
      adminmarkupprice = markupprice;
      finalpricemarkup += markupprice;
    }
    if (Number(cvalue) !== 0) {
      if (ctype === "Percentage") {
        markupprice = (Number(price) * Number(cvalue)) / 100;
      } else {
        markupprice = Number(cvalue);
      }
      clientmarkupprice = markupprice;
      finalpricemarkup += markupprice;
    }
    if (Number(finalpricemarkup) % 1 !== 0) {
      return Number(finalpricemarkup).toFixed(2);
    }
    return finalpricemarkup;
  };
  const RoomDetailPage = async (id, index, loca) => {
    var hotelRoomdetail = [];
    if (loca === "makkah") {
      dispatch(setSearchQuery(makkahSearch));
      localStorage.setItem("AllHotelCurr", JSON.stringify(makkahHotelscurr));
      hotelRoomdetail = makkahHotels.filter((item) => item.hotel_id == id);
    } else {
      dispatch(setSearchQuery(madinahSearch));
      localStorage.setItem("AllHotelCurr", JSON.stringify(MadinahHotelscurr));
      hotelRoomdetail = MadinahHotels.filter((item) => item.hotel_id == id);
    }
    dispatch(setSearchResults(hotelRoomdetail));
    setCustomLoader(true);
    try {
      const data = {
        token: token,
        hotel_search_data: JSON.stringify(hotelRoomdetail[0]),
        hotel_code: String(id),
      };
      const response = await axios.post(
        baseurl + "/api/hotels/details_Live",
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            // Required for CORS support to work
            "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
            "Access-Control-Allow-Headers":
              "Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale",
          },
        }
      );
      setCustomLoader(false);
      if (response.data !== "") {
        dispatch(setHotelDetail(response.data.hotel_details));
        navigate(`/hotel_detail/${id}`, { state: { index } });
      } else {
        toast.info(
          "Something went wrong. Please select another hotel or try after some time thanks."
        );
      }
    } catch (error) {
      setCustomLoader(false);
      console.error("Error:", error);
    }
  };
  const renderPrice = (price, currency, location) => {
    var selectedcurrency = localStorage.getItem("selectedCurrency");
    var currencyData = [];
    if (location === "makkah") {
      currencyData = makkahHotelscurr;
    } else {
      currencyData = MadinahHotelscurr;
    }
    if (currencyData.length === 0 || currency === selectedcurrency) {
      if (Number(price) % 1 !== 0) {
        return Number(price).toFixed(2);
      }
      return Number(price);
    } else {
      var filterroomcurrdata = currencyData.filter(
        (item) => item.base_code === currency
      );
      const usdprice = filterroomcurrdata[0].conversion_rates[selectedcurrency]; // Use square brackets to access the property
      var baseprice = Number(usdprice) * Number(price);
      if (Number(baseprice) % 1 !== 0) {
        return Number(baseprice).toFixed(2);
      }
      return baseprice;
    }
  };
  return (
    <>
      {customLoader && <Loading />}
      <ToastContainer />
      <Layout>
        <div className="top-section d-flex flex-column align-items-center justify-content-center">
          <div className="container home-top-text">
            <h2 className="text-white">Book Your Perfect Stay</h2>
            <h3 className="text-white">Your perfect room just a click away</h3>
            <HomeSearch />
          </div>
        </div>
        <div className="container hotel-home-section ">
          {(makkahPromotionHotels.length !== 0 ||
            madinahPromotionHotels.length !== 0) && (
            <div className="row">
              <h4 className="my-2">Promotion hotels in Mecca & Medina</h4>
              <div className="hr my-3 "></div>
              {makkahPromotionHotels.map((item, index) => (
                <div key={index} className="col-md-3 col-sm-4 col-12">
                  <div class="card hotel-card">
                    <figure className="m-0">
                      <small>Mecca</small>
                      {makkahDetail[item.hotel_id] &&
                      makkahDetail[item.hotel_id].details_data &&
                      makkahDetail[item.hotel_id].details_data.image ? (
                        <img
                          class="card-img-top-home"
                          src={makkahDetail[item.hotel_id].details_data.image}
                          alt="Card image cap"
                        />
                      ) : (
                        <div className="home-card-loader-img">
                          <Loader size="md" />
                        </div>
                      )}
                    </figure>
                    <div class="card-body">
                      {item?.stars_rating === "" || item?.stars_rating === 0 ? (
                        <div class="score">
                          <strong>No Rating</strong>
                        </div>
                      ) : (
                        <div class="cat_star">
                          {Array(Math.floor(item.stars_rating))
                            .fill(0)
                            .map((_, index) => (
                              <FontAwesomeIcon
                                key={index}
                                style={{ color: "#fff" }}
                                icon={faStar}
                              />
                            ))}
                          {item.stars_rating % 1 !== 0 && (
                            <FontAwesomeIcon
                              style={{ color: "#fff" }}
                              icon={faStarHalfAlt}
                            />
                          )}
                        </div>
                      )}
                      <Whisper
                        placement="top"
                        controlId="control-id-hover"
                        trigger="hover"
                        speaker={<Tooltip>{item.hotel_name}</Tooltip>}
                      >
                        <h6 className="ellipsis-text">{item.hotel_name}</h6>
                      </Whisper>
                      <p className="ellipsis-address">
                        {makkahDetail[item.hotel_id]?.details_data?.address}
                      </p>
                      <div className="d-flex mt-4 justify-content-between align-items-center">
                        <h6>
                          {makkahPriceConversion === false
                            ? item.hotel_curreny
                            : Object.keys(SelectedCurrRates).length === 0
                            ? BaseCurrRates.base_code
                            : SelectedCurrRates.base_code}{" "}
                          {makkahPriceConversion === false
                            ? calculateMarkup(
                                item.customer_markup_type,
                                item.customer_markup,
                                item.admin_markup_type,
                                item.admin_markup,
                                item.min_price
                              )
                            : renderPrice(
                                calculateMarkup(
                                  item.customer_markup_type,
                                  item.customer_markup,
                                  item.admin_markup_type,
                                  item.admin_markup,
                                  item.min_price
                                ),
                                item.hotel_curreny,
                                "makkah"
                              )}
                          <sub style={{ color: "#808080ad" }}>
                            <del>
                              {makkahPriceConversion === false
                                ? calculateMarkup(
                                    item.customer_markup_type,
                                    item.customer_markup,
                                    item.admin_markup_type,
                                    item.admin_markup,
                                    item.min_price_Actual
                                  )
                                : renderPrice(
                                    calculateMarkup(
                                      item.customer_markup_type,
                                      item.customer_markup,
                                      item.admin_markup_type,
                                      item.admin_markup,
                                      item.min_price_Actual
                                    ),
                                    item.hotel_curreny,
                                    "makkah"
                                  )}
                            </del>
                          </sub>
                          <br /> <small>Vat and Taxes included</small>
                        </h6>
                        <button
                          onClick={() =>
                            RoomDetailPage(item.hotel_id, index, "makkah")
                          }
                          className="btn btn-primary"
                        >
                          View Detail
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {madinahPromotionHotels.map((item, index) => (
                <div key={index} className="col-md-3 col-sm-4 col-12">
                  <div class="card hotel-card">
                    <figure className="m-0">
                      <small>Medina</small>
                      {madinahDetail[item.hotel_id] &&
                      madinahDetail[item.hotel_id].details_data &&
                      madinahDetail[item.hotel_id].details_data.image ? (
                        <img
                          class="card-img-top-home"
                          src={madinahDetail[item.hotel_id].details_data.image}
                          alt="Card image cap"
                        />
                      ) : (
                        <div className="home-card-loader-img">
                          <Loader size="md" />
                        </div>
                      )}
                    </figure>
                    <div class="card-body">
                      {item?.stars_rating === "" || item?.stars_rating === 0 ? (
                        <div class="score">
                          <strong>No Rating</strong>
                        </div>
                      ) : (
                        <div class="cat_star">
                          {Array(Math.floor(item.stars_rating))
                            .fill(0)
                            .map((_, index) => (
                              <FontAwesomeIcon
                                key={index}
                                style={{ color: "#fff" }}
                                icon={faStar}
                              />
                            ))}
                          {item.stars_rating % 1 !== 0 && (
                            <FontAwesomeIcon
                              style={{ color: "#fff" }}
                              icon={faStarHalfAlt}
                            />
                          )}
                        </div>
                      )}
                      <Whisper
                        placement="top"
                        controlId="control-id-hover"
                        trigger="hover"
                        speaker={<Tooltip>{item.hotel_name}</Tooltip>}
                      >
                        <h6 className="ellipsis-text">{item.hotel_name}</h6>
                      </Whisper>
                      <p className="ellipsis-address">
                        {madinahDetail[item.hotel_id]?.details_data?.address}
                      </p>
                      <div className="d-flex mt-4 justify-content-between align-items-center">
                        <h6>
                          {madinahPriceConversion === false
                            ? item.hotel_curreny
                            : Object.keys(SelectedCurrRates).length === 0
                            ? BaseCurrRates.base_code
                            : SelectedCurrRates.base_code}{" "}
                          {madinahPriceConversion === false
                            ? calculateMarkup(
                                item.customer_markup_type,
                                item.customer_markup,
                                item.admin_markup_type,
                                item.admin_markup,
                                item.min_price
                              )
                            : renderPrice(
                                calculateMarkup(
                                  item.customer_markup_type,
                                  item.customer_markup,
                                  item.admin_markup_type,
                                  item.admin_markup,
                                  item.min_price
                                ),
                                item.hotel_curreny,
                                "madinah"
                              )}
                          <sub style={{ color: "#808080ad" }}>
                            <del>
                              {madinahPriceConversion === false
                                ? calculateMarkup(
                                    item.customer_markup_type,
                                    item.customer_markup,
                                    item.admin_markup_type,
                                    item.admin_markup,
                                    item.min_price_Actual
                                  )
                                : renderPrice(
                                    calculateMarkup(
                                      item.customer_markup_type,
                                      item.customer_markup,
                                      item.admin_markup_type,
                                      item.admin_markup,
                                      item.min_price_Actual
                                    ),
                                    item.hotel_curreny,
                                    "madinah"
                                  )}
                            </del>
                          </sub>
                          <br /> <small>Vat and Taxes included</small>
                        </h6>
                        <button
                          onClick={() =>
                            RoomDetailPage(item.hotel_id, index, "madinah")
                          }
                          className="btn btn-primary"
                        >
                          View Detail
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/* <div className="mt-2">
                <Slider
                  {...{
                    ...settingspromotion,
                    slidesToShow:
                      makkahPromotionHotels.length +
                        madinahPromotionHotels.length <
                      3
                        ? makkahPromotionHotels.length +
                          madinahPromotionHotels.length
                        : 3, // Adjust number of slides dynamically
                    infinite:
                      makkahPromotionHotels.length +
                        madinahPromotionHotels.length >
                      1, // Only enable infinite loop if more than one hotel
                  }}
                >
                  {makkahPromotionHotels.map((item, index) => (
                    <div key={`makkah-${index}`} className="p-1">
                      <div className="card-shadow">
                        <div className="row">
                          <div className="col-md-4 text-center">
                            <img
                              src={sale}
                              width="150"
                              alt={`${item.hotel_name}-image`}
                            />
                          </div>
                          <div className="col-md-8 d-flex justify-content-center align-items-center">
                            <div>
                              <h6>{item.hotel_name}</h6>
                              <p>
                                Some quick example text to build on the card
                                title and make up the bulk of the card's
                                content.
                              </p>
                              <button className="theme-btn-1 px-3 py-2">
                                Pay Now
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {madinahPromotionHotels.map((item, index) => (
                    <div key={`madinah-${index}`} className="p-1">
                      <div className="card-shadow">
                        <div className="row">
                          <div className="col-md-4 text-center">
                            <img
                              src={sale}
                              width="150"
                              alt={`${item.hotel_name}-image`}
                            />
                          </div>
                          <div className="col-md-8 d-flex justify-content-center align-items-center">
                            <div>
                              <h6>{item.hotel_name}</h6>
                              <p>
                                Some quick example text to build on the card
                                title and make up the bulk of the card's
                                content.
                              </p>
                              <button className="theme-btn-1 px-3 py-2">
                                Pay Now
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div> */}
            </div>
          )}

          {isloadingMakkah && isloadingMadinah ? (
            <div className="row">
              <h4 className="mb-2">Recommended Hotels in Mecca and Medina</h4>
              <div className="col-12 col-sm-6 col-md-3 mt-2">
                <div class="card">
                  <div className="home-card-loader-img"></div>
                  <div class="card-body">
                    <h6 className=" placeholder-glow">
                      <span class="placeholder col-12"></span>
                      <span class="placeholder col-8"></span>
                      <span class="placeholder col-11"></span>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mt-2">
                <div class="card">
                  <div className="home-card-loader-img"></div>
                  <div class="card-body">
                    <h6 className=" placeholder-glow">
                      <span class="placeholder col-12"></span>
                      <span class="placeholder col-8"></span>
                      <span class="placeholder col-11"></span>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mt-2">
                <div class="card">
                  <div className="home-card-loader-img"></div>
                  <div class="card-body">
                    <h6 className=" placeholder-glow">
                      <span class="placeholder col-12"></span>
                      <span class="placeholder col-8"></span>
                      <span class="placeholder col-11"></span>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mt-2">
                <div class="card">
                  <div className="home-card-loader-img"></div>
                  <div class="card-body">
                    <h6 className=" placeholder-glow">
                      <span class="placeholder col-12"></span>
                      <span class="placeholder col-8"></span>
                      <span class="placeholder col-11"></span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <h4 className="mt-4">Recommended Hotels in Mecca and Medina</h4>
              <div className="hr my-3 "></div>
              {makkahHotels.length + MadinahHotels.length === 1 ? (
                <>
                  {makkahHotels.slice(0, 8).map((item, index) => (
                    <div key={index} className="p-1 col-md-3 item">
                      <div class="card hotel-card">
                        <figure className="m-0">
                          {item.client_Id === 48 && <small>AS</small>}
                          {makkahDetail[item.hotel_id] &&
                          makkahDetail[item.hotel_id].details_data &&
                          makkahDetail[item.hotel_id].details_data.image ? (
                            <img
                              class="card-img-top-home"
                              src={
                                makkahDetail[item.hotel_id].details_data.image
                              }
                              alt="Card image cap"
                            />
                          ) : (
                            <div className="home-card-loader-img">
                              <Loader size="md" />
                            </div>
                          )}
                        </figure>
                        <div class="card-body">
                        {item?.stars_rating === "" || item?.stars_rating === 0 ? (
                        <div class="score">
                          <strong>No Rating</strong>
                        </div>
                      ) : (
                        <div class="cat_star">
                          {Array(Math.floor(item.stars_rating))
                            .fill(0)
                            .map((_, index) => (
                              <FontAwesomeIcon
                                key={index}
                                style={{ color: "#fff" }}
                                icon={faStar}
                              />
                            ))}
                          {item.stars_rating % 1 !== 0 && (
                            <FontAwesomeIcon
                              style={{ color: "#fff" }}
                              icon={faStarHalfAlt}
                            />
                          )}
                        </div>
                      )}
                          <Whisper
                            placement="top"
                            controlId="control-id-hover"
                            trigger="hover"
                            speaker={<Tooltip>{item.hotel_name}</Tooltip>}
                          >
                            <h6 className="ellipsis-text">{item.hotel_name}</h6>
                          </Whisper>
                          {/* <p className="ellipsis-address">
                            {makkahDetail[item.hotel_id]?.details_data?.address}
                          </p> */}
                          <div className="d-flex mt-4 justify-content-between align-items-center">
                            <h6 className="">
                              {makkahPriceConversion === false
                                ? item.hotel_curreny
                                : Object.keys(SelectedCurrRates).length === 0
                                ? BaseCurrRates.base_code
                                : SelectedCurrRates.base_code}{" "}
                              {makkahPriceConversion === false
                                ? calculateMarkup(
                                    item.customer_markup_type,
                                    item.customer_markup,
                                    item.admin_markup_type,
                                    item.admin_markup,
                                    item.min_price
                                  )
                                : renderPrice(
                                    calculateMarkup(
                                      item.customer_markup_type,
                                      item.customer_markup,
                                      item.admin_markup_type,
                                      item.admin_markup,
                                      item.min_price
                                    ),
                                    item.hotel_curreny,
                                    "makkah"
                                  )}
                              <br /> <small>Vat and Taxes included</small>
                            </h6>
                            <button
                              onClick={() =>
                                RoomDetailPage(item.hotel_id, index, "makkah")
                              }
                              className="btn btn-primary"
                            >
                              View Detail
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {MadinahHotels.slice(0, 8).map((item, index) => (
                    <div key={index} className="p-1 col-md-3 item">
                      <div class="card hotel-card">
                        <figure className="m-0">
                          {item.client_Id === 48 && <small>AS</small>}
                          {madinahDetail[item.hotel_id] &&
                          madinahDetail[item.hotel_id].details_data &&
                          madinahDetail[item.hotel_id].details_data.image ? (
                            <img
                              class="card-img-top-home"
                              src={
                                madinahDetail[item.hotel_id].details_data.image
                              }
                              alt="Card image cap"
                            />
                          ) : (
                            <div className="home-card-loader-img">
                              <Loader size="md" />
                            </div>
                          )}
                        </figure>
                        <div class="card-body">
                        {item?.stars_rating === "" || item?.stars_rating === 0 ? (
                        <div class="score">
                          <strong>No Rating</strong>
                        </div>
                      ) : (
                        <div class="cat_star">
                          {Array(Math.floor(item.stars_rating))
                            .fill(0)
                            .map((_, index) => (
                              <FontAwesomeIcon
                                key={index}
                                style={{ color: "#fff" }}
                                icon={faStar}
                              />
                            ))}
                          {item.stars_rating % 1 !== 0 && (
                            <FontAwesomeIcon
                              style={{ color: "#fff" }}
                              icon={faStarHalfAlt}
                            />
                          )}
                        </div>
                      )}
                          <Whisper
                            placement="top"
                            controlId="control-id-hover"
                            trigger="hover"
                            speaker={<Tooltip>{item.hotel_name}</Tooltip>}
                          >
                            <h6 className="ellipsis-text">{item.hotel_name}</h6>
                          </Whisper>
                          {/* <p className="ellipsis-address">
                            {
                              madinahDetail[item.hotel_id]?.details_data
                                ?.address
                            }
                          </p> */}
                          <div className="d-flex mt-4 justify-content-between align-items-center">
                            <h6>
                              {madinahPriceConversion === false
                                ? item.hotel_curreny
                                : Object.keys(SelectedCurrRates).length === 0
                                ? BaseCurrRates.base_code
                                : SelectedCurrRates.base_code}{" "}
                              {madinahPriceConversion === false
                                ? calculateMarkup(
                                    item.customer_markup_type,
                                    item.customer_markup,
                                    item.admin_markup_type,
                                    item.admin_markup,
                                    item.min_price
                                  )
                                : renderPrice(
                                    calculateMarkup(
                                      item.customer_markup_type,
                                      item.customer_markup,
                                      item.admin_markup_type,
                                      item.admin_markup,
                                      item.min_price
                                    ),
                                    item.hotel_curreny,
                                    "madinah"
                                  )}
                              <br /> <small>Vat and Taxes included</small>
                            </h6>
                            <button
                              onClick={() =>
                                RoomDetailPage(item.hotel_id, index, "madinah")
                              }
                              className="btn btn-sm btn-primary"
                            >
                              View Detail
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="mt-2 mb-4 card-slider">
                  <Slider {...settings}>
                    {makkahHotels.slice(0, 8).map((item, index) => (
                      <div key={index} className="p-1 item">
                        <div class="card hotel-card">
                          <figure className="m-0">
                            <small>Mecca</small>
                            {makkahDetail[item.hotel_id] &&
                            makkahDetail[item.hotel_id].details_data &&
                            makkahDetail[item.hotel_id].details_data.image ? (
                              <img
                                class="card-img-top-home"
                                src={
                                  makkahDetail[item.hotel_id].details_data.image
                                }
                                alt="Card image cap"
                              />
                            ) : (
                              <div className="home-card-loader-img">
                                <Loader size="md" />
                              </div>
                            )}
                          </figure>
                          <div class="card-body">
                          {item?.stars_rating === "" || item?.stars_rating === 0 ? (
                        <div class="score">
                          <strong>No Rating</strong>
                        </div>
                      ) : (
                        <div class="cat_star">
                          {Array(Math.floor(item.stars_rating))
                            .fill(0)
                            .map((_, index) => (
                              <FontAwesomeIcon
                                key={index}
                                style={{ color: "#fff" }}
                                icon={faStar}
                              />
                            ))}
                          {item.stars_rating % 1 !== 0 && (
                            <FontAwesomeIcon
                              style={{ color: "#fff" }}
                              icon={faStarHalfAlt}
                            />
                          )}
                        </div>
                      )}
                            <Whisper
                              placement="top"
                              controlId="control-id-hover"
                              trigger="hover"
                              speaker={<Tooltip>{item.hotel_name}</Tooltip>}
                            >
                              <h6 className="ellipsis-text">
                                {item.hotel_name}
                              </h6>
                            </Whisper>
                            {/* <p className="ellipsis-address">
                              {
                                makkahDetail[item.hotel_id]?.details_data
                                  ?.address
                              }
                            </p> */}
                            <div className="d-flex mt-4 justify-content-between align-items-center">
                              <h6 className=" ">
                                {makkahPriceConversion === false
                                  ? item.hotel_curreny
                                  : Object.keys(SelectedCurrRates).length === 0
                                  ? BaseCurrRates.base_code
                                  : SelectedCurrRates.base_code}{" "}
                                {makkahPriceConversion === false
                                  ? calculateMarkup(
                                      item.customer_markup_type,
                                      item.customer_markup,
                                      item.admin_markup_type,
                                      item.admin_markup,
                                      item.min_price
                                    )
                                  : renderPrice(
                                      calculateMarkup(
                                        item.customer_markup_type,
                                        item.customer_markup,
                                        item.admin_markup_type,
                                        item.admin_markup,
                                        item.min_price
                                      ),
                                      item.hotel_curreny,
                                      "makkah"
                                    )}
                                <br /> <small>Vat and Taxes included</small>
                              </h6>
                              <button
                                onClick={() =>
                                  RoomDetailPage(item.hotel_id, index, "makkah")
                                }
                                className="btn btn-sm btn-primary"
                              >
                                View Detail
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    {MadinahHotels.slice(0, 8).map((item, index) => (
                      <div key={index} className="p-1 item">
                        <div class="card hotel-card">
                          <figure className="m-0">
                            <small>Medina</small>
                            {madinahDetail[item.hotel_id] &&
                            madinahDetail[item.hotel_id].details_data &&
                            madinahDetail[item.hotel_id].details_data.image ? (
                              <img
                                class="card-img-top-home"
                                src={
                                  madinahDetail[item.hotel_id].details_data
                                    .image
                                }
                                alt="Card image cap"
                              />
                            ) : (
                              <div className="home-card-loader-img">
                                <Loader size="md" />
                              </div>
                            )}
                          </figure>
                          <div class="card-body">
                          {item?.stars_rating === "" || item?.stars_rating === 0 ? (
                        <div class="score">
                          <strong>No Rating</strong>
                        </div>
                      ) : (
                        <div class="cat_star">
                          {Array(Math.floor(item.stars_rating))
                            .fill(0)
                            .map((_, index) => (
                              <FontAwesomeIcon
                                key={index}
                                style={{ color: "#fff" }}
                                icon={faStar}
                              />
                            ))}
                          {item.stars_rating % 1 !== 0 && (
                            <FontAwesomeIcon
                              style={{ color: "#fff" }}
                              icon={faStarHalfAlt}
                            />
                          )}
                        </div>
                      )}
                            <Whisper
                              placement="top"
                              controlId="control-id-hover"
                              trigger="hover"
                              speaker={<Tooltip>{item.hotel_name}</Tooltip>}
                            >
                              <h6 className="ellipsis-text">
                                {item.hotel_name}
                              </h6>
                            </Whisper>
                            {/* <p className="ellipsis-address">
                              {
                                madinahDetail[item.hotel_id]?.details_data
                                  ?.address
                              }
                            </p> */}
                            <div className="d-flex mt-4 justify-content-between align-items-center">
                              <h6>
                                {madinahPriceConversion === false
                                  ? item.hotel_curreny
                                  : Object.keys(SelectedCurrRates).length === 0
                                  ? BaseCurrRates.base_code
                                  : SelectedCurrRates.base_code}{" "}
                                {madinahPriceConversion === false
                                  ? calculateMarkup(
                                      item.customer_markup_type,
                                      item.customer_markup,
                                      item.admin_markup_type,
                                      item.admin_markup,
                                      item.min_price
                                    )
                                  : renderPrice(
                                      calculateMarkup(
                                        item.customer_markup_type,
                                        item.customer_markup,
                                        item.admin_markup_type,
                                        item.admin_markup,
                                        item.min_price
                                      ),
                                      item.hotel_curreny,
                                      "madinah"
                                    )}
                                <br /> <small>Vat and Taxes included</small>
                              </h6>
                              <button
                                onClick={() =>
                                  RoomDetailPage(
                                    item.hotel_id,
                                    index,
                                    "madinah"
                                  )
                                }
                                className="btn btn-sm btn-primary"
                              >
                                View Detail
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              )}
            </div>
          )}
          {/* {isloadingMadinah ? (
            <div className="row">
              <h4 className="mb-2">Recommended Hotels in Medina</h4>
              <div className="col-12 col-sm-6 col-md-3 mt-2">
                <div class="card">
                  <div className="home-card-loader-img"></div>
                  <div class="card-body">
                    <h6 className=" placeholder-glow">
                      <span class="placeholder col-12"></span>
                      <span class="placeholder col-8"></span>
                      <span class="placeholder col-11"></span>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mt-2">
                <div class="card">
                  <div className="home-card-loader-img"></div>
                  <div class="card-body">
                    <h6 className=" placeholder-glow">
                      <span class="placeholder col-12"></span>
                      <span class="placeholder col-8"></span>
                      <span class="placeholder col-11"></span>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mt-2">
                <div class="card">
                  <div className="home-card-loader-img"></div>
                  <div class="card-body">
                    <h6 className=" placeholder-glow">
                      <span class="placeholder col-12"></span>
                      <span class="placeholder col-8"></span>
                      <span class="placeholder col-11"></span>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mt-2">
                <div class="card">
                  <div className="home-card-loader-img"></div>
                  <div class="card-body">
                    <h6 className=" placeholder-glow">
                      <span class="placeholder col-12"></span>
                      <span class="placeholder col-8"></span>
                      <span class="placeholder col-11"></span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <h4 className=" mt-4">Recommended Hotels in Medina</h4>
              <div className="hr my-3 "></div>
              {MadinahHotels.length === 1 ? (
                <>
                  {MadinahHotels.slice(0, 8).map((item, index) => (
                    <div key={index} className="p-1 col-md-3 item">
                      <div class="card hotel-card">
                        <figure className="m-0">
                          {item.client_Id === 48 && <small>AS</small>}
                          {madinahDetail[item.hotel_id] &&
                          madinahDetail[item.hotel_id].details_data &&
                          madinahDetail[item.hotel_id].details_data.image ? (
                            <img
                              class="card-img-top-home"
                              src={
                                madinahDetail[item.hotel_id].details_data.image
                              }
                              alt="Card image cap"
                            />
                          ) : (
                            <div className="home-card-loader-img">
                              <Loader size="md" />
                            </div>
                          )}
                        </figure>
                        <div class="card-body">
                          <Whisper
                            placement="top"
                            controlId="control-id-hover"
                            trigger="hover"
                            speaker={<Tooltip>{item.hotel_name}</Tooltip>}
                          >
                            <h6 className="ellipsis-text">{item.hotel_name}</h6>
                          </Whisper>
                          <p className="ellipsis-address">
                            {
                              madinahDetail[item.hotel_id]?.details_data
                                ?.address
                            }
                          </p>
                          <div className="d-flex mt-4 justify-content-between align-items-center">
                            <h6>
                              {madinahPriceConversion === false
                                ? item.hotel_curreny
                                : Object.keys(SelectedCurrRates).length === 0
                                ? BaseCurrRates.base_code
                                : SelectedCurrRates.base_code}{" "}
                              {madinahPriceConversion === false
                                ? calculateMarkup(
                                    item.customer_markup_type,
                                    item.customer_markup,
                                    item.admin_markup_type,
                                    item.admin_markup,
                                    item.min_price
                                  )
                                : renderPrice(
                                    calculateMarkup(
                                      item.customer_markup_type,
                                      item.customer_markup,
                                      item.admin_markup_type,
                                      item.admin_markup,
                                      item.min_price
                                    ),
                                    item.hotel_curreny,
                                    "madinah"
                                  )}
                              <br /> <small>Vat and Taxes included</small>
                            </h6>
                            <button
                              onClick={() =>
                                RoomDetailPage(item.hotel_id, index, "madinah")
                              }
                              className="btn btn-sm btn-primary"
                            >
                              View Detail
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="mt-2 card-slider">
                  <Slider {...settings}>
                    {MadinahHotels.slice(0, 8).map((item, index) => (
                      <div key={index} className="p-1 item">
                        <div class="card hotel-card">
                          <figure className="m-0">
                            {item.client_Id === 48 && <small>AS</small>}
                            {madinahDetail[item.hotel_id] &&
                            madinahDetail[item.hotel_id].details_data &&
                            madinahDetail[item.hotel_id].details_data.image ? (
                              <img
                                class="card-img-top-home"
                                src={
                                  madinahDetail[item.hotel_id].details_data
                                    .image
                                }
                                alt="Card image cap"
                              />
                            ) : (
                              <div className="home-card-loader-img">
                                <Loader size="md" />
                              </div>
                            )}
                          </figure>
                          <div class="card-body">
                            <Whisper
                              placement="top"
                              controlId="control-id-hover"
                              trigger="hover"
                              speaker={<Tooltip>{item.hotel_name}</Tooltip>}
                            >
                              <h6 className="ellipsis-text">
                                {item.hotel_name}
                              </h6>
                            </Whisper>
                            <p className="ellipsis-address">
                              {
                                madinahDetail[item.hotel_id]?.details_data
                                  ?.address
                              }
                            </p>
                            <div className="d-flex mt-4 justify-content-between align-items-center">
                              <h6>
                                {madinahPriceConversion === false
                                  ? item.hotel_curreny
                                  : Object.keys(SelectedCurrRates).length === 0
                                  ? BaseCurrRates.base_code
                                  : SelectedCurrRates.base_code}{" "}
                                {madinahPriceConversion === false
                                  ? calculateMarkup(
                                      item.customer_markup_type,
                                      item.customer_markup,
                                      item.admin_markup_type,
                                      item.admin_markup,
                                      item.min_price
                                    )
                                  : renderPrice(
                                      calculateMarkup(
                                        item.customer_markup_type,
                                        item.customer_markup,
                                        item.admin_markup_type,
                                        item.admin_markup,
                                        item.min_price
                                      ),
                                      item.hotel_curreny,
                                      "madinah"
                                    )}
                                <br /> <small>Vat and Taxes included</small>
                              </h6>
                              <button
                                onClick={() =>
                                  RoomDetailPage(
                                    item.hotel_id,
                                    index,
                                    "madinah"
                                  )
                                }
                                className="btn btn-sm btn-primary"
                              >
                                View Detail
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              )}
            </div>
          )} */}
          <div className="row mt-4">
            <div className="col-md-6">
              <img
                className="rounded-2"
                style={{ width: "100%", height: "auto" }}
                src={home1}
              />
            </div>
            <div className="col-md-6 text-center home-about-section">
              <h2 className="mt-2">About Alsubaee</h2>
              <p className="mt-4">
              In the 1970s, our family extended hospitality to travelers bound for Mecca, regardless of their backgrounds or social standing. Growing up in a culture that reveres guest service as a sacred duty, we witnessed firsthand our father’s unwavering love for travel and his commitment to serving humanity.
              </p>
              <p className="mt-4">Driven by this legacy, we founded Alsubaee in 2013—dedicated to curating exceptional travel adventures. Whether it’s a leisurely holiday or a profound spiritual journey, we specialize in tailoring solutions that cater to the unique needs of every traveler.</p>
              <p className="mt-4">As a premier travel facilitator, dedicated to Hajj and Umrah pilgrimages, we are committed to providing you with a transformative experience that honors the deep spiritual significance of these holy journeys.</p>
              <NavLink to='/about_us'><button type="button" class="btn btn-lg mt-4 btn-outline-primary">Read More</button></NavLink>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
export default Home;
