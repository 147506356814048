import React, { useState, useEffect } from "react";
import Layout from "../Components/Layout";
import { ApiEndPoint, ApiToken, CurrencyConverter } from "../Data/Api";
import Loadeing from "../Components/Loader";
import axios from "axios";
import { Tooltip, Whisper,Loader } from "rsuite";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setSearchResults,
  setHotelDetail,
  setSearchQuery,
} from "../reducers/hotelActions";
import img from "../Assets/Images/bg2.jpg";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faHome,
  faList,
  faMapMarkerAlt,
  faSliders,
  faStarHalfAlt,
  faTh,
} from "@fortawesome/free-solid-svg-icons";
import { SelectPicker, RangeSlider } from "rsuite";
import ModifySearch from "../Components/ModifySearch";
import moment from "moment";
import HotelMap from "../Components/HotelMap";
function HotelListing() {
  const baseurl = ApiEndPoint();
  const token = ApiToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const UserDetail = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(true);
  const [detailloading, setDetailLoading] = useState(false);
  const [isGridView, setIsGridView] = useState(1);
  const [orderBy, setOrderBy] = useState(1);
  const BaseCurrRates = useSelector((state) => state.currency.baseCurrRates);
  const SelectedCurrRates = useSelector(
    (state) => state.currency.selectedCurrRates
  );
  const [selectedcurr, setSelectedCurr] = useState(
    localStorage.getItem("selectedCurrency")
  );
  const [priceConversion, setPriceConversion] = useState(false);
  const [currencyRates, setCurrencyRates] = useState([]);
  const [hotelsList, setHotelsList] = useState([]);
  const [resetHotelsList, setResetHotelsList] = useState([]);
  const [hotelsResponse, setHotelsResponse] = useState([]);
  const [hotelsDetail, setHotelsDetail] = useState({});
  const [searchData, setSearchData] = useState({});
  const locationSearch = localStorage.getItem("SearchLocation");
  const [numberOfNights, setNumberOfNights] = useState("");
  //Filters
  const [hotelsFList, setHotelsFList] = useState([]);
  const [selectedhotelid, setSelectedhotelid] = useState(null);
  const [selectedStars, setSelectedStars] = useState([]);
  const [selectedMeals, setSelectedMeals] = useState([]);
  const [rangeValue, setRangeValue] = useState([0, 0]);
  const [minimumPrice, setMinimumPrice] = useState(0);
  const [maximumPrice, setMaximumPrice] = useState(0);
  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const HotelPerPage = 15; // Number of hotels to display per page
  const indexOfLastHotel = currentPage * HotelPerPage;
  const indexOfFirstHotel = indexOfLastHotel - HotelPerPage;
  const pagesCount = Math.ceil(hotelsList.length / HotelPerPage);
  const validCurrentPage = currentPage < 1 ? 1 : currentPage;
  const currentHotels = hotelsList.slice(indexOfFirstHotel, indexOfLastHotel);
  const maxPagesToShow = 8;
  const startHotelIndex = indexOfFirstHotel + 1;
  const endHotelIndex = Math.min(indexOfLastHotel, hotelsList.length);
  const paginate = (pageNumber) => {
    window.scrollTo(0, 0);
    setCurrentPage(pageNumber);
  };

  const renderPaginationItems = () => {
    const items = [];
    const maxPagesToShow = 5; // Number of pages to show at once

    // Calculate the start page
    let startPage = currentPage - Math.floor(maxPagesToShow / 2);
    if (startPage < 1) {
      startPage = 1;
    }

    // Calculate the end page
    let endPage = startPage + maxPagesToShow - 1;
    if (endPage > pagesCount) {
      endPage = pagesCount;
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }

    // Generate page items
    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <li class={`page-item ${i === currentPage ? "active" : ""}`} key={i}>
          <a class="page-link" onClick={() => paginate(i)} href="#">
            {i}
          </a>
        </li>
      );
    }

    return items;
  };

  useEffect(() => {
    if (currentPage < 1) {
      setCurrentPage(1);
    } else if (currentPage > pagesCount) {
      setCurrentPage(pagesCount);
    }
  }, [pagesCount]);

  useEffect(() => {
    setLoading(true);
    Searchhotels();
    var view = localStorage.getItem("HotelCardView");
    if (view) {
      setIsGridView(Number(view));
    }
  }, [location.search]);

  const Searchhotels = async () => {
    setOrderBy("1");
    const urlParams = new URLSearchParams(window.location.search);
    const parsedData = {};
    for (const [key, value] of urlParams.entries()) {
      if (key === "Adults" || key === "children" || key === "rooms_counter") {
        parsedData[key] = value.split(",").map(Number);
      } else if (key === "lat" || key === "long") {
        parsedData[key] = parseFloat(value);
      } else if (key === "adult" || key === "child" || key === "room") {
        parsedData[key] = parseInt(value, 10);
      } else if (key.startsWith("child_ages")) {
        // Check for child ages
        const roomIndex = parseInt(key.replace("child_ages", ""), 10) - 1; // Get the room index
        parsedData[key] = value ? value.split(",").map(Number) : []; // Split and convert ages to numbers or set to empty array
      } else {
        parsedData[key] = value;
      }
    }
    parsedData.token = token;
    parsedData.currency_slc = "AFN";
    parsedData.currency_slc_iso = "AF";
    setSearchData(parsedData);
    let Checkin = moment(parsedData.check_in);
    let checkout = moment(parsedData.check_out);
    let daysBetween = Math.abs(checkout.diff(Checkin, "days"));
    setNumberOfNights(daysBetween);
    dispatch(setSearchQuery(parsedData));
    try {
      const response = await axios.post(
        baseurl + "/api/search/hotels/new_Live",
        parsedData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (response.data.status == "success") {
        response.data.hotels_list=response.data.hotels_list.filter(
          (item) => Number(item.client_Id) === 48
        );
        const customhotel = response.data.hotels_list.filter(
          (item) => item.hotel_provider === "Custome_hotel"
        );
        
        const thirdparty =[];
        // const thirdparty = response.data.hotels_list.filter(
        //   (item) => item.hotel_provider !== "Custome_hotel"
        // );
        const flist = response.data.hotels_list.map((item) => ({
          label: item.hotel_name,
          value: item.hotel_id,
        }));
        setHotelsFList(flist);
        const prices = response.data.hotels_list.map((item) => item.min_price);
        const minPrice = Math.min(...prices);
        setMinimumPrice(minPrice);
        const maxPrice = Math.max(...prices);
        setMaximumPrice(maxPrice);
        setRangeValue([minPrice, maxPrice]);
        // var customeHotel = response.data.hotels_list;
        // if (location === "All") {
        //   customeHotel = response.data.hotels_list;
        // } else {
        //   customeHotel = response.data.hotels_list.filter(
        //     (item) => item.hotel_provider === "Custome_hotel"
        //   );
        // }
        const currencies = [];
        response.data.hotels_list.forEach((hotel) => {
          if (!currencies.includes(hotel.hotel_curreny)) {
            currencies.push(hotel.hotel_curreny);
          }
        });
        const currencyResponse = await AllCurrency(currencies);
        if (currencyResponse === null || currencyResponse.length === 0) {
          setCurrencyRates([]);
          setPriceConversion(false);
          localStorage.setItem("AllHotelCurr", null);
        } else {
          localStorage.setItem(
            "AllHotelCurr",
            JSON.stringify(currencyResponse)
          );
          setCurrencyRates(currencyResponse);
          setPriceConversion(true);
        }
        setHotelsResponse(response.data.hotels_list);
        setLoading(false);
        const sortedHotels = thirdparty.sort(
          (a, b) => a.min_price - b.min_price
        );
        const sortedcustomHotels = customhotel.sort(
          (a, b) => a.min_price - b.min_price
        );
        sortedcustomHotels.sort((a, b) => {
          if (a.client_Id === 48) return -1; // `a` comes first if `cline_id` is 48
          if (b.client_Id === 48) return 1;  // `b` comes first if `cline_id` is 48
          return a.client_Id - b.client_Id;   // Otherwise, sort in ascending order
        });
        const combinedHotels = [...sortedcustomHotels, ...sortedHotels];
        setHotelsList(combinedHotels);
        setResetHotelsList(combinedHotels);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };
  const AllCurrency = async (currencyArray) => {
    const token = CurrencyConverter();

    if (currencyArray.length === 0) {
      return []; // Return an empty array if no currencies are provided
    }

    const requests = currencyArray.map((currencynew) => {
      const config = {
        method: "get",
        url: `https://v6.exchangerate-api.com/v6/${token}/latest/${currencynew}`, // Replace with your API URL
        maxBodyLength: Infinity,
        headers: {},
      };

      return axios
        .request(config)
        .then((response) => {
          return response.data; // Return the response data on success
        })
        .catch((error) => {
          console.error(error);
          return null; // Return null for failed requests
        });
    });

    const results = await Promise.all(requests);
    const newdatcurr = results.filter((response) => response !== null); // Filter out null responses
    return newdatcurr;
  };
  const renderPrice = (price, currency) => {
    var selectedcurrency = localStorage.getItem("selectedCurrency");
    var currencyData = currencyRates;
    if (currencyData.length === 0 || currency === selectedcurrency) {
      if (Number(price) % 1 !== 0) {
        return Number(price).toFixed(2);
      }
      return Number(price);
    } else {
      var filterroomcurrdata = currencyData.filter(
        (item) => item.base_code === currency
      );

      const gbpprice = filterroomcurrdata[0].conversion_rates[selectedcurrency]; // Use square brackets to access the property
      var baseprice = Number(gbpprice) * Number(price);
      if (Number(baseprice) % 1 !== 0) {
        return Number(baseprice).toFixed(2);
      }
      return baseprice;
    }
  };
  useEffect(() => {
    currentHotels.forEach((hotel) => {
      if (!hotelsDetail[hotel.hotel_id]) {
        fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider);
      }
    });
  }, [currentPage, hotelsList, orderBy]);
  const fetchHotelDetails = async (hotelId, provider) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: token,
    };
    try {
      const response = await axios.post(
        baseurl + "/api/hotels/mata_Live",
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      setHotelsDetail((prevDetails) => ({
        ...prevDetails,
        [hotelId]: response.data,
      }));
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
    }
  };
  const RoomDetailPage = async (id, index) => {
    const hotelRoomdetail = hotelsResponse.filter(
      (item) => item.hotel_id == id
    );
    dispatch(setSearchResults(hotelRoomdetail));
    setDetailLoading(true);
    try {
      const data = {
        token: token,
        b2b_agent_id:UserDetail.id,
        hotel_search_data: JSON.stringify(hotelRoomdetail[0]),
        hotel_code: String(id),
      };
      const response = await axios.post(
        baseurl + "/api/hotels/details_Live",
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            // Required for CORS support to work
            "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
            "Access-Control-Allow-Headers":
              "Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale",
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          },
        }
      );
      setDetailLoading(false);
      if (response.data !== "") {
        dispatch(setHotelDetail(response.data.hotel_details));
        navigate(`/hotel_detail/${id}`, { state: { index } });
      } else {
        toast.info(
          "Something went wrong. Please select another hotel or try after some time thanks."
        );
      }
      // Handle the API response here
    } catch (error) {
      setDetailLoading(false);
      console.error("Error:", error);
    }
  };
  const calculateMarkup = (ctype, cvalue, atype, avalue, price) => {
    let markupprice = 0;
    let adminmarkupprice = 0;
    let clientmarkupprice = 0;
    let finalpricemarkup = Number(price);
    if (Number(avalue) !== 0) {
      if (atype === "Percentage") {
        markupprice = (price * Number(avalue)) / 100;
      } else {
        markupprice = Number(avalue);
      }
      adminmarkupprice = markupprice;
      finalpricemarkup += markupprice;
    }
    if (Number(cvalue) !== 0) {
      if (ctype === "Percentage") {
        markupprice = (Number(price) * Number(cvalue)) / 100;
      } else {
        markupprice = Number(cvalue);
      }
      clientmarkupprice = markupprice;
      finalpricemarkup += markupprice;
    }
  
    if (Number(finalpricemarkup) % 1 !== 0) {
      return Number(finalpricemarkup).toFixed(2);
    }
    return finalpricemarkup;
  };
  const handlenamefilterchnage = (value) => {
    setSelectedhotelid(value);
    if (value !== null) {
      const filterhotel = hotelsResponse.filter(
        (item) => item.hotel_id === value
      );
      setHotelsList(filterhotel);
    } else {
      const sortedHotels = hotelsResponse.sort(
        (a, b) => a.min_price - b.min_price
      );
      setHotelsList(sortedHotels);
    }
  };

  // Filter Functions
  const handleStarsChange = (star) => {
    setSelectedStars(
      (prevSelectedStars) =>
        prevSelectedStars.includes(star)
          ? prevSelectedStars.filter((s) => s !== star) // Remove if already selected
          : [...prevSelectedStars, star] // Add if not selected
    );
  };
  const handleMealTypeChange = (meal) => {
    setSelectedMeals(
      (prevSelectedStars) =>
        prevSelectedStars.includes(meal)
          ? prevSelectedStars.filter((s) => s !== meal) // Remove if already selected
          : [...prevSelectedStars, meal] // Add if not selected
    );
  };
  const handleOrderTypeChange = (event) => {
    setOrderBy(event.target.value);
    let filteredHotels = hotelsResponse;
    // Filter by price Low to High
    if (event.target.value === "1") {
      filteredHotels = filteredHotels.sort((a, b) => a.min_price - b.min_price);
    }
    // Filter by price Highy to Low
    if (event.target.value === "2") {
      filteredHotels = filteredHotels.sort((a, b) => b.min_price - a.min_price);
    }
    // Filter by price A-Z
    if (event.target.value === "3") {
      filteredHotels = filteredHotels.sort((a, b) =>
        a.hotel_name.localeCompare(b.hotel_name)
      );
    }
    // Filter by price Z-A
    if (event.target.value === "4") {
      filteredHotels = filteredHotels.sort((a, b) =>
        b.hotel_name.localeCompare(a.hotel_name)
      );
    }
    if (event.target.value === "5") {
      const today = new Date(); // Get today's date
      filteredHotels = filteredHotels.filter((hotel) => {
        return hotel.rooms_options.some((room) =>
          room.cancliation_policy_arr.some((policy) => {
            const fromDate = new Date(policy.from_date);
            return fromDate > today; // Check if from_date is greater than today
          })
        );
      });
    }
    const customhotel = filteredHotels.filter(
      (item) => item.hotel_provider === "Custome_hotel"
    );
    const thirdparty = filteredHotels.filter(
      (item) => item.hotel_provider !== "Custome_hotel"
    );
    const combinedHotels = [...customhotel, ...thirdparty];
    setHotelsList(combinedHotels);
  };
  const toggleView = (num) => {
    localStorage.setItem("HotelCardView", num);
    setIsGridView(num); // Toggle the view
  };
  const applyAllFilters = () => {
    setDetailLoading(true);
    setTimeout(() => {
      setDetailLoading(false);
    }, 1000);
    let filteredHotels = hotelsResponse;
    // Filter by price range
    if (rangeValue.length > 0) {
      filteredHotels = filteredHotels.filter((hotel) => {
        const price = hotel.min_price;
        return price >= rangeValue[0] && price <= rangeValue[1];
      });
    }

    // Filter by star rating
    if (selectedStars.length > 0) {
      filteredHotels = filteredHotels.filter((hotel) =>
        selectedStars.includes(hotel.stars_rating)
      );
    }

    // Filter by meal type
    if (selectedMeals.length > 0) {
      filteredHotels = filteredHotels.filter((hotel) => {
        return hotel.rooms_options.some((room) =>
          selectedMeals.includes(room.board_id)
        );
      });
    }
    const customhotel = filteredHotels.filter(
      (item) => item.hotel_provider === "Custome_hotel"
    );
    const thirdparty = filteredHotels.filter(
      (item) => item.hotel_provider !== "Custome_hotel"
    );
    const combinedHotels = [...customhotel, ...thirdparty];
    setHotelsList(combinedHotels);
  };

  const ResetFilter=()=>{
    setDetailLoading(true);
    setTimeout(() => {
      setDetailLoading(false);
    }, 1000);
    setHotelsList(resetHotelsList);
    setSelectedhotelid(null);
    setSelectedStars([]);
    setSelectedMeals([]);
    setOrderBy(1);
  }

  return (
    <Layout>
      <ToastContainer />
      {detailloading && <Loadeing />}
      {loading && <Loadeing />}
      <ModifySearch />
      <div className="container hotel-home-section">
        {hotelsResponse.length === 0 && loading !== true ? (
          <div className="text-center mt-4">
            <h3>Sorry, no hotels were found. Please refine your search and try again.</h3>
            <NavLink to='/'><button className="btn btn-secondary"><FontAwesomeIcon icon={faHome}/> Home</button></NavLink>
          </div>
        ) : (
          <>
            {loading ? (
              <div className="row">
                <div className="col-12 col-sm-6 col-md-3 mt-2">
                  <div class="card">
                    <div className="home-card-loader-img"></div>
                    <div class="card-body">
                      <h6 className=" placeholder-glow">
                        <span class="placeholder col-12"></span>
                        <span class="placeholder col-8"></span>
                        <span class="placeholder col-11"></span>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mt-2">
                  <div class="card">
                    <div className="home-card-loader-img"></div>
                    <div class="card-body">
                      <h6 className=" placeholder-glow">
                        <span class="placeholder col-12"></span>
                        <span class="placeholder col-8"></span>
                        <span class="placeholder col-11"></span>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mt-2">
                  <div class="card">
                    <div className="home-card-loader-img"></div>
                    <div class="card-body">
                      <h6 className=" placeholder-glow">
                        <span class="placeholder col-12"></span>
                        <span class="placeholder col-8"></span>
                        <span class="placeholder col-11"></span>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mt-2">
                  <div class="card">
                    <div className="home-card-loader-img"></div>
                    <div class="card-body">
                      <h6 className=" placeholder-glow">
                        <span class="placeholder col-12"></span>
                        <span class="placeholder col-8"></span>
                        <span class="placeholder col-11"></span>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mt-2">
                  <div class="card">
                    <div className="home-card-loader-img"></div>
                    <div class="card-body">
                      <h6 className=" placeholder-glow">
                        <span class="placeholder col-12"></span>
                        <span class="placeholder col-8"></span>
                        <span class="placeholder col-11"></span>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mt-2">
                  <div class="card">
                    <div className="home-card-loader-img"></div>
                    <div class="card-body">
                      <h6 className=" placeholder-glow">
                        <span class="placeholder col-12"></span>
                        <span class="placeholder col-8"></span>
                        <span class="placeholder col-11"></span>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mt-2">
                  <div class="card">
                    <div className="home-card-loader-img"></div>
                    <div class="card-body">
                      <h6 className=" placeholder-glow">
                        <span class="placeholder col-12"></span>
                        <span class="placeholder col-8"></span>
                        <span class="placeholder col-11"></span>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mt-2">
                  <div class="card">
                    <div className="home-card-loader-img"></div>
                    <div class="card-body">
                      <h6 className=" placeholder-glow">
                        <span class="placeholder col-12"></span>
                        <span class="placeholder col-8"></span>
                        <span class="placeholder col-11"></span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row pt-3">
                <div class="filters_listing sticky_horizontal mb-2">
                  <div class="container">
                    <ul class="clearfix d-flex gap-2 justify-content-end align-items-center">
                      <li>
                        <p
                          class="btn_map cursor-pointer"
                          data-bs-toggle="collapse"
                          href="#collapseMap"
                          aria-expanded="false"
                          aria-controls="collapseMap"
                          data-text-swap="Hide map"
                          data-text-original="View on map"
                        >
                          View on map
                        </p>
                      </li>
                      <li>
                        <div class="layout_view d-flex">
                          <p
                            onClick={() => toggleView(2)}
                            class={`${
                              isGridView === 2 ? "active" : ""
                            } cursor-pointer `}
                          >
                            <FontAwesomeIcon icon={faList} />
                            {/* <i class="icon-th"></i> */}
                          </p>
                          <p
                            onClick={() => toggleView(1)}
                            class={`${
                              isGridView === 1 ? "active" : ""
                            } mt-0 ms-2 cursor-pointer`}
                          >
                            <FontAwesomeIcon icon={faTh} />
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="collapse" id="collapseMap">
                  <div class="map mb-2">
                    <HotelMap
                      hotels={hotelsResponse}
                      hotelsearch={searchData}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-3 col-md-3">
                  <div id="filters_col">
                    <a
                      className="d-flex justify-content-between align-items-center"
                      data-bs-toggle="collapse"
                      href="#collapseFilters"
                      aria-expanded="false"
                      aria-controls="collapseFilters"
                      id="filters_col_bt"
                    >
                      Filters
                      <FontAwesomeIcon icon={faSliders} />
                    </a>
                    <div class="collapse show" id="collapseFilters">
                      <div class="filter_type">
                        <h6>Order By</h6>
                        <select
                          value={orderBy}
                          onChange={handleOrderTypeChange}
                          class="form-select"
                        >
                          <option value="1">Low to High</option>
                          <option value="2">Highy to Low</option>
                          <option value="3">Order by A-Z</option>
                          <option value="4">Order by Z-A</option>
                          <option value="5">Free Cancellation</option>
                        </select>
                      </div>
                      <div class="filter_type">
                        <h6>Search By name</h6>
                        <SelectPicker
                          onChange={handlenamefilterchnage}
                          value={selectedhotelid}
                          placeholder="Enter Hotel Name"
                          data={hotelsFList}
                          block
                        />
                      </div>
                      <div class="filter_type">
                        <h6>Price Range</h6>
                        <div>
                          <RangeSlider
                            onChange={(value) => setRangeValue(value)}
                            value={rangeValue} // Bind state to the slider
                            min={minimumPrice} // Minimum value
                            max={maximumPrice} // Maximum value
                            step={1} // Step increment for the slider
                          />
                        </div>
                      </div>
                      <div class="filter_type">
                        <h6>Stars</h6>
                        <ul>
                          <li>
                            <label class="container_check">
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <input
                                value="5"
                                onChange={() => handleStarsChange(5)}
                                checked={selectedStars.includes(5)}
                                type="checkbox"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </li>
                          <li>
                            <label class="container_check">
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <input
                                value="4"
                                onChange={() => handleStarsChange(4)}
                                checked={selectedStars.includes(4)}
                                type="checkbox"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </li>
                          <li>
                            <label class="container_check">
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <input
                                value="3"
                                onChange={() => handleStarsChange(3)}
                                checked={selectedStars.includes(3)}
                                type="checkbox"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </li>
                          <li>
                            <label class="container_check">
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <input
                                value="2"
                                onChange={() => handleStarsChange(2)}
                                checked={selectedStars.includes(2)}
                                type="checkbox"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </li>
                          <li>
                            <label class="container_check">
                              <FontAwesomeIcon icon={faStar} />
                              <input
                                value="1"
                                onChange={() => handleStarsChange(1)}
                                checked={selectedStars.includes(1)}
                                type="checkbox"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </li>
                        </ul>
                      </div>

                      <div class="filter_type">
                        <h6>Meal Type</h6>
                        <ul>
                          <li>
                            <label class="container_check">
                               ROOM ONLY
                              <input
                                value="ROOM ONLY"
                                checked={selectedMeals.includes("ROOM ONLY")}
                                onChange={() =>
                                  handleMealTypeChange("ROOM ONLY")
                                }
                                type="checkbox"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </li>
                          <li>
                            <label class="container_check">
                              BED AND BREAKFAST
                              <input
                                value="BED AND BREAKFAST"
                                checked={selectedMeals.includes(
                                  "BED AND BREAKFAST"
                                )}
                                onChange={() =>
                                  handleMealTypeChange("BED AND BREAKFAST")
                                }
                                type="checkbox"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </li>
                          <li>
                            <label class="container_check">
                              HALF BOARD
                              <input
                                value="HALF BOARD"
                                checked={selectedMeals.includes("HALF BOARD")}
                                onChange={() =>
                                  handleMealTypeChange("HALF BOARD")
                                }
                                type="checkbox"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </li>
                          <li>
                            <label class="container_check">
                              Full BOARD
                              <input
                                value="Full BOARD"
                                checked={selectedMeals.includes("Full BOARD")}
                                onChange={() =>
                                  handleMealTypeChange("Full BOARD")
                                }
                                type="checkbox"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </li>
                        </ul>
                        <p onClick={ResetFilter} className="text-primary mt-3 text-end cursor-pointer">Reset Filter?</p>
                        <button
                          onClick={applyAllFilters}
                          className="theme-btn-1 w-100 ps-4 pe-4 mt-2 py-2"
                        >
                          Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-12 col-sm-9 col-md-9">
                  {isGridView === 2 ? (
                    <div>
                      {currentHotels.map((item, index) => (
                        <div key={index} class="row box_list  g-0">
                          <div class="col-lg-5">
                            <figure>
                            {item.rooms_options.every((room) => room.request_type === "1")?
                               (
                                <small>Available on Request</small>
                               ): (
                                <small> {item.hotel_provider==='Custome_hotel'? item.room_Quantity : item.rooms_options.length} {item.hotel_provider==='Custome_hotel'? item.room_Quantity.length===1?'Room Available':'Rooms Available' : item.rooms_options.length===1?'Room Available':'Rooms Available'}</small>
                               )}
                              {item.client_Id === 48 && (
                            <small className="custom-tag1">
                            AS
                          </small>
                             )}
                              {hotelsDetail[item.hotel_id] &&
                              hotelsDetail[item.hotel_id].details_data &&
                              hotelsDetail[item.hotel_id].details_data.image ? (
                                <img
                                  src={
                                    hotelsDetail[item.hotel_id].details_data
                                      .image
                                  }
                                  alt={item.hotel_name}
                                />
                              ) : (
                                <div className="home-card-loader-img">
                                <Loader size="md" />
                                </div>
                              )}
                            </figure>
                          </div>
                          <div class="col-lg-7">
                            <div class="wrapper">
                              <a class="wish_bt">
                                {moment(searchData?.check_in).format("MMM Do ")}
                                <FontAwesomeIcon icon={faArrowRight} />
                                {moment(searchData?.check_out).format(
                                  "MMM Do "
                                )}
                              </a>
                              {item?.stars_rating === "" ||
                              item?.stars_rating === 0 ? (
                                <div class="score">
                                  <strong>No Rating</strong>
                                </div>
                              ) : (
                                <div class="cat_star">
                                  {Array(Math.floor(item.stars_rating))
                                    .fill(0)
                                    .map((_, index) => (
                                      <FontAwesomeIcon
                                        key={index}
                                        style={{ color: "#fff" }}
                                        icon={faStar}
                                      />
                                    ))}
                                  {item.stars_rating % 1 !== 0 && (
                                    <FontAwesomeIcon
                                      style={{ color: "#fff" }}
                                      icon={faStarHalfAlt}
                                    />
                                  )}
                                </div>
                              )}

                              <h3>
                                <a>{item.hotel_name}</a>
                              </h3>
                              <p>
                                {" "}
                                <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
                                {
                                  hotelsDetail[item.hotel_id]?.details_data
                                    ?.address
                                }
                              </p>
                              <div className="d-flex justify-content-between align-items-center">
                                <ul class="hotel-room room_tabs" role="tablist">
                                  {hotelsDetail[
                                    item.hotel_id
                                  ]?.details_data?.facilities
                                    .slice(0, 5)
                                    .map((item, index) => (
                                      <li key={index} role="bravo_room">
                                        {item}
                                      </li>
                                    ))}
                                </ul>
                              </div>
                              {(() => {
                                const today = new Date(); // Get today's date

                                // Find the first room with free cancellation (from_date greater than today)
                                const roomWithFreeCancellation =
                                  item.rooms_options.find((room) =>
                                    room.cancliation_policy_arr.some(
                                      (policy) => {
                                        const fromDate = new Date(
                                          policy.from_date
                                        );
                                        return fromDate > today; // Check if from_date is greater than today
                                      }
                                    )
                                  );

                                // Render the Free Cancellation message if a room is found
                                if (roomWithFreeCancellation) {
                                  return (
                                    <p className="text-success fw-bold">
                                      Free Cancellation for{" "}
                                      {roomWithFreeCancellation.room_name}{" "}
                                      {/* Display the room name */}
                                    </p>
                                  );
                                }

                                return null; // Return nothing if no room has free cancellation
                              })()}
                              <span class="price fw-bold">
                                From{" "}
                                <strong>
                                  {priceConversion === false
                                    ? item.hotel_curreny
                                    : Object.keys(SelectedCurrRates).length ===
                                      0
                                    ? BaseCurrRates.base_code
                                    : SelectedCurrRates.base_code}{" "}
                                  {priceConversion === false
                                    ? calculateMarkup(
                                        item.customer_markup_type,
                                        item.customer_markup,
                                        item.admin_markup_type,
                                        item.admin_markup,
                                        item.min_price
                                      )
                                    : renderPrice(
                                        calculateMarkup(
                                          item.customer_markup_type,
                                          item.customer_markup,
                                          item.admin_markup_type,
                                          item.admin_markup,
                                          item.min_price
                                        ),
                                        item.hotel_curreny
                                      )}
                                       {item.min_price_Actual != null && item.min_price_Actual !==0 &&(
                                    <sub style={{ color: "#808080ad" }}>
                                    <del>
                                    {priceConversion === false
                                  ? item.hotel_curreny
                                  : Object.keys(SelectedCurrRates).length === 0
                                  ? BaseCurrRates.base_code
                                  : SelectedCurrRates.base_code}{" "}
                                {priceConversion === false
                                  ? calculateMarkup(
                                      item.customer_markup_type,
                                      item.customer_markup,
                                      item.admin_markup_type,
                                      item.admin_markup,
                                      item.min_price_Actual
                                    )

                                  : renderPrice(
                                      calculateMarkup(
                                        item.customer_markup_type,
                                        item.customer_markup,
                                        item.admin_markup_type,
                                        item.admin_markup,
                                        item.min_price_Actual
                                      ),
                                      item.hotel_curreny
                                    )}
                                      </del>
                                      </sub>
                                      )}
                                </strong>
                                <br />
                                <small>
                                  {priceConversion === false
                                    ? item.hotel_curreny
                                    : Object.keys(SelectedCurrRates).length ===
                                      0
                                    ? BaseCurrRates.base_code
                                    : SelectedCurrRates.base_code}{" "}
                                  {priceConversion === false
                                    ? (calculateMarkup(
                                        item.customer_markup_type,
                                        item.customer_markup,
                                        item.admin_markup_type,
                                        item.admin_markup,
                                        item.min_price
                                      ) / Number(numberOfNights)).toFixed(2)
                                    : (renderPrice(
                                        calculateMarkup(
                                          item.customer_markup_type,
                                          item.customer_markup,
                                          item.admin_markup_type,
                                          item.admin_markup,
                                          item.min_price
                                        ),
                                        item.hotel_curreny
                                      ) /
                                      Number(numberOfNights)).toFixed(2)}{" "}
                                  / Night
                                </small>
                                <br />
                                <small>Vat and Taxes included</small>
                              </span>
                            </div>
                            <ul className="list-ul">
                              <li></li>
                              <li>
                                <div class="score">
                                  <strong
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      RoomDetailPage(item.hotel_id, index)
                                    }
                                  >
                                    {
                                      // Check if all rooms have request_type === 1
                                      item.rooms_options.every(
                                        (room) => room.request_type === "1"
                                      )
                                        ? "On Request" // If all have request_type === 1
                                        : " View Detail" // If any room doesn't have request_type === 1
                                    }
                                  </strong>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="row">
                      {currentHotels.map((item, index) => (
                        <div
                          key={index}
                          className="col-12 col-sm-6 col-md-4 mt-2"
                        >
                          <div
                            class="card hotel-card"
                          >
                            {hotelsDetail[item.hotel_id] &&
                            hotelsDetail[item.hotel_id].details_data &&
                            hotelsDetail[item.hotel_id].details_data.image ? (
                              <img
                                class="card-img-top-home"
                                src={
                                  hotelsDetail[item.hotel_id].details_data.image
                                }
                                alt="Card image cap"
                              />
                            ) : (
                              <div className="home-card-loader-img">
                              <Loader size="md" />
                              </div>
                            )}
                             {item.client_Id === 48 && (
                            <div class="custom_tag">
                                <strong>AS</strong>
                              </div>
                             )}
                              <div class="score">
                              {item.rooms_options.every((room) => room.request_type === "1")?
                               (
                                <strong>Available on Request</strong>
                               ): (
                                <strong> {item.hotel_provider==='Custome_hotel'? item.room_Quantity : item.rooms_options.length} {item.hotel_provider==='Custome_hotel'? item.room_Quantity.length===1?'Room Available':'Rooms Available' : item.rooms_options.length===1?'Room Available':'Rooms Available'}</strong>
                               )}
                              </div>
                            <div class="card-body">
                            {item?.stars_rating === "" ||
                              item?.stars_rating === 0 ? (
                                <div class="score">
                                  <strong>No Rating</strong>
                                </div>
                              ) : (
                                <div class="cat_star">
                                  {Array(Math.floor(item.stars_rating))
                                    .fill(0)
                                    .map((_, index) => (
                                      <FontAwesomeIcon
                                        key={index}
                                        style={{ color: "#fff" }}
                                        icon={faStar}
                                      />
                                    ))}
                                  {item.stars_rating % 1 !== 0 && (
                                    <FontAwesomeIcon
                                      style={{ color: "#fff" }}
                                      icon={faStarHalfAlt}
                                    />
                                  )}
                                </div>
                              )}
                              <Whisper
                                placement="top"
                                controlId="control-id-hover"
                                trigger="hover"
                                speaker={<Tooltip>{item.hotel_name}</Tooltip>}
                              >
                                <h6 className="ellipsis-text">
                                  {item.hotel_name}
                                </h6>
                              </Whisper>
                              <p className="ellipsis-address">
                                {
                                  hotelsDetail[item.hotel_id]?.details_data
                                    ?.address
                                }
                              </p>
                              <div className="d-flex justify-content-between align-items-center">
                                <ul class="hotel-room room_tabs1" role="tablist">
                                  {hotelsDetail[
                                    item.hotel_id
                                  ]?.details_data?.facilities
                                    .slice(0, 2)
                                    .map((item, index) => (
                                      <li key={index} role="bravo_room">
                                        {item}
                                      </li>
                                    ))}
                                </ul>
                              </div>
                              <div className="d-flex justify-content-between aligh=n-items-center">
                                {moment(searchData?.check_in).format("MMM Do ")}
                                <FontAwesomeIcon icon={faArrowRight} />
                                {moment(searchData?.check_out).format(
                                  "MMM Do "
                                )}
                              </div>
                              {(() => {
                                const today = new Date(); // Get today's date

                                // Find the first room with free cancellation (from_date greater than today)
                                const roomWithFreeCancellation =
                                  item.rooms_options.find((room) =>
                                    room.cancliation_policy_arr.some(
                                      (policy) => {
                                        const fromDate = new Date(
                                          policy.from_date
                                        );
                                        return fromDate > today; // Check if from_date is greater than today
                                      }
                                    )
                                  );

                                // Render the Free Cancellation message if a room is found
                                if (roomWithFreeCancellation) {
                                  return (
                                    <p className="text-success fw-bold">
                                      Free Cancellation for{" "}
                                      {roomWithFreeCancellation.room_name}{" "}
                                      {/* Display the room name */}
                                    </p>
                                  );
                                }

                                return null; // Return nothing if no room has free cancellation
                              })()}
                               <div className="d-flex mt-4 justify-content-between align-items-center">
                              <h6 >
                           
                                {priceConversion === false
                                  ? item.hotel_curreny
                                  : Object.keys(SelectedCurrRates).length === 0
                                  ? BaseCurrRates.base_code
                                  : SelectedCurrRates.base_code}{" "}
                                  {priceConversion === false
                                    ? (calculateMarkup(
                                        item.customer_markup_type,
                                        item.customer_markup,
                                        item.admin_markup_type,
                                        item.admin_markup,
                                        item.min_price
                                      ) )
                                    : (renderPrice(
                                        calculateMarkup(
                                          item.customer_markup_type,
                                          item.customer_markup,
                                          item.admin_markup_type,
                                          item.admin_markup,
                                          item.min_price
                                        ),
                                        item.hotel_curreny
                                      ))} 
                                    {item.min_price_Actual != null  && item.min_price_Actual !== 0&& (
                                    <sub style={{ color: "#808080ad" }}>
                                    <del>
                                    {priceConversion === false
                                  ? item.hotel_curreny
                                  : Object.keys(SelectedCurrRates).length === 0
                                  ? BaseCurrRates.base_code
                                  : SelectedCurrRates.base_code}{" "}
                                {priceConversion === false
                                  ? calculateMarkup(
                                      item.customer_markup_type,
                                      item.customer_markup,
                                      item.admin_markup_type,
                                      item.admin_markup,
                                      item.min_price_Actual
                                    )

                                  : renderPrice(
                                      calculateMarkup(
                                        item.customer_markup_type,
                                        item.customer_markup,
                                        item.admin_markup_type,
                                        item.admin_markup,
                                        item.min_price_Actual
                                      ),
                                      item.hotel_curreny
                                    )}
                                      </del>
                                      </sub>
                                      )}
                                <br />
                                <small>
                                  {priceConversion === false
                                    ? item.hotel_curreny
                                    : Object.keys(SelectedCurrRates).length ===
                                      0
                                    ? BaseCurrRates.base_code
                                    : SelectedCurrRates.base_code}{" "}
                                  {priceConversion === false
                                    ? (calculateMarkup(
                                        item.customer_markup_type,
                                        item.customer_markup,
                                        item.admin_markup_type,
                                        item.admin_markup,
                                        item.min_price
                                      ) / Number(numberOfNights)).toFixed(2)
                                    : (renderPrice(
                                        calculateMarkup(
                                          item.customer_markup_type,
                                          item.customer_markup,
                                          item.admin_markup_type,
                                          item.admin_markup,
                                          item.min_price
                                        ),
                                        item.hotel_curreny
                                      ) /
                                      Number(numberOfNights)).toFixed(2)}{" "}
                                  / Night
                                </small>
                                <br />
                                <small>Vat and Taxes included</small>
                              </h6>
                              <button
                                onClick={() => RoomDetailPage(item.hotel_id, index)}
                              className="btn btn-sm btn-primary"
                            >
                              {
                                      // Check if all rooms have request_type === 1
                                      item.rooms_options.every(
                                        (room) => room.request_type === "1"
                                      )
                                        ? "On Request" // If all have request_type === 1
                                        : " View Detail" // If any room doesn't have request_type === 1
                                    }
                            </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
            {hotelsList.length >= 15 && (
            <div className="text-center mt-4">
              <p>
                Showing {startHotelIndex} to {endHotelIndex} of{" "}
                {hotelsList.length} hotels
              </p>
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  {/* Previous Button */}
                  {currentPage !== 1 &&(
                  <li
                  
                    class='page-item '
                    onClick={() => paginate(validCurrentPage - 1)}
                  >
                    <a  class="page-link" href="#">
                      Previous
                    </a>
                  </li>
                  )}

                  {/* Pagination Items */}
                  {renderPaginationItems()}

                  {/* Next Button */}
                  { currentPage !== pagesCount && (
                  <li
                    class='page-item'
                    onClick={() => paginate(validCurrentPage + 1)}
                  >
                    <a class="page-link" href="#">
                      Next
                    </a>
                  </li>
                  )}
                </ul>
              </nav>
            </div>
            ) }
          </>
        )}
      </div>
    </Layout>
  );
}
export default HotelListing;
